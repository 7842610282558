import { media } from 'Config/styled'
import { makeStyles, alpha } from '@material-ui/core/styles'
import styled from 'styled-components'
import colors from 'Assets/colors'

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  bar: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.24)',
    borderRadius: '0px 0px 4px 4px',
  },
  menuButton: {
    textTransform: 'none',
    marginLeft: 'auto',
    backgroundColor: colors.cart,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    padding: '1rem',
  },
  title: {
    flexGrow: 1,
    display: 'flex',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '24ch',
      '&:focus': {
        width: '40ch',
      },
    },
  },
}))

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 1.5rem 0;

  ${media('lg')} {
    display: block;
    padding: 0rem;
  }

  .border-with-radius {
    width: 100%;
    max-width: 5.25rem;
    height: 0px;
    transition: all 0.2s;
  }
`

export const Container = styled.div`
  ${media('lg')} {
    width: 100%;
  }
`

export const SearchInput = styled.div`
  ${media('lg')} {
    display: flex;
    margin-top: 1rem;
  }
`
