import styled from 'styled-components'
import { media } from 'Config/styled'

export const BackSection = styled.div`
  display: none;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1.2rem;
  position: absolute;
  top: ${props => props.top};
  margin-right: ${props => props.marginRight};
  left: ${props => props.left};
  width: 40px;
  height: 40px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  ${media('lg')} {
    display: ${props => (props.hasRoutes ? 'flex' : 'none')};
  }
`
