import React from 'react'

import PropTypes from 'prop-types'
import remove from 'lodash/remove'
import { useTranslation } from 'react-i18next'
import ContentLoader from 'react-content-loader'
import { ApproverProviderCheckout } from 'Components/organisms'
import { NumberFormat } from 'Components/atoms'
import BackButton from 'Components/atoms/backButton'
import { ModalOriginCenter, ModalLoading } from 'Components/molecules'
import {
  hasSomethingAvailableInCart,
  calculateAvailableProductAmount,
  calculateAvailableProductTotalPrice,
} from 'Components/utils/cartCalculator'
import { isDeliveriesCompleted, hasAvailableProductsDeliveries } from 'Redux/purchases/utils'
import useApproverCartsCheckout from 'hooks/approver/useApproverCartsCheckout'

import {
  Container,
  Title,
  LowerMenu,
  Row,
  Center,
  CenterHelper,
  CenterInfo,
  CenterAction,
  CenterLabel,
  CenterPlace,
  Selector,
  FinishButton,
  ButtonLiteral,
  TotalItems,
  LoadingWrapper,
} from './styled'
import { hasAvailableProduct } from './utils'

const ApproverCheckout = () => {
  const { t } = useTranslation()

  const {
    cart,
    isLoading,
    showModal,
    onConfirm,
    onCenterSelected,
    onClickChangeCenter,
    originCenters,
    isSubmiting,
    errors,
    setErrors,
    orderedByProvider,
    selectedOrigin,
    selectedCenter,
    deliveries,
  } = useApproverCartsCheckout()

  if (isLoading) {
    return <ProviderCardLoading />
  }

  return (
    <>
      <Container>
        <BackButton />
        <Title>{t('finishPurchase')}</Title>
        <Providers providers={orderedByProvider} errors={errors} setErrors={setErrors} />
      </Container>
      <LowerMenu>
        <Row>
          <OriginCenter
            center={selectedOrigin}
            onCenterSelected={onCenterSelected}
            showModalOriginCenter={showModal}
            onClickChangeCenter={onClickChangeCenter}
            originCenters={originCenters}
          />
          <DestinationCenter center={selectedCenter} />
          <SubmitButton
            onConfirm={onConfirm}
            cart={cart}
            orderedByProvider={orderedByProvider}
            deliveries={deliveries}
            selectedOrigin={selectedOrigin}
            errors={errors}
          />
        </Row>
      </LowerMenu>
      <ModalLoading showModal={isSubmiting} />
    </>
  )
}

const Providers = ({ providers, setErrors, errors }) => (
  <>
    {Object.keys(providers).map(key => (
      <ApproverProviderCheckout
        key={key}
        providerName={key}
        products={providers[key]}
        setErrors={error => {
          const newErrors = { ...remove({ ...errors }, key) }
          if (error) {
            setErrors({ ...errors, [key]: error })
          } else {
            setErrors(newErrors)
          }
        }}
      />
    ))}
  </>
)

Providers.propTypes = {
  providers: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
}

const DestinationCenter = ({ center }) => {
  const { t } = useTranslation()

  if (!center) return null

  return (
    <Center>
      <CenterInfo>
        <CenterLabel>{t('destinationCenter')}</CenterLabel>
        <CenterPlace>{center.name}</CenterPlace>
      </CenterInfo>
    </Center>
  )
}

DestinationCenter.propTypes = {
  center: PropTypes.object,
}

DestinationCenter.defaultProps = {
  center: null,
}

const OriginCenter = ({ center, showModalOriginCenter, onCenterSelected, onClickChangeCenter, originCenters }) => {
  const { t } = useTranslation()

  return (
    <CenterHelper>
      <Center>
        <CenterInfo>
          <CenterLabel>{t('originCenter')}</CenterLabel>
          <CenterPlace>{center ? center.name : t('noOriginCenterSelected')}</CenterPlace>
        </CenterInfo>
        {originCenters.length === 0 && <p>{t('invalidERPOriginCenters')}</p>}
        {originCenters.length > 1 && (
          <CenterAction>
            <Selector type='button' onClick={onClickChangeCenter}>
              {t('change')}
            </Selector>
          </CenterAction>
        )}

        <ModalOriginCenter
          showModal={showModalOriginCenter}
          onCenterSelected={onCenterSelected}
          centers={originCenters}
        />
      </Center>
    </CenterHelper>
  )
}

OriginCenter.propTypes = {
  center: PropTypes.object,
  showModalOriginCenter: PropTypes.bool.isRequired,
  originCenters: PropTypes.array.isRequired,
  onCenterSelected: PropTypes.func.isRequired,
  onClickChangeCenter: PropTypes.func.isRequired,
}

OriginCenter.defaultProps = {
  center: null,
}

const SubmitButton = ({ onConfirm, cart, orderedByProvider, deliveries, selectedOrigin, errors, needsApproval }) => {
  const { t } = useTranslation()

  const productQuantity = hasSomethingAvailableInCart(cart) ? calculateAvailableProductAmount(cart) : ''
  const fullTotal = hasSomethingAvailableInCart(cart) ? calculateAvailableProductTotalPrice(cart) : ''
  const isAllDeliveriesCompleted = isDeliveriesCompleted(orderedByProvider, deliveries)
  const hasAvailable = hasAvailableProduct(cart)
  // eslint-disable-next-line no-unused-vars
  const disabled = !isAllDeliveriesCompleted || !selectedOrigin || Object.keys(errors).length > 0 || !hasAvailable
  const disabledConfirm =
    !hasAvailableProductsDeliveries || !selectedOrigin || Object.keys(errors).length > 0 || !hasAvailable

  const getSubmitLabel = () => {
    if (!hasAvailableProductsDeliveries) return t('completeDeliveryDates')
    if (!selectedOrigin) return t('selectOriginCenter')
    if (needsApproval) return t('requestApproval')
    return t('confirmOrder')
  }

  return (
    <Center>
      <FinishButton onClick={onConfirm} disabled={disabledConfirm}>
        <ButtonLiteral>{getSubmitLabel()}</ButtonLiteral>
        {hasSomethingAvailableInCart(cart) ? <NumberFormat value={fullTotal} /> : '0€'}
        <TotalItems>{productQuantity}</TotalItems>
      </FinishButton>
    </Center>
  )
}

SubmitButton.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  orderedByProvider: PropTypes.object.isRequired,
  deliveries: PropTypes.array.isRequired,
  selectedOrigin: PropTypes.object,
  errors: PropTypes.object.isRequired,
  needsApproval: PropTypes.bool,
}

SubmitButton.defaultProps = {
  selectedOrigin: null,
  needsApproval: false,
}

const ProviderCardLoading = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <LoadingWrapper>
        <ContentLoader
          speed={2}
          width={2000}
          height={400}
          viewBox='0 0 2000 400'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          title={t('loading')}
        >
          <rect x='0' y='10' rx='0' ry='0' width='2000' height='38' />
          <rect x='0' y='70' rx='0' ry='0' width='2000' height='6' />
          <rect x='0' y='90' rx='0' ry='0' width='150' height='6' />
          <rect x='0' y='130' rx='0' ry='0' width='2000' height='38' />
          <rect x='0' y='190' rx='0' ry='0' width='2000' height='6' />
          <rect x='0' y='210' rx='0' ry='0' width='150' height='6' />
        </ContentLoader>
      </LoadingWrapper>
    </Container>
  )
}

export default ApproverCheckout
