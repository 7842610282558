import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ReactComponent as FrozenIcon } from 'Assets/Icons/Frozen.svg'
import moment from 'moment'
import { ReactComponent as RefrigeratedIcon } from 'Assets/Icons/Refrigerated.svg'
import Arrow from 'Assets/Icons/Arrow'
import Trash from 'Assets/Icons/Trash'
import ArrowRight from 'Assets/Icons/ArrowThinRight'
import { TEMPERATURES, MEASURE_UNIT } from 'Config/constants'
import Icons from 'Components/utils/categoryIcons'
import colors from 'Assets/colors'
import { PurchaseActions } from 'Redux/purchases'
import { getProduct } from 'Services/api'
import { selectCenterId } from 'Redux/purchases/utils'
import ProductButton from 'Components/atoms/productButton'
import { NumberFormat } from 'Components/atoms'
import { financial } from 'Components/utils/cartCalculator'
import Box from '@material-ui/core/Box'

import { AVAILABILITY_STATES } from '../../../Config/constants'

import {
  CardContainer,
  IconsContainer,
  Name,
  Provider,
  Price,
  PreviousPrice,
  MinAndMulti,
  PriceKG,
  ButtonNotAvailable,
  NameAndProviderContainer,
  SimpleName,
  SimpleCardContainer,
  EraseButton,
  Unavailable,
  MoreInfoContainer,
  MoreInfo,
  ProductRow,
  RowPrice,
  NextDate,
  PriceSection,
  LabelMobile,
  DataSection,
  ProductButtonSection,
} from './styled'

const ProductHorizontalCard = ({
  categoryName,
  categoryId,
  temperature,
  name,
  provider,
  price,
  min,
  multiple,
  measureUnit,
  refProp,
  pricePerKg,
  id,
  isSimpleCard,
  raw_material: rawMaterial,
  notAvailable,
  reference,
  setShowModalConfirmationProduct,
  setOnConfirmProduct,
  showJustButtonCard,
  previousPrice,
  availability,
  hasMoreInfo,
  refButton,
  onNext,
  onPrevious,
  showNextDate,
}) => {
  const { amount = 0 } = useSelector(state => state.purchase.cart[id]) || {}
  const centerId = useSelector(selectCenterId)
  const center = useSelector(state => state.auth.selectedCenter)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { addProductToCart } = PurchaseActions

  const Icon = Icons[categoryId] || (() => <></>)

  const { showPrice } = center

  const getAdequateIcon = () => {
    if (temperature === TEMPERATURES.refrigerated) {
      return <RefrigeratedIcon className='icon-product-card' style={{ marginLeft: '8px' }} />
    }

    if (temperature === TEMPERATURES.frozen) {
      return <FrozenIcon className='icon-product-card' style={{ marginLeft: '8px' }} />
    }

    return ''
  }

  const onConfirmProductErase = () => {
    dispatch(
      addProductToCart(id, {
        amount: 0,
        name,
        total: 0,
        provider,
        price,
        pricePerKg,
        measureUnit,
        min,
        multiple,
        id,
        raw_material: rawMaterial,
        state: availability ? availability.status : AVAILABILITY_STATES.AVAILABLE,
        reference,
      })
    )
    setShowModalConfirmationProduct(false)
  }

  const setToZero = () => {
    if (isSimpleCard && !notAvailable) {
      setShowModalConfirmationProduct(true)
      setOnConfirmProduct(() => onConfirmProductErase)
      return
    }

    dispatch(
      addProductToCart(id, {
        amount: 0,
        name,
        total: 0,
        provider,
        price,
        pricePerKg,
        measureUnit,
        min,
        multiple,
        id,
        raw_material: rawMaterial,
        state: availability ? availability.status : AVAILABILITY_STATES.AVAILABLE,
        reference,
      })
    )
  }

  const onClickCard = async () => {
    try {
      const response = await getProduct(centerId, id)
      dispatch(
        PurchaseActions.setProduct({
          ...response,
          categoryName,
          categoryId,
          temperature,
          name,
          provider,
          price,
          min,
          multiple,
          measureUnit,
          refProp,
          pricePerKg,
          id,
          isSimpleCard,
          raw_material: rawMaterial,
          state: availability ? availability.status : AVAILABILITY_STATES.AVAILABLE,
          reference,
          availability: response ? response.availability : availability,
          previousPrice,
        })
      )
      dispatch(PurchaseActions.setModalProductOpen(true))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  if (!amount && isSimpleCard) return null

  const differencePrice = previousPrice ? price - previousPrice : null

  const showButton = () => (
    <ProductButton
      name={name}
      provider={provider}
      price={price}
      min={min}
      multiple={multiple}
      measureUnit={measureUnit}
      pricePerKg={pricePerKg}
      id={id}
      isSimpleCard={isSimpleCard}
      rawMaterial={rawMaterial}
      notAvailable={notAvailable}
      reference={reference}
      setShowModalConfirmationProduct={setShowModalConfirmationProduct}
      setOnConfirmProduct={setOnConfirmProduct}
      showJustButtonCard={showJustButtonCard}
      availability={availability}
      innerRef={refButton}
      onPrevious={onPrevious}
      onNext={onNext}
    />
  )

  const renderPrice = () => (
    <RowPrice>
      {showPrice && (
        <>
          <Price>
            <NumberFormat value={price} suffix={`€/${measureUnit}`} />
          </Price>
          {measureUnit !== MEASURE_UNIT.KG ? (
            <PriceKG>
              <NumberFormat value={pricePerKg} suffix='€/KG' />
            </PriceKG>
          ) : null}
        </>
      )}
    </RowPrice>
  )

  const renderDifferencePrice = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: { xs: '0', sm: '0rem', lg: '0.5rem' },
        marginLeft: { xs: '0', sm: '0rem', lg: '0.5rem' },
        paddingBottom: { xs: '0', sm: '0rem', lg: '0.5rem' },
        paddingTop: { xs: '0', sm: '0rem', lg: '0.5rem' },
        // marginTop: '0.5rem',
        // marginLeft: '0.5rem',
        // paddingBottom: '0.5rem',
        // paddingTop: '0.5rem',
      }}
    >
      {showPrice && (
        <>
          <Price style={{ color: differencePrice > 0 ? colors.red : colors.secondary }}>
            <NumberFormat value={price} suffix={`€/${measureUnit}`} />
          </Price>
          <Arrow
            style={{
              marginRight: '0.5rem',
              marginLeft: '0.2rem',
              transform: differencePrice > 0 ? 'rotate(270deg)' : 'rotate(90deg)',
              position: 'relative',
            }}
            fill={differencePrice > 0 ? colors.red : colors.secondary}
          />
          <PreviousPrice>
            <NumberFormat value={previousPrice} suffix={`€/${measureUnit}`} />
          </PreviousPrice>
          {measureUnit !== MEASURE_UNIT.KG ? (
            <PriceKG>
              <NumberFormat value={pricePerKg} suffix='€/KG' />
            </PriceKG>
          ) : null}
        </>
      )}
    </Box>
  )

  const percentage = (num, per) => (num / 100) * per

  if (isSimpleCard) {
    return (
      <SimpleCardContainer onClick={onClickCard}>
        <ProductRow>
          <IconsContainer>
            <LabelMobile>{`${t('product')}:`}</LabelMobile>

            <SimpleName title={name}>{name}</SimpleName>
          </IconsContainer>
          <DataSection>
            <LabelMobile>{`${t('price')}:`}</LabelMobile>

            {!differencePrice || Math.abs(differencePrice) < percentage(previousPrice, 0.01)
              ? renderPrice()
              : renderDifferencePrice()}
            {showNextDate && availability?.start_date ? (
              <NextDate>{`${t('nextDate')}${moment(availability?.start_date).format('DD/MM/YYYY')}`}</NextDate>
            ) : (
              <MinAndMulti>{`${t('min')}: ${financial(min)} / ${t('multi')}: ${financial(multiple)}`}</MinAndMulti>
            )}
          </DataSection>
          {showPrice && (
            <PriceSection>
              <LabelMobile>{`${t('total')}:`}</LabelMobile>
              <RowPrice>
                <Price>
                  <NumberFormat value={price * amount} />
                </Price>
              </RowPrice>
            </PriceSection>
          )}
          <ProductButtonSection>
            {notAvailable ? (
              <ButtonNotAvailable>
                <Unavailable>
                  {availability && availability.status === AVAILABILITY_STATES.NOT_STOCK && t('noItems')}
                  {availability && availability.status === AVAILABILITY_STATES.UNAVAILABLE && t('deleted')}
                </Unavailable>
                <EraseButton onClick={setToZero}>
                  <Trash className='trash' width={22} fill='#fff' /> {t('delete')}
                </EraseButton>
              </ButtonNotAvailable>
            ) : (
              showButton()
            )}
          </ProductButtonSection>
        </ProductRow>
      </SimpleCardContainer>
    )
  }

  if (showJustButtonCard) {
    showButton()
  }

  return (
    <CardContainer ref={refProp} onClick={onClickCard}>
      <IconsContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon className='icon-product-card' style={{ marginRight: '8px' }} />
          <div className='category-product-card'> {categoryName}</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          {getAdequateIcon()}
          <div className='category-product-card'>{temperature || ''}</div>
        </div>
      </IconsContainer>
      <NameAndProviderContainer>
        <LabelMobile>{`${t('product')}:`}</LabelMobile>
        <Name title={name}>{name}</Name>
        <Provider>{provider.name}</Provider>
      </NameAndProviderContainer>
      <LabelMobile>{`${t('price')}:`}</LabelMobile>
      {!differencePrice || Math.abs(differencePrice) < percentage(previousPrice, 0.01)
        ? renderPrice()
        : renderDifferencePrice()}
      <MoreInfoContainer>
        <MinAndMulti>{`${t('min')}: ${min} / ${t('multi')}: ${multiple}`}</MinAndMulti>
        {hasMoreInfo && (
          <MoreInfo>
            {t('moreInfoPrice')}
            <ArrowRight fill={colors.secondary} />
          </MoreInfo>
        )}
      </MoreInfoContainer>
      {showButton()}
    </CardContainer>
  )
}

ProductHorizontalCard.defaultProps = {
  categoryName: '',
  categoryId: '',
  temperature: '',
  name: '',
  provider: {},
  price: 0,
  min: 0,
  multiple: 0,
  measureUnit: '',
  refProp: () => {},
  pricePerKg: 0,
  id: '',
  isSimpleCard: false,
  raw_material: 'food',
  notAvailable: false,
  reference: '',
  setOnConfirmProduct: () => {},
  setShowModalConfirmationProduct: () => {},
  showJustButtonCard: false,
  previousPrice: null,
  availability: null,
  hasMoreInfo: false,
  refButton: null,
  onNext: () => {},
  onPrevious: () => {},
  showNextDate: false,
}

ProductHorizontalCard.propTypes = {
  categoryName: PropTypes.string,
  categoryId: PropTypes.string,
  temperature: PropTypes.string,
  name: PropTypes.string,
  provider: PropTypes.object,
  price: PropTypes.number,
  min: PropTypes.number,
  multiple: PropTypes.number,
  measureUnit: PropTypes.string,
  refProp: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  pricePerKg: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSimpleCard: PropTypes.bool,
  raw_material: PropTypes.string,
  notAvailable: PropTypes.bool,
  reference: PropTypes.string,
  setOnConfirmProduct: PropTypes.func,
  setShowModalConfirmationProduct: PropTypes.func,
  showJustButtonCard: PropTypes.bool,
  previousPrice: PropTypes.number,
  availability: PropTypes.object,
  hasMoreInfo: PropTypes.bool,
  refButton: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  showNextDate: PropTypes.bool,
}

export default ProductHorizontalCard
