import styled from 'styled-components'
import colors from 'Assets/colors'
import { media } from 'Config/styled'

export const Row = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey4};
  display: grid;
  grid-template-columns: ${props => (props.buttonText ? '65% 35%' : '100%')};
  padding: 19px;
  border-radius: 8px;
  margin: 16px 0;

  ${media('lg')} {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
`

export const TutorialContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const TutorialText = styled.div`
  color: ${colors.black};
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  a {
    text-decoration-line: underline;
    color: ${colors.white};
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: -8px 0;

  ${media('lg')} {
    justify-content: flex-start;
    margin: 0;
  }
`

export const ActionButton = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  height: 40px;
  display: inline-block;
  padding: 8px 12px;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }

  ${media('lg')} {
    padding: 0;
    height: auto;
  }
`

export const ButtonLiteral = styled.div`
  color: ${colors.secondary};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin: 0 auto;
`
export const CloseButton = styled.div``
