import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import moment from 'moment'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import * as Sentry from 'Services/sentry'
import Typography from '@material-ui/core/Typography'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import { ReactComponent as Download } from 'Assets/Icons/Descargar.svg'
import { debounceAmount } from 'Config/constants'
import routes from 'Config/routes'
import { selectCenterId } from 'Redux/purchases/utils'
import { getDownloadHistoric, getProviderOrdersQaExcel } from 'Services/api'
import { PurchaseActions, PurchaseTypes } from 'Redux/purchases'
import { SearchBar, CartDrawer } from 'Components/organisms'
import { Notification, Modal } from 'Components/atoms'
import { useDebounce } from 'Components/utils/customHooks'
import { HistoryOrderProduct, HistoryFilter, ModalRepeatOrderErrors } from 'Components/molecules'
import { selectConfig } from 'Redux/auth/utils'
import ModalDownloadEmail from 'Components/molecules/modalDownloadEmail'
import { fetchDeliveryErrors } from 'Redux/delivery/actions'
import ActionButton from 'Components/atoms/actionButton'
import useIsMobile from 'hooks/useIsMobile'

import FailedRequests from '../failedRequests'
import { ORDER_STATUS } from '../../../Config/constants'

import {
  Container,
  RequestsContainer,
  SingleRequestContainer,
  RequestHeader,
  RequestHeaderInfo,
  Header,
  TabButton,
  Title,
  ButtonContainer,
  ButtonLiteral,
  ActionButtonDownload,
  SmallActionButtonDownload,
  ButtonLiteralCenter,
  ButtonContainerSmall,
  TabSection,
  RightTabSection,
} from './styled'

const Historic = () => {
  const LIMIT = 15
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const deliveryErrors = useSelector(state => state.delivery.deliveryErrors)
  const orders = useSelector(({ purchase }) => purchase.orders)

  const previusOrderProducts = useSelector(state => state.purchase.previusOrderProducts)
  const centerId = useSelector(selectCenterId)
  const providerOrders = useSelector(({ purchase }) => purchase.providerOrders)

  const cart = useSelector(state => state.purchase.cart)
  const features = useSelector(selectConfig)
  const cartInitiated = useSelector(state => state.purchase.cartInitiated)
  const debouncedCart = useDebounce(cart, debounceAmount)

  const numProviderPendingOrders = useSelector(state => state.purchase.numProviderPendingOrders)
  const errorProviderOrders = useSelector(({ purchase }) => purchase.errorProviderOrders)
  const isLoadingProviderOrders = useSelector(({ purchase }) => purchase.isLoadingProviderOrders)

  const { state } = useLocation()
  const observer = useRef()

  const [showDrawer, setShowDrawer] = useState(false)
  const [ordersWithErrors, setOrdersWithErrors] = useState(false)
  const [ordersList, setProviderOrdersList] = useState([])

  const [notices, setNotices] = useState(state?.initialTab ? state.initialTab : 'history')
  const [hasMore, setHasMore] = useState('')
  const [pageNumber, setPageNumber] = useState(0)
  const offset = pageNumber * LIMIT
  const [queryParams, setQueryParams] = useState({ offset, filters: state ? state.filter : {} })

  const [showDownloadQaExcelModal, setShowDownloadQaExcelModal] = useState(false)
  const [showDownloadProviderOrdersExcelModal, setShowDownloadProviderOrdersExcelModal] = useState(false)
  const [showDownloadButton, setShowDownloadButton] = useState(false)

  const lastProductElementRef = useCallback(
    node => {
      if (isLoadingProviderOrders) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(pageNumber + 1)
          setQueryParams({ ...queryParams, offset: (pageNumber + 1) * LIMIT })
        }
      })
      if (node) observer.current.observe(node)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingProviderOrders, hasMore]
  )

  const handleFilters = values => {
    setPageNumber(0)

    setProviderOrdersList([])
    setQueryParams({ filters: values, offset: 0 })
  }

  const hasItemsToAdd = useMemo(() => {
    if (previusOrderProducts.lines && previusOrderProducts.lines.length > 0) {
      return true
    }
    return false
  }, [previusOrderProducts])

  const handleNewOrder = useCallback(() => {
    history.push(routes.previusOrder)
  }, [history])

  const handleDownloadHistoric = useCallback(() => {
    getDownloadHistoric(queryParams.filters, centerId)
      .then(() => setShowDownloadProviderOrdersExcelModal(true))
      .catch(e => {
        toast.error(t('petitionError'))
        Sentry.captureException(e)
      })
  }, [centerId, queryParams.filters, t])

  const handleDownloadQa = useCallback(() => {
    getProviderOrdersQaExcel(queryParams.filters, centerId)
      .then(() => setShowDownloadQaExcelModal(true))
      .catch(e => {
        toast.error(t('petitionError'))
        Sentry.captureException(e)
      })
      .catch(() => toast.error(t('petitionError')))
  }, [centerId, queryParams.filters, t])

  const handleCloseContinueNotif = useCallback(() => {
    dispatch(PurchaseActions.setPreviusOrderProducts([]))
  }, [dispatch])

  const handleChangeFilters = useCallback(values => {
    handleFilters(values)
    setNotices(null)
  }, [])

  const exportDisabled = Object.keys(queryParams.filters).length <= 0
  const expotQaDisabled = exportDisabled || queryParams.filters?.status !== ORDER_STATUS.RC

  const handleReceptionError = useCallback(() => {
    if (!isEmpty(deliveryErrors)) {
      setPageNumber(0)

      setProviderOrdersList([])
      setNotices('showReceptionError')

      const requestIds = deliveryErrors.reduce(
        (accumulator, currentValue, currentIndex) => accumulator.concat(`ids[${currentIndex}]=${currentValue.id}&`),
        ''
      )
      const str = requestIds.slice(0, requestIds.length - 1)
      setQueryParams({ filters: { ids: str } })
    }
  }, [deliveryErrors])

  const handleShowPR = useCallback(() => {
    handleFilters({ status: ORDER_STATUS.PR })
    setNotices('showPR')
  }, [])

  const handleShowErrorRequests = useCallback(() => {
    setNotices('showRequestsWithError')
  }, [])

  const onChangeTab = useCallback(
    (_event, newValue) => {
      setNotices(newValue)
      switch (newValue) {
        case 'history':
          handleFilters({})
          break

        case 'showReceptionError':
          handleReceptionError()
          break
        case 'showPR':
          handleShowPR()
          break
        case 'showRequestsWithError':
          handleShowErrorRequests()
          break
        default:
      }
    },
    [handleReceptionError, handleShowErrorRequests, handleShowPR]
  )

  const hideFilters = notices === 'showReceptionError' || notices === 'showPR' || notices === 'showRequestsWithError'

  useEffect(() => {
    const newProviderOrderList = providerOrders || []

    setProviderOrdersList([...ordersList, ...newProviderOrderList])
    setHasMore(LIMIT === newProviderOrderList.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerOrders])

  useEffect(() => {
    if (!cartInitiated) {
      return
    }

    dispatch({ type: PurchaseTypes.UPDATE_CART, debouncedCart })

    /* eslint-disable-next-line */
  }, [debouncedCart, dispatch])

  useEffect(() => {
    dispatch({
      type: PurchaseTypes.FETCH_ORDERS,
      params: {
        endDate: moment().add(2, 'day').startOf('day').format(),
        startDate: moment().subtract(1, 'year').startOf('day').format(),
        statuses: ['En Proceso', 'Error', 'Error Corregido'],
      },
    })

    dispatch({
      type: PurchaseTypes.FETCH_PENDING_PROVIDER_ORDERS,
    })

    if (features?.order_reception_sqs) {
      dispatch(fetchDeliveryErrors())
    }
  }, [dispatch, features])

  useEffect(() => {
    dispatch({
      type: PurchaseTypes.FETCH_PROVIDER_ORDERS,
      params: { ...queryParams.filters, offset: queryParams.offset, limit: LIMIT },
    })
  }, [dispatch, queryParams])

  useEffect(() => {
    if (orders) {
      const inProcess = orders.filter(order => order.state === 'Error' || order.state === 'Error Corregido')
      setOrdersWithErrors(inProcess.length > 0)
    }
  }, [orders])

  const handleClickExcel = () => {
    setShowDownloadButton(true)
  }

  if (!orders) return null

  return (
    <>
      <SearchBar setShowDrawer={setShowDrawer} />
      <Container $tutorial={false}>
        <Header>
          <TabSection>
            <Notices
              onChangeTab={onChangeTab}
              notices={notices}
              deliveryErrors={deliveryErrors}
              failedReceptions={ordersWithErrors}
              numProviderPendingOrders={numProviderPendingOrders}
            />

            <RightTabSection>
              {!hideFilters && <HistoryFilter onChange={handleChangeFilters} value={queryParams.filters} />}
              {!exportDisabled && expotQaDisabled && (
                <ButtonContainerSmall>
                  <SmallActionButtonDownload onClick={handleDownloadHistoric} disabled={exportDisabled}>
                    <ButtonLiteralCenter>{t('downloadOrders')}</ButtonLiteralCenter>
                  </SmallActionButtonDownload>
                </ButtonContainerSmall>
              )}

              {exportDisabled && !expotQaDisabled && (
                <ButtonContainerSmall>
                  <ActionButtonDownload onClick={handleDownloadQa} disabled={expotQaDisabled}>
                    <ButtonLiteralCenter>{t('downloadOrdersQa')}</ButtonLiteralCenter>
                  </ActionButtonDownload>
                </ButtonContainerSmall>
              )}

              {!exportDisabled && !expotQaDisabled && (
                <ActionButton onClick={handleClickExcel} buttonText={t('download')} Icon={Download} />
              )}
            </RightTabSection>
          </TabSection>
        </Header>

        {hasItemsToAdd && (
          <Notification
            text={t('continueFromLastRequest')}
            color='info'
            icon='close'
            buttonText={`${t('gotoRequest')} ${previusOrderProducts.id}`}
            onClick={handleNewOrder}
            onClose={handleCloseContinueNotif}
          />
        )}
        {!features?.failed_requests &&
          orders.map(order => (
            <Notification
              key={order.id}
              color={order.state !== 'Error' ? '' : 'error'}
              icon={order.state !== 'Error' ? 'loading' : 'error'}
              text={
                order.state !== 'Error'
                  ? t('requestProcesingText', { orderId: order.id })
                  : t('requestErrorText', { orderId: order.id })
              }
            />
          ))}
        <RequestsContainer>
          {errorProviderOrders && <Notification color='error' icon='error' text={t('errorLoadingOrders')} />}

          {isLoadingProviderOrders && (!ordersList || ordersList.length <= 0) ? (
            <RequestsLoader />
          ) : (
            <>
              {notices === 'showRequestsWithError' && <FailedRequests />}
              {notices !== 'showRequestsWithError' && ordersList.length === 0 && !errorProviderOrders && (
                <Notification color='error' icon='error' text={t('emptyResults')} />
              )}
              {notices !== 'showRequestsWithError' &&
                ordersList?.map((order, index) => (
                  <SingleRequestContainer
                    key={order.id}
                    ref={ordersList.length === index + 1 ? lastProductElementRef : null}
                  >
                    <RequestHeader>
                      {order.request ? (
                        <RequestHeaderInfo>{t('request', { request: order.request })}</RequestHeaderInfo>
                      ) : (
                        <div>&nbsp;</div>
                      )}
                      <RequestHeaderInfo>{moment(order.created).format('DD/MM/YYYY')}</RequestHeaderInfo>
                    </RequestHeader>
                    <HistoryOrderProduct centerId={centerId} order={order} />
                  </SingleRequestContainer>
                ))}
            </>
          )}
        </RequestsContainer>
      </Container>
      <CartDrawer showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
      <ButtonModal
        showModal={showDownloadButton}
        setShowModal={setShowDownloadButton}
        onDownloadHistoric={handleDownloadHistoric}
        onDownloadQA={handleDownloadQa}
        disabledDownloadHistoric={exportDisabled}
        disabledDownloadQA={expotQaDisabled}
      />
      <ModalRepeatOrderErrors />
      <ModalDownloadEmail
        setShowModal={() => setShowDownloadProviderOrdersExcelModal(false)}
        showModal={showDownloadProviderOrdersExcelModal}
        title={t('downloadOrdersExcelMultipleTitle')}
        description={t('downloadOrdersExcelMultipleDescription')}
      />
      <ModalDownloadEmail
        setShowModal={() => setShowDownloadQaExcelModal(false)}
        showModal={showDownloadQaExcelModal}
        title={t('downloadQaExcelMultipleTitle')}
        description={t('downloadQaExcelMultipleDescription')}
      />
    </>
  )
}

const RequestsLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width={1400}
      height={232}
      viewBox='0 0 1400 232'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='18' y='60' rx='0' ry='0' width='700' height='10' />
      <rect x='18' y='18' rx='0' ry='0' width='150' height='10' />
      <rect x='18' y='110' rx='0' ry='0' width='100' height='10' />
      <rect x='400' y='110' rx='0' ry='0' width='100' height='10' />
      <rect x='18' y='142' rx='0' ry='0' width='700' height='10' />
    </ContentLoader>
  )
}

const Notices = ({ onChangeTab, deliveryErrors, failedReceptions, numProviderPendingOrders, notices }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Tabs value={notices} onChange={onChangeTab} variant={isMobile ? 'scrollable' : 'fixed'} scrollButtons='auto'>
        <TabButton label={<Typography variant='h6'>{t('history')}</Typography>} value='history' />

        {!isEmpty(deliveryErrors) && (
          <TabButton
            label={<Typography variant='subtitle1'>{t('showReceptionError')}</Typography>}
            value='showReceptionError'
          />
        )}
        {numProviderPendingOrders && (
          <TabButton label={<Typography variant='subtitle1'>{t('showPR')}</Typography>} value='showPR' />
        )}
        {failedReceptions && (
          <TabButton
            label={<Typography variant='subtitle1'>{t('showRequestsWithError')}</Typography>}
            value='showRequestsWithError'
          />
        )}
      </Tabs>
    </Box>
  )
}

Notices.defaultProps = {
  onChangeTab: () => null,
  notices: null,
  deliveryErrors: null,
  failedReceptions: false,
  numProviderPendingOrders: false,
}

Notices.propTypes = {
  onChangeTab: PropTypes.func,
  notices: PropTypes.string,
  deliveryErrors: PropTypes.any,
  failedReceptions: PropTypes.bool,
  numProviderPendingOrders: PropTypes.bool,
}

const ButtonModal = ({
  showModal,
  setShowModal,
  disabledDownloadHistoric,
  disabledDownloadQA,
  onDownloadHistoric,
  onDownloadQA,
}) => {
  const { t } = useTranslation()

  const downloadQA = () => {
    onDownloadQA()
    setShowModal(false)
  }

  const downloadHistory = () => {
    onDownloadHistoric()
    setShowModal(false)
  }

  return (
    <Modal margin='2rem' isOpen={showModal} setIsOpen={setShowModal} height='15rem'>
      <Box alignSelf='center' width='100%'>
        <Title>{t('downloadMulticenterTitle')}</Title>

        <ButtonContainer>
          <ActionButtonDownload onClick={downloadHistory} disabled={disabledDownloadHistoric}>
            <ButtonLiteral>{t('downloadOrders')}</ButtonLiteral>
          </ActionButtonDownload>
        </ButtonContainer>

        <ButtonContainer>
          <ActionButtonDownload onClick={downloadQA} disabled={disabledDownloadQA}>
            <ButtonLiteral>{t('downloadOrdersQa')}</ButtonLiteral>
          </ActionButtonDownload>
        </ButtonContainer>
      </Box>
    </Modal>
  )
}

ButtonModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  disabledDownloadHistoric: PropTypes.bool,
  disabledDownloadQA: PropTypes.bool,
  onDownloadHistoric: PropTypes.func,
  onDownloadQA: PropTypes.func,
}

ButtonModal.defaultProps = {
  showModal: false,
  setShowModal: () => null,
  disabledDownloadHistoric: false,
  disabledDownloadQA: false,
  onDownloadHistoric: () => null,
  onDownloadQA: () => null,
}

export default Historic
