import React, { useCallback } from 'react'

import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { ReactComponent as ArrowLeft } from 'Assets/Icons/ArrowLeft.svg'

import { BackSection } from './styled'

const BackButton = ({ cMarginRight, cTop, cLeft }) => {
  const history = useHistory()

  const onBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack()
    }
  }, [history])

  return (
    <BackSection onClick={onBack} marginRight={cMarginRight} left={cLeft} top={cTop} hasRoutes={history.length > 1}>
      <ArrowLeft />
    </BackSection>
  )
}

BackButton.propTypes = {
  cMarginRight: PropTypes.string,
  cTop: PropTypes.string,
  cLeft: PropTypes.string,
}

BackButton.defaultProps = {
  cMarginRight: '10px',
  cTop: '0px',
  cLeft: '0px',
}

export default BackButton
