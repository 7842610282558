import { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'
import { PurchaseTypes } from 'Redux/purchases'
import routes from 'Config/routes'
import { selectConfig } from 'Redux/auth/utils'

const MobileHideSiderbar = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { providerId } = useParams()

  useEffect(() => {
    if (providerId) dispatch({ type: PurchaseTypes.FETCH_CATEGORIES_PROVIDERS, params: { providerId } })
  }, [dispatch, providerId])

  const isHabitualRoute = _.get(history, 'location.pathname', '').includes(routes.habitualCats)

  const features = useSelector(selectConfig)

  useEffect(() => {
    dispatch({ type: PurchaseTypes.FETCH_CATEGORIES_HABITUAL })
  }, [isHabitualRoute, dispatch, features])

  return null
}

export default MobileHideSiderbar
