import { media } from 'Config/styled'
import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import colors from 'Assets/colors'

export const useStyles = makeStyles(() => ({
  button: {
    height: '56px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
    textTransform: 'none',
    color: colors.white,
    transition: 'all 0.2s',
    border: `1px solid ${colors.secondary}`,
    backgroundColor: colors.secondary,
    '&:hover': {
      opacity: 0.8,
      color: colors.white,
      transform: 'translateY(-2px)',
      backgroundColor: colors.secondary,
      boxShadow: '0 3px 5px rgba(51, 51, 51, 0.2)',
    },
    '&:active': {
      transform: 'translate(-1px)',
      boxShadow: '0 1px 3px rgba(51, 51, 51, 0.4)',
    },
  },
}))

export const SectionContainer = styled.div`
  width: 21.25rem;
  height: calc(100vh - 250px);
  overflow-y: hidden;
  position: relative;
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  opacity: ${props => props.opacity};
  overflow-y: scroll;

  ${props =>
    props.$tutorial &&
    css`
      height: calc(100vh - 216px);
    `}

  ${props =>
    props.isHidden &&
    css`
      height: calc(100vh - 6.5rem);
    `}

  ${props =>
    props.needsApproval &&
    css`
      height: calc(100vh - 300px);
    `}

    ${media('lg')} {
      width: 330px;
      height: calc(100vh - 90px);
    }
`

export const ProviderName = styled.div`
  padding: 1.5rem 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: ${props => props.theme.colors.black};
`
