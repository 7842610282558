import { useState, useEffect } from 'react'

import { useTheme } from '@material-ui/core/styles'

const useIsMobile = breakpointPx => {
  const theme = useTheme()
  const breakpoint = breakpointPx ?? theme.breakpoints.values.lg

  const [isMobile, setIsMobile] = useState(() => window.matchMedia(`(max-width: ${breakpoint}px)`).matches)

  useEffect(() => {
    const query = `(max-width: ${breakpoint}px)`
    const mediaQuery = window.matchMedia(query)

    const updateMatch = () => setIsMobile(mediaQuery.matches)

    mediaQuery.addEventListener('change', updateMatch)
    updateMatch()

    return () => {
      mediaQuery.removeEventListener('change', updateMatch)
    }
  }, [breakpoint])

  return isMobile
}

export default useIsMobile
