import styled, { css } from 'styled-components'
import colors from 'Assets/colors'
import { media } from 'Config/styled'

export const Container = styled.div`
  padding: 2.5rem 1.25rem 4.1rem;
  height: calc(100vh - 216px);
  overflow: auto;

  ${props =>
    props.$tutorial &&
    css`
      height: calc(100vh - 216px);
    `}

  ${media('lg')} {
    display: flex;
    flex-direction: column;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Grow = styled.div`
  flex-grow: 1;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 1.5rem;
  justify-content: space-between;
  align-items: center;
`

export const ButtonExport = styled.div`
  background-color: ${colors.secondary};
  font-family: Inter;
  font-style: normal;
  max-width: 100%;
  width: 288px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: none;
  color: ${colors.white};
  transition: all 0.2s;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  height: 56px;
  opacity: ${({ disabled }) => (disabled ? '.4' : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  & svg {
    margin-right: 0.5rem;
    fill: ${colors.white};
    & path {
      fill: ${colors.white};
    }
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &.disabled {
    background-color: ${colors.gray40l};
    pointer-events: none;
  }
`

export const RequestsContainer = styled.div``

export const SingleRequestContainer = styled.div`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  margin: 12px 0;
  background: #fafafa;

  ${media('lg')} {
    display: flex;
    flex-direction: column;
  }
`

export const RequestHeader = styled.div`
  background: ${colors.black};
  color: ${colors.white};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
`

export const RequestHeaderInfo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`
