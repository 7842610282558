const validate = (data, category, files, providerId, t) => {
  const { status, questions, redelivery } = data
  const errors = {}

  if (!status) {
    errors.status = t('mandatoryField')
  } else {
    if (category.redelivery && !redelivery) {
      errors.redelivery = t('mandatoryField')
    }

    errors.questions = category?.questions?.map(question => {
      const findRequiredQuestion = questions?.find(v => v.id === question.id)
      if (!findRequiredQuestion?.value && question?.required) {
        return { id: question.id, error: t('mandatoryField') }
      }
      return { id: question.id, error: '' }
    })
  }

  return errors
}

export default validate
