import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import colors from 'Assets/colors'
import { media } from 'Config/styled'

export const Container = styled.div`
  height: 95vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  ${media('lg')} {
    justify-content: flex-start;
  }
`

export const Input = styled(TextField)`
  width: 50%;
`
export const LoginContainer = styled.div`
  max-width: 42.5rem;
  background: white;
  padding: 100px 80px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  position: relative;
  bottom: 0px;
  ${media('lg')} {
    padding: 10px 20px;
  }
`
export const AppVersion = styled.div`
  margin-top: 30px;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: ${colors.grey3};
`
export const RootContainer = styled(Container)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
