import React from 'react'

import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { ReactComponent as CartIcon } from 'Assets/Icons/Cart.svg'
import { NumberFormat } from 'Components/atoms'
import BackButton from 'Components/atoms/backButton'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import useIsMobile from 'hooks/useIsMobile'

import {
  useStyles,
  Row,
  Container,
  TotalItems,
  TitleSection,
  HorizontalTitleContainer,
  TitleSectionNormal,
} from './styled'

const Bar = ({ setShowDrawer, requestId }) => {
  const hidden = useSelector(state => state.auth.hiddenNavbar)
  const selectedCenter = useSelector(state => state.auth.selectedCenter)
  const cart = useSelector(state => state.purchase.cart)
  const classes = useStyles()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { showPrice } = selectedCenter

  const hasSomethingInCart = !!_.find(cart, ({ amount = 0 }) => amount > 0)

  const fullTotal = hasSomethingInCart
    ? _.chain(cart)
        .map(({ total }) => {
          if (!total) return 0
          return total
        })
        .sum()
        .value()
    : ''

  const productQuantity = hasSomethingInCart
    ? _.chain(cart)
        .map(({ amount }) => (amount > 0 ? 1 : 0))
        .sum()
        .value()
    : ''

  return (
    <Container className={classes.root} hiddenBar={hidden}>
      <BackButton />
      <Row>
        {isMobile ? (
          <Box
            display='flex'
            flexDirection='column-reverse'
            justifyContent='space-between'
            gridRowGap='1rem'
            width='100%'
          >
            <Typography variant='h6'>
              {t('newRequest')} {t('newrequestFrom')} {requestId}{' '}
            </Typography>
            <Button
              startIcon={<CartIcon />}
              className={classes.menuButton}
              color='inherit'
              onClick={() => setShowDrawer(true)}
            >
              {hasSomethingInCart && showPrice ? <NumberFormat value={fullTotal} /> : t('cart')}
              {hasSomethingInCart ? <TotalItems>{productQuantity}</TotalItems> : null}
            </Button>
          </Box>
        ) : (
          <>
            <HorizontalTitleContainer>
              <TitleSection>{t('newRequest')}</TitleSection>
              <TitleSectionNormal>{t('newrequestFrom')}</TitleSectionNormal>
              <TitleSection>{requestId}</TitleSection>
            </HorizontalTitleContainer>
            <Button
              startIcon={<CartIcon />}
              className={classes.menuButton}
              color='inherit'
              onClick={() => setShowDrawer(true)}
            >
              {hasSomethingInCart && showPrice ? <NumberFormat value={fullTotal} /> : t('cart')}
              {hasSomethingInCart ? <TotalItems>{productQuantity}</TotalItems> : null}
            </Button>
          </>
        )}
      </Row>
    </Container>
  )
}

Bar.defaultProps = {
  setShowDrawer: () => {},
  requestId: 0,
}

Bar.propTypes = {
  setShowDrawer: PropTypes.func,
  requestId: PropTypes.number,
}

export default Bar
