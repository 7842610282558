import set from 'lodash/set'
import { QUANTITY_DIF_CATEGORY, DAMAGED_PRODUCT_CATEGORY } from 'Config/utils'

const validate = (lines, category, t) => {
  const errors = {}

  if (category.id === DAMAGED_PRODUCT_CATEGORY) {
    if (!category.requiredLot) {
      return errors
    }

    if (lines) {
      lines.forEach(({ isChecked, reference, affectedAmount }) => {
        if (isChecked && affectedAmount === '') {
          set(errors, `[${reference}].affectedAmount`, t('mandatoryField'))
        }
      })
    }
    return errors
  }

  if (category.id !== QUANTITY_DIF_CATEGORY && category.id !== DAMAGED_PRODUCT_CATEGORY) {
    if (lines && category.requiredLot) {
      lines.forEach(({ isChecked, lot, expirationDate, reference, affectedAmount }) => {
        if (isChecked && !lot) set(errors, `[${reference}].lot`, t('mandatoryField'))
        if (isChecked && !expirationDate) set(errors, `[${reference}].expirationDate`, t('mandatoryField'))
        if (isChecked && affectedAmount === '') set(errors, `[${reference}].affectedAmount`, t('mandatoryField'))
      })
    } else {
      lines.forEach(({ isChecked, lot, expirationDate, reference, affectedAmount }) => {
        if (isChecked && !lot && (expirationDate || affectedAmount !== '')) {
          set(errors, `[${reference}].lot`, t('mandatoryField'))
        }
        if (isChecked && affectedAmount === '' && (expirationDate || lot)) {
          set(errors, `[${reference}].affectedAmount`, t('mandatoryField'))
        }

        if (isChecked && !expirationDate && (affectedAmount !== '' || lot)) {
          set(errors, `[${reference}].expirationDate`, t('mandatoryField'))
        }
      })
    }
  } else if (lines) {
    lines.forEach(({ isChecked, lot, expirationDate, reference, affectedAmount, amount }) => {
      if (affectedAmount !== amount && affectedAmount !== '0' && affectedAmount !== '') {
        if (isChecked && !lot) set(errors, `[${reference}].lot`, t('mandatoryField'))
        if (isChecked && !expirationDate) set(errors, `[${reference}].expirationDate`, t('mandatoryField'))
      }
    })
  }

  return errors
}

export default validate
