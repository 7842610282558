import {
  SET_PROVIDER_RECIVING_ORDERS,
  SET_PROVIDER_RECIVING_ORDERS_LOADING,
  SET_PROVIDER_RECIVING_ORDERS_ERROR,
  SET_PROVIDER_RECIVING_ORDERS_FORM,
  SET_DELIVERY_FORM,
  SET_DELIVERY_ERRORS,
  SET_PROVIDER_ORDER_FOR_NC,
} from './types'

const initialState = {
  providerOrder: {},
  receptionlines: null,
  isLoadingProviderRecivingOrders: false,
  errorProviderRecivingOrders: false,
  deliveryForm: {},
  providerRecivingOrdersForm: {},
  deliveryErrors: [],
  providerOrderForNc: null,
}

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROVIDER_RECIVING_ORDERS:
      return {
        ...state,
        providerOrder: action.payload.providerOrder,
        receptionlines: action.payload.receptionlines,
      }
    case SET_PROVIDER_RECIVING_ORDERS_LOADING:
      return { ...state, isLoadingProviderRecivingOrders: action.payload }
    case SET_PROVIDER_RECIVING_ORDERS_ERROR:
      return { ...state, errorProviderRecivingOrders: action.payload }
    case SET_PROVIDER_RECIVING_ORDERS_FORM:
      return { ...state, providerRecivingOrdersForm: action.payload }
    case SET_DELIVERY_FORM:
      return { ...state, deliveryForm: action.payload }
    case SET_DELIVERY_ERRORS:
      return { ...state, deliveryErrors: action.payload }
    case SET_PROVIDER_ORDER_FOR_NC:
      return { ...state, providerOrderForNc: action.payload }
    default:
      return state
  }
}

export default Reducer
