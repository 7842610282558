import React from 'react'

import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import get from 'lodash/get'
import map from 'lodash/map'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { NumberFormat } from 'Components/atoms'
import ContentLoader from 'react-content-loader'
import { CART_STATUS } from 'Config/constants'
import { useDispatch } from 'react-redux'
import { setRequestOrder } from 'Redux/approver/actions'

import {
  Title,
  CartRow,
  CartHeader,
  CartHeaderInfo,
  Tag,
  CartBody,
  Center,
  Code,
  Price,
  Products,
  ButtonContainer,
  ActionButton,
  ButtonLiteral,
  CartHeaderDate,
} from './styled'

const { PENDING, CLOSE_EXPIRED, APPROVED, REJECTED } = CART_STATUS

const ApproverCartsList = ({ title, data, status }) => (
  <>
    <Title>{title}</Title>
    {map(data, item => (
      <CartItem key={item.id} item={item} status={status} />
    ))}
  </>
)

ApproverCartsList.defaultProps = {
  title: '',
  data: [],
  status: '',
}

ApproverCartsList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  status: PropTypes.string,
}

const CartItem = ({ item, status }) => (
  <CartRow>
    <CartItemHeader status={status} item={item} />
    <CartItemBody status={status} item={item} />
  </CartRow>
)

CartItem.defaultProps = {
  item: {},
  status: '',
}

CartItem.propTypes = {
  item: PropTypes.object,
  status: PropTypes.string,
}

const CartItemHeader = ({ status, item }) => {
  const { t } = useTranslation()

  const requestDate = moment(get(item, 'created')).format('DD/MM/YYYY')
  const limitDate = moment(get(item, 'autoApprovedDate')).format('DD/MM/YYYY')
  const statusUpdatedAt = moment(get(item, 'statusUpdatedAt')).format('DD/MM/YYYY')
  const username = get(item, 'username')
  const statusUpdatedBy = get(item, 'statusUpdatedBy')
  const updatedByCopy = statusUpdatedBy ? t('requestApproved', { statusUpdatedBy }) : t('userApprovedAuto')

  if (status === CLOSE_EXPIRED || status === PENDING) {
    return (
      <CartHeader status={status}>
        <CartHeaderInfo>{t('limitDateApprover', { limitDate })}</CartHeaderInfo>
        <Box display='flex'>
          <CartHeaderDate>{t('createdBy', { user: username })}</CartHeaderDate>
        </Box>
      </CartHeader>
    )
  }

  if (status === APPROVED) {
    return (
      <CartHeader status={status}>
        <CartHeaderInfo>{updatedByCopy}</CartHeaderInfo>
        <Box display='flex'>
          <CartHeaderDate margin>{t('approvedDate', { approvedDate: statusUpdatedAt })}</CartHeaderDate>
          <CartHeaderDate>{t('requestDate', { requestDate })}</CartHeaderDate>
        </Box>
      </CartHeader>
    )
  }

  if (status === REJECTED) {
    return (
      <CartHeader status={status}>
        <CartHeaderInfo>{t('requestRejected', { statusUpdatedBy })}</CartHeaderInfo>
        <Box display='flex'>
          <CartHeaderDate margin>{t('rejectedDate', { rejectedDate: statusUpdatedAt })}</CartHeaderDate>
          <CartHeaderDate>{t('requestDate', { requestDate })}</CartHeaderDate>
        </Box>
      </CartHeader>
    )
  }

  return null
}

CartItemHeader.defaultProps = {
  item: {},
  status: '',
}

CartItemHeader.propTypes = {
  item: PropTypes.object,
  status: PropTypes.string,
}

const CartItemBody = ({ item }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const requestId = get(item, 'id', '')
  const centerName = get(item, 'target.name', '')
  const deliveryDate = get(item, 'approvedDeliveryDate', get(item, 'deliveryDate'))
  const deliveryDateFormatted = moment(deliveryDate).format('DD/MM/YYYY')
  const total = get(item, 'approvedTotal', get(item, 'currentTotal', get(item, 'total', 0)))
  const totalLines = get(item, 'approvedTotalLines', get(item, 'currentTotalLines', get(item, 'totalLines', 0)))

  const handleClickSeeCart = () => dispatch(setRequestOrder(item))

  return (
    <CartBody>
      <Box
        display='flex'
        flex={1}
        rowGap='0.2rem'
        sx={{
          flexDirection: { xs: 'column', lg: 'row' },
          rowGap: '0.2rem',
          alignItems: { xs: 'flex-start', lg: 'unset' },
          width: { xs: '100%', lg: 'unset' },
          paddingBottom: { xs: '0.5rem', lg: 'unset' },
        }}
      >
        <Center>{centerName}</Center>
        <Tag>{t('deliveryDateApprover', { deliveryDate: deliveryDateFormatted })}</Tag>
        <Code>{t('requestIdApprover', { requestId })}</Code>
        <Price>
          <NumberFormat value={total} suffix='€' />
        </Price>
        <Products>
          <NumberFormat
            value={totalLines}
            suffix={totalLines === 1 ? t('numberOfProduct') : t('numberOfProducts')}
            decimalScale={0}
          />
        </Products>
      </Box>
      <ButtonContainer>
        <ActionButton onClick={handleClickSeeCart} aria-label={t('approverTutorial.stepFive.target')}>
          <ButtonLiteral>{t('seeCart2')}</ButtonLiteral>
        </ActionButton>
      </ButtonContainer>
    </CartBody>
  )
}

CartItemBody.defaultProps = {
  item: {},
}

CartItemBody.propTypes = {
  item: PropTypes.object,
}

export const CartsListLoader = () => (
  <>
    <RequestsLoader />
    <RequestsLoader />
  </>
)

const RequestsLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width='100%'
      height={210}
      viewBox='0 0 100% 210'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='0' y='9' rx='10' ry='10' width='300' height='16' />
      <rect x='0' y='50' rx='10' ry='10' width='100%' height='30' />
      <rect x='19' y='60' rx='0' ry='1' width='101' height='10' />
      <rect x='10' y='120' rx='0' ry='0' width='200' height='14' />
      <rect x='246' y='120' rx='6' ry='6' width='254' height='20' />
      <rect x='517' y='123' rx='0' ry='0' width='150' height='14' />
    </ContentLoader>
  )
}

export default ApproverCartsList
