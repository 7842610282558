import styled, { css } from 'styled-components'
import { media } from 'Config/styled'
import colors from 'Assets/colors'

export const Container = styled.div`
  padding: 2.5rem 1.25rem 0;
  height: calc(100vh - 190px);
  position: relative;
  overflow: scroll;

  ${media('lg')} {
    height: calc(100vh - 210px);
    display: block;
    flex-wrap: wrap;
  }

  ${props =>
    props.$tutorial &&
    css`
      height: calc(100vh - 216px);
    `}
  ::-webkit-scrollbar {
    display: none;
  }
`

export const LoadingWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 1.5rem;
`

export const LowerMenu = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fafafa;
  border-top: 1px solid rgba(51, 51, 51, 0.24);
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;

  ${media('lg')} {
    grid-template-columns: 1fr;
    row-gap: 0.9rem;
    padding: 1rem 0;
  }
`

export const Center = styled.div`
  padding: 24px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media('lg')} {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const CenterHelper = styled.div`
  border-right: 1px solid rgba(51, 51, 51, 0.24);
`

export const CenterInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const CenterLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.gray40l};
`

export const CenterPlace = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.black};
`

export const CenterAction = styled.div`
  button {
  }
`

export const Selector = styled.button`
  background: ${colors.secondary};
  border-radius: 0.5rem;
  cursor: pointer;
  margin-left: 0.5rem;
  padding: 1rem 1.125rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const FinishButton = styled.div`
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  width: 100%;
  background-color: ${props => (props.disabled ? props.theme.colors.gray40l : props.theme.colors.cart)};
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.disabled ? props.theme.colors.white : props.theme.colors.black)};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;

  ${media('lg')} {
    flex-wrap: wrap;
  }

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const TotalItems = styled.div`
  background: #ffffff;
  border-radius: 4px;
  padding: 0px 8px;
  margin-left: 1rem;
  color: ${props => props.theme.colors.black};
`

export const ButtonLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-right: auto;
  ${media('lg')} {
    margin-rigth: 16px;
    font-size: 14px;
  }
`

export const HorizontalProviderNotAvailableContainer = styled.div`
  margin: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  background: ${props => props.theme.colors.gray98b};
`

export const HorizontalMessage = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  margin-left: 0.5rem;
`
export const NotAvailableBudgetRow = styled(Row)`
  padding-left: 1rem;
  margin-bottom: ${props => props.marginBottom};
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  border-bottom: 1px solid rgba(51, 51, 51, 0.24);
`

export const HorizontalTitleSection = styled(Row)`
  justify-content: flex-start;
  margin-right: 330px;
`
export const HorizontalTitleProduct = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  width: 31rem;
  margin-left: 1.1rem;

  ${media('lg')} {
    width: 100%;
  }
`

export const HorizontalTitleTotal = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  flex: 1;
`
export const NotAvailableTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.red};
`
export const HorizontalTitleValue = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  flex: 1;
`

export const HorizontalRedSquare = styled.div`
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.red};
  border: 1px solid ${props => props.theme.colors.red};
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
`

export const RowRed = styled.div`
  display: flex;
  align-items: center;
  margin: ${props => props.margin};
  justify-content: flex-start;
`
export const LabelMobile = styled.div`
  display: none;

  ${media('lg')} {
    display: block;
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
  }
`
