import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AVAILABILITY_STATES, AVAILABILITY, TEMPERATURES, DELIVERY_DATE_MIN_DAYS, MEASURE_UNIT } from 'Config/constants'
import { ReactComponent as FrozenIcon } from 'Assets/Icons/Frozen.svg'
import { ReactComponent as RefrigeratedIcon } from 'Assets/Icons/Refrigerated.svg'
import { ReactComponent as ArrowLeft } from 'Assets/Icons/ArrowLeft.svg'
import { AuthActions } from 'Redux/auth'
import Icons from 'Components/utils/categoryIcons'
import { getProviderOrdinaryDates } from 'Services/api'
import { handleHabitualCategories, selectCenterId } from 'Redux/purchases/utils'
import ArrowIcon from 'Assets/Icons/Arrow'
import colors from 'Assets/colors'
import { NumberFormat, ProductButton } from 'Components/atoms'
import { financial } from 'Components/utils/cartCalculator'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { needsApprovalMode } from 'Redux/approver/selectors'
import analyticsService from 'Services/analytics'
import * as Sentry from 'Services/sentry'
import { selectConfig } from 'Redux/auth/utils'

import ModalAddToList from '../modalAddToList'

import {
  Row,
  IconContainer,
  Title,
  Provider,
  Price,
  PriceKG,
  MinAndMulti,
  Border,
  AllergensTitle,
  Allergens,
  CommentsContainer,
  Comment,
  PreviousPrice,
  DifferencePrice,
  DifferencePriceKG,
  MoreInfoPrice,
  BoldText,
  IconSection,
  RowFullScreen,
  IconContainerFullScreen,
  ReturnText,
  RowFullScreenIcon,
  BtnSection,
  ReturnSection,
  AddListButton,
  PriceContainer,
  PriceRowContainer,
} from './styled'

const YES = 'SI'
const NO = 'NO'

const Product = ({ fixAllScreen }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const product = useSelector(state => state.purchase.product)
  const centerId = useSelector(selectCenterId)
  const isLoading = useSelector(state => state.purchase.isLoadingProduct)
  const center = useSelector(state => state.auth.selectedCenter)
  const needsApproval = useSelector(needsApprovalMode)
  const categories = useSelector(state => state.purchase.selectedCategories)
  const selectedHabitualCategories = useSelector(state => state.purchase.selectedCategoriesHabitual)
  const [dates, setDates] = useState({ availability: 'no', dates: [] })
  const [openAddListModal, setOpenAddListModal] = useState(false)
  const features = useSelector(selectConfig)

  const sendWishlistAnalytics = () => {
    const habitualCategories = handleHabitualCategories(selectedHabitualCategories)

    analyticsService.addToWishlist({
      products: [{ ...product, amount: product.min }],
      selectedCategories: habitualCategories || categories,
    })
  }

  const handleClickAddList = () => {
    setOpenAddListModal(prevState => !prevState)
    sendWishlistAnalytics()
  }

  const { showPrice } = center
  const productProviderId = product && product.provider ? product.provider.id : null

  useEffect(() => {
    if (productProviderId && centerId) {
      getProviderOrdinaryDates(productProviderId, centerId)
        .then(result => setDates(result))
        .catch(e => Sentry.captureException(e))
    }
  }, [productProviderId, centerId])

  const percentage = (num, per) => (num / 100) * per

  let categoryId = _.get(product, 'categoryId', '')
  if (categoryId === '') {
    categoryId = _.get(product, 'category.id', '')
  }
  const type = _.get(product, 'category.children.0.children.0.name', '')
  const temperature = _.get(product, 'temperature', '')
  const name = _.get(product, 'name', '')
  const provider = _.get(product, 'provider', {})
  const price = _.get(product, 'price', '')
  const measureUnit = _.get(product, 'measureUnit', '')
  const pricePerKg = _.get(product, 'pricePerKg', '')
  const min = _.get(product, 'min', '')
  const multiple = _.get(product, 'multiple', '')
  const reference = _.get(product, 'reference', '')
  const providerCode = _.get(product, 'provider.id', '')
  const allergens = _.get(product, 'allergens', [])
  const comments = _.get(product, 'comments', [])
  const availability = _.get(product, 'availability', null)
  const previousPrice = _.get(product, 'previous_price', _.get(product, 'previousPrice', null))
  const categoryData = _.get(product, 'category', null)
  const previusCategory =
    categoryData &&
    categoryData.children &&
    categoryData.children[0] &&
    categoryData.children[0].children &&
    categoryData.children[0].children[0] &&
    categoryData.children[0].children[0].name
      ? categoryData.children[0].children[0].name
      : null
  const differencePrice = previousPrice ? price - previousPrice : 0
  const minDifferencePrice = previousPrice ? percentage(previousPrice, 0.01) : 0
  const nextPrice = availability && availability.price && measureUnit ? availability.price : null
  const nextPriceDate = availability ? moment(availability.start_date).format('DD/MM') : null
  const availabilityUntil =
    availability && availability.end_date && availability.status === AVAILABILITY_STATES.AVAILABLE
      ? moment(availability.end_date).format('DD/MM/YYYY')
      : null

  const hasComments = comments.length > 0

  const positiveAllergens = allergens.filter(({ value }) => value === YES)
  const negativeAllergens = allergens.filter(({ value }) => value === NO)
  const otherAllergens = allergens.filter(({ value }) => value !== NO && value !== YES)
  otherAllergens.forEach(({ name: alergen, value }) => positiveAllergens.push({ name: `${alergen} (${value})` }))
  const priceDateValue = moment(product?.availability?.start_date).format('L')

  const getAdequateIcon = () => {
    if (temperature === TEMPERATURES.refrigerated) {
      return <RefrigeratedIcon style={{ marginRight: '0.5rem' }} />
    }

    if (temperature === TEMPERATURES.frozen) {
      return <FrozenIcon style={{ marginRight: '0.5rem' }} />
    }

    return ''
  }

  const IconCategory = Icons[categoryId] || (() => <></>)

  const PriceDate = () => <p>{`${t('priceChangedDate')}: ${priceDateValue}`}</p>

  const Arrow = () => (
    <ArrowIcon
      style={{
        marginRight: '0.5rem',
        marginLeft: '0.5rem',
        transform: differencePrice > 0 ? 'rotate(270deg)' : 'rotate(90deg)',
        position: 'relative',
        bottom: '6px',
        width: '22px',
        height: '22px',
      }}
      fill={differencePrice > 0 ? colors.red : colors.secondary}
    />
  )

  const renderPrice = () => (
    <div style={{ display: 'flex' }}>
      {showPrice && (
        <>
          {price ? (
            <Price>
              <NumberFormat value={price} suffix={`€/${measureUnit}`} />
            </Price>
          ) : null}
          {measureUnit !== MEASURE_UNIT.KG && pricePerKg ? (
            <PriceKG>
              <NumberFormat value={pricePerKg} suffix='€/KG' />
            </PriceKG>
          ) : null}
        </>
      )}
    </div>
  )

  const renderDifferencePrice = () => (
    <DifferencePrice>
      {showPrice && (
        <PriceContainer>
          <PriceRowContainer>
            <Price style={{ color: differencePrice > 0 ? colors.red : colors.secondary }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <NumberFormat value={price} suffix={`€/${measureUnit}`} />
                <Arrow />
              </Box>
            </Price>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {previousPrice ? (
                <PreviousPrice>
                  <NumberFormat value={previousPrice} suffix={`€/${measureUnit}`} />
                </PreviousPrice>
              ) : null}
              {measureUnit !== MEASURE_UNIT.KG && pricePerKg ? (
                <DifferencePriceKG>
                  <NumberFormat value={pricePerKg || 0} suffix='€/KG' />
                </DifferencePriceKG>
              ) : null}
            </Box>
          </PriceRowContainer>
          <PriceRowContainer>
            <PriceDate />
          </PriceRowContainer>
        </PriceContainer>
      )}
    </DifferencePrice>
  )

  const renderAllergenMessage = () => {
    if (negativeAllergens.length === 14) return 'Este producto no contiene'
    if (allergens.length === 0) return 'No están registrados en base de datos'
    if (allergens.length < 14) return 'No disponemos de toda la información de alérgenos de este producto'
    return null
  }

  const getNextDeliveryDate = () => {
    let nextDeliveryDate = moment().add(DELIVERY_DATE_MIN_DAYS, 'd')

    if (dates?.availability === AVAILABILITY.YES && dates?.dates && dates?.dates[0]) {
      nextDeliveryDate = moment(dates.dates[0].delivery)
    }
    const formattedDate = nextDeliveryDate.format('dddd, LL')

    return formattedDate
  }

  const onReturnPrevius = () => {
    dispatch(AuthActions.setRefreshingCategory(true))
    history.go(0)
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <>
      {!fixAllScreen ? (
        <>
          <Row>
            <IconContainer>
              <IconCategory style={{ marginRight: '0.5rem' }} />
              {type || product?.categoryName}
            </IconContainer>
            <IconContainer>
              {getAdequateIcon()}
              {temperature}
            </IconContainer>
          </Row>
          <Title>{name}</Title>
          <Provider>{provider?.name}</Provider>

          {!differencePrice || Math.abs(differencePrice) < Math.abs(minDifferencePrice)
            ? renderPrice()
            : renderDifferencePrice()}
          {nextPrice && nextPriceDate && (
            <MoreInfoPrice>
              {t('moreInfoDescription1')} <BoldText>{nextPriceDate}</BoldText> {t('moreInfoDescription2')}{' '}
              <BoldText>
                <NumberFormat value={nextPrice} suffix={`€/${measureUnit}`} />
              </BoldText>
            </MoreInfoPrice>
          )}
          <MinAndMulti>{`${t('min')}: ${financial(min)} / ${t('multi')}: ${financial(multiple)}`}</MinAndMulti>
          <BtnSection>
            <ProductButton {...product} fromModal />
            {!needsApproval && features?.shopping_lists && (
              <AddListButton onClick={handleClickAddList}>{t('shoppingList.addToList.btnTitle')}</AddListButton>
            )}
          </BtnSection>
          {hasComments ? (
            <CommentsContainer>
              {comments?.map(({ note }) => (
                <Comment key={note}>{note}</Comment>
              ))}
            </CommentsContainer>
          ) : null}
          <Border />
          <AllergensTitle>Alérgenos del producto</AllergensTitle>
          <Allergens>
            {positiveAllergens?.length > 0 &&
              positiveAllergens.map(({ name: allergenName }, index) => {
                if (index === positiveAllergens.length - 1) return allergenName
                return `${allergenName}, `
              })}{' '}
            {renderAllergenMessage()}
          </Allergens>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <AllergensTitle>Próxima fecha de entrega</AllergensTitle>
              <Allergens> {getNextDeliveryDate()}</Allergens>
            </div>
          </div>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', lg: 'row' } }}>
            <Box flex={1}>
              <AllergensTitle>Código del producto</AllergensTitle>
              <Allergens>{reference}</Allergens>
            </Box>
            <Box flex={1}>
              <AllergensTitle>Código del proveedor</AllergensTitle>
              <Allergens>{providerCode}</Allergens>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <RowFullScreenIcon>
            <div>
              {previusCategory ? (
                <ReturnSection onClick={onReturnPrevius}>
                  <ArrowLeft />
                  <ReturnText>{`${t('returnPrevius')} ${previusCategory?.toUpperCase()}`}</ReturnText>
                </ReturnSection>
              ) : null}
              <Title>{name}</Title>
              <Provider>{provider?.name}</Provider>
            </div>
            <IconSection>
              <IconContainerFullScreen>
                <IconCategory style={{ marginRight: '0.5rem' }} />
                {type || product?.categoryName}
              </IconContainerFullScreen>
              <IconContainerFullScreen>
                {getAdequateIcon()}
                {temperature}
              </IconContainerFullScreen>
            </IconSection>
          </RowFullScreenIcon>

          {!differencePrice || Math.abs(differencePrice) < Math.abs(minDifferencePrice)
            ? renderPrice()
            : renderDifferencePrice()}
          {nextPrice && nextPriceDate && (
            <MoreInfoPrice>
              {t('moreInfoDescription1')} <BoldText>{nextPriceDate}</BoldText> {t('moreInfoDescription2')}{' '}
              <BoldText>
                <NumberFormat value={nextPrice} suffix={`€/${measureUnit}`} />
              </BoldText>
            </MoreInfoPrice>
          )}
          <MinAndMulti>{`${t('min')}: ${financial(min)} / ${t('multi')}: ${financial(multiple)}`}</MinAndMulti>
          <BtnSection>
            <ProductButton {...product} fromModal />
            {features?.shopping_lists && (
              <Button style={{ width: '320px' }} onClick={handleClickAddList}>
                {t('shoppingList.addToList.btnTitle')}
              </Button>
            )}
          </BtnSection>
          {hasComments ? (
            <CommentsContainer>
              {comments?.map(({ note }) => (
                <Comment key={note}>{note}</Comment>
              ))}
            </CommentsContainer>
          ) : null}
          <Border />
          <RowFullScreen>
            <div>
              <AllergensTitle>Alérgenos del producto</AllergensTitle>
              <Allergens>
                {positiveAllergens?.length > 0 &&
                  positiveAllergens.map(({ name: allergenName }, index) => {
                    if (index === positiveAllergens.length - 1) return allergenName
                    return `${allergenName}, `
                  })}{' '}
                {renderAllergenMessage()}
              </Allergens>
            </div>
            <div>
              <AllergensTitle>Código del producto</AllergensTitle>
              <Allergens>{reference}</Allergens>
            </div>
          </RowFullScreen>
          <RowFullScreen>
            <div>
              <AllergensTitle>Próxima fecha de entrega</AllergensTitle>
              <Allergens> {getNextDeliveryDate()}</Allergens>
            </div>

            <div>
              <AllergensTitle>Código del proveedor</AllergensTitle>
              <Allergens>{providerCode}</Allergens>
            </div>
          </RowFullScreen>
          {availabilityUntil ? (
            <RowFullScreen>
              <div>
                <AllergensTitle>{t('availability')}</AllergensTitle>
                <Allergens> {`${t('availabilityUntil')} ${availabilityUntil}`}</Allergens>
              </div>
            </RowFullScreen>
          ) : null}
        </>
      )}
      {openAddListModal && (
        <ModalAddToList
          productId={product?.id}
          showModal={openAddListModal}
          setShowModal={() => setOpenAddListModal(prevState => !prevState)}
        />
      )}
    </>
  )
}

Product.defaultProps = {
  fixAllScreen: false,
}

Product.propTypes = {
  fixAllScreen: PropTypes.bool,
}
export default Product
