import colors from 'Assets/colors'
import { media } from 'Config/styled'

export const selectStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: colors.white,
    height: 56,
    minWidth: 450,
    paddingLeft: 16,
    paddingRight: 12,
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    borderWidth: 0,

    ...{
      [media('lg')]: {
        minWidth: 'unset',
      },
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? undefined : isSelected ? data.color : isFocused ? colors.gray3 : undefined,
    color: isDisabled ? '#ccc' : isSelected ? colors.black : data.color,
    cursor: isDisabled ? 'not-allowed' : 'default',

    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled ? (isSelected ? data.color : colors.gray3) : undefined,
    },
  }),
  input: styles => ({
    ...styles,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
  }),
  placeholder: styles => ({ ...styles }),
  singleValue: styles => ({ ...styles }),
  multiValue: styles => ({
    ...styles,
    backgroundColor: colors.secondary,
    borderRadius: 8,
    padding: '0 8px',
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: colors.white,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: colors.white,
    ':hover': {
      backgroundColor: data.color,
      color: colors.white,
      cursor: 'pointer',
    },
  }),
  dropdownIndicator: styles => ({
    ...styles,
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: colors.white,
    marginTop: 3,
  }),
}
