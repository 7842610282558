import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import colors from 'Assets/colors'
import { media } from 'Config/styled'

export const useStyles = makeStyles(() => ({
  back: {
    width: '240px',
    height: '56px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
    textTransform: 'none',
    color: colors.black,
    transition: 'all 0.2s',
    border: `1px solid ${colors.black}`,
    backgroundColor: colors.white,
    '&:hover': {
      opacity: 0.8,
      color: colors.black,
      transform: 'translateY(-2px)',
      backgroundColor: colors.se,
      boxShadow: '0 3px 5px rgba(51, 51, 51, 0.2)',
    },
    '&:active': {
      transform: 'translate(-1px)',
      boxShadow: '0 1px 3px rgba(51, 51, 51, 0.4)',
    },
  },
  history: {
    width: '240px',
    height: '56px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
    textTransform: 'none',
    color: colors.white,
    transition: 'all 0.2s',
    border: `1px solid ${colors.secondary}`,
    backgroundColor: colors.secondary,
    '&:hover': {
      opacity: 0.8,
      color: colors.white,
      transform: 'translateY(-2px)',
      backgroundColor: colors.secondary,
      boxShadow: '0 3px 5px rgba(51, 51, 51, 0.2)',
    },
    '&:active': {
      transform: 'translate(-1px)',
      boxShadow: '0 1px 3px rgba(51, 51, 51, 0.4)',
    },
  },
}))

export const Container = styled.div`
  padding: 2.5rem 1.25rem 9rem;
  height: calc(100vh - 64px);
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Content = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 40px 80px;
  width: 680px;
  margin: 40px auto;
  align-items: center;

  ${media('lg')} {
    width: 100%;
  }
`

export const ListContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin-left: 180px;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.black};
  margin-bottom: 16px;
  text-align: center;
`

export const SubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  text-align: center;
  margin-bottom: 24px;
  margin-top: 24px;
`

export const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4.5rem;

  ${media('lg')} {
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }
`
