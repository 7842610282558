import styled from 'styled-components'
import colors from 'Assets/colors'
import { OrderPRCounter } from 'Components/organisms/navBar/styled'
import { media } from 'Config/styled'

export const MulticenterStats = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  column-gap: 36px;
  justify-content: flex-start;
  align-items: center;

  > div,
  > div a {
    display: inline-flex;
    align-items: center;
    color: ${colors.black};
  }

  ${media('lg')} {
    flex-wrap: wrap;
    gap: 1rem;
  }
`

export const ChipCounter = styled(OrderPRCounter)`
  background-color: ${({ color }) => colors[color]};
  line-height: 24px;
  width: unset;
  min-width: 24px;
  padding: 0 2px;
  margin-left: 8px;
`

export const Separator = styled.div`
  width: 100vw;
  height: 1px;
  background-color: ${colors.black};
  margin-left: -24px;
`
