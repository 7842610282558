export const selectCenterId = state => state.auth.selectedCenter.id ?? state.multicenter.selectedCenter?.id

export const selectCategories = state => state.ticket.categories

export const selectTicketsList = state => state.ticket.ticketList

export const selectIsLoadingTikets = state => state.ticket.isLoading

export const selectTicket = state => state.ticket.ticket

export const selectFilterStatus = state => state.ticket.filterStatus

export const selectTicketsListCount = state => state.ticket.ticketListCount

export const selectIsLoadingTiketComment = state => state.ticket.isLoadingTicketComment

export const selectTicketsPendingListCount = state => state.ticket.ticketPendingListCount

export const selectTicketsPendingList = state => state.ticket.ticketPendingList
