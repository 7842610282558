import React, { useRef } from 'react'

import { createPortal } from 'react-dom'

import { Container, ModalInsideContainer, ModalInside, CloseButtonContainer, CloseButton } from './styled'

const modalRoot = document.getElementById('modal')

const Modal = ({
  onClose,
  isOpen,
  children,
  setIsOpen,
  maxWidth,
  width,
  height,
  respHeight,
  padding,
  displayContainer,
  justifyContainer,
  margin,
  textAlign,
  showClose,
  className,
  borderRadius,
  scrollbar,
  closeBg,
  maxHeight,
  overflowY,
  minHeight,
  displayModal,
  overflowInside,
}) => {
  const modalContent = useRef()

  const Close = () => {
    setIsOpen(false)
    if (onClose) onClose()
  }

  const onOutsideClick = () => {
    Close()
  }

  return (
    isOpen &&
    createPortal(
      <Container
        className={className}
        overflowY={overflowY}
        displayContainer={displayContainer}
        justifyContainer={justifyContainer}
        scrollbar={scrollbar}
      >
        <ModalInsideContainer onClick={onOutsideClick} />
        <ModalInside
          textAlign={textAlign}
          ref={modalContent}
          width={width}
          maxWidth={maxWidth}
          height={height}
          respHeight={respHeight}
          maxHeight={maxHeight}
          padding={padding}
          margin={margin}
          borderRadius={borderRadius}
          scrollbar={scrollbar}
          minHeight={minHeight}
          displayModal={displayModal}
          overflowInside={overflowInside}
        >
          {showClose && (
            <CloseButtonContainer closeBg={closeBg}>
              <CloseButton onClick={Close}>+</CloseButton>
            </CloseButtonContainer>
          )}
          {children}
        </ModalInside>
      </Container>,
      modalRoot
    )
  )
}

Modal.defaultProps = {
  children: <></>,
  onClose: () => {},
  showClose: true,
  scrollbar: 'auto',
  setIsOpen: () => undefined,
}

export default Modal
