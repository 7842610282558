import React from 'react'

import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { Modal } from 'Components/atoms'

import { Container, Content, Title, SubTitle, useStyles } from './styled'

const NotServedInfo = ({ showModal, setShowModal, width, height, onConfirm }) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Modal isOpen={showModal} setIsOpen={setShowModal} width={width} height={height}>
      <Container>
        <Content>
          <Title>{t('errorReception0')}</Title>
          <SubTitle>{t('errorReception0Msg')}</SubTitle>

          <Button onClick={onConfirm} className={classes.button}>
            {t('errorReception0Open')}
          </Button>
        </Content>
      </Container>
    </Modal>
  )
}

export default NotServedInfo

NotServedInfo.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onConfirm: PropTypes.func,

  width: PropTypes.string,
  height: PropTypes.string,
}

NotServedInfo.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  onConfirm: () => {},

  width: '',
  height: '',
}
