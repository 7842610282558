import { media } from 'Config/styled'
import styled from 'styled-components'

export const NoResultsContainer = styled.div`
  padding: 40px;
  margin: 0 auto;

  ${media('lg')} {
    grid-column: span 2;
    padding: 1rem;
  }
`

export const NoResultsBox = styled.div`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  max-width: 660px;
  margin: 0 auto;

  ${media('lg')} {
    width: 100%;
    max-width: unset;
    padding: 1rem;
  }
`
