import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { setCart, setRequestOrder } from 'Redux/approver/actions'
import { ReactComponent as ExclamationCircle } from 'Assets/Icons/ExclamationCircle.svg'
import { ReactComponent as CloseX } from 'Assets/Icons/CloseX.svg'
import { ReactComponent as CleanUp } from 'Assets/Icons/CleanUp.svg'
import { ReactComponent as CarroVacio } from 'Assets/Icons/CarroVacio.svg'
import { ReactComponent as CartError } from 'Assets/Icons/CartError.svg'
import { CartSearchBar, ModalConfirmation, ModalLoading, ProductHorizontalCardApprover } from 'Components/molecules'
import { NumberFormat } from 'Components/atoms'
import { REQUEST_ORDER_STATE } from 'Config/constants'
import { calculateProductAmount, hasSomethingInCart } from 'Components/utils/cartCalculator'
import useProductButton from 'Components/utils/useProductButton'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import routes from 'Config/routes'
import useApproverCart from 'hooks/approver/useApproverCart'

import {
  Root,
  Row,
  Cart,
  HorizontalProvider,
  FinishButton,
  ButtonLiteral,
  TotalItems,
  Container,
  NotAvailableTitle,
  SVGRow,
  ErrorTitle,
  ErrorMessage,
  HorizontalClose,
  HorizontalMainRow,
  SearchRow,
  HorizontalRedSquare,
  HorizontalButtonNoProducts,
  ExpandedBudget,
  HorizontalProductContainer,
  HorizontalProviderContainer,
  ProviderRow,
  HorizontalTitleSection,
  HorizontalTitleProduct,
  HorizontalTitleTotal,
  HorizontalTitleValue,
  FinishButtonSection,
  Remainings,
  RemainingHorizontal,
  RemainingsSectionHorizontal,
  BudgetRowHorizontal,
  BudgetAmountPositiveHorizontal,
  BudgetAmountNegativeHorizontal,
  BudgetKindHorizontal,
  BudgetKindHorizontalNotRawMaterial,
  BudgetAmountPositiveHorizontalNotRaw,
  BudgetAmountNegativeHorizontalNotRaw,
  EmptyMargin,
  HorizontalText,
  HorizontalProviderNotAvailableContainer,
  NotAvailableBudgetRow,
  HorizontalMessage,
  HorizontalTitleAmount,
  RejectCart,
  PendingActions,
  EmptyCartContainer,
  AddProducts,
} from './styled'

const ApproverCart = () => {
  const { t } = useTranslation()
  const { onNext, onPreviuos, setRef } = useProductButton()
  const {
    productsToShow,
    showConfirmRejectModal,
    showModalConfirmationProduct,
    onConfirmProduct,
    showModalConfirmationProvider,
    isFetchingCart,
    isUpdatingCart,
    notAvailableToShow,
    totalByProvider,
    emptyCart,
    totalFood,
    totalNoFood,
    fullTotal,
    hasRequestOrderPendingState,
    onSeek,
    scrollToNoOkProducts,
    onAcceptProviderErase,
    onCancelProviderErase,
    onCancelProductDelete,
    onClickAddProducts,
    onClickReject,
    doRejectCart,
    deleteProducts,
    setOnConfirmProduct,
    setShowModalConfirmationProduct,
    hasAtLeastOneProduct,
    setShowConfirmRejectModal,
    setShowModalConfirmationProvider,
  } = useApproverCart()

  const renderProductToShow = ({
    id,
    measureUnit,
    min,
    multiple,
    name,
    price,
    pricePerKg,
    providerName,
    state,
    raw_material: productFood,
    provider,
    reference,
    previous_price: previousPrice,
    availability,
  }) => (
    <ProductHorizontalCardApprover
      key={id}
      id={id}
      measureUnit={measureUnit}
      min={min}
      multiple={multiple}
      name={name}
      price={price}
      pricePerKg={pricePerKg}
      providerName={providerName}
      raw_material={productFood}
      state={state}
      provider={provider}
      reference={reference}
      setOnConfirmProduct={setOnConfirmProduct}
      setShowModalConfirmationProduct={setShowModalConfirmationProduct}
      previousPrice={previousPrice}
      availability={availability}
      refButton={element => setRef(element, id)}
      onPrevious={onPreviuos}
      onNext={onNext}
      disabled={!hasRequestOrderPendingState}
    />
  )

  return (
    <Root>
      <>
        <Header />
        {emptyCart && !isFetchingCart && <EmptyCart />}
        {isFetchingCart && <Spinner />}
        <>
          {!emptyCart && <Search onSeek={onSeek} />}
          <HorizontalProductContainer name='containerElement' id='containerElement'>
            <>
              {!isEmpty(notAvailableToShow) && <NotAvailableWarning onClick={scrollToNoOkProducts} />}
              {Object.keys(productsToShow).map(key => {
                if (!hasAtLeastOneProduct(key)) return null

                const price = totalByProvider.find(element => element.index === key).total
                const amount = productsToShow[key].length
                const numberOfProductsLabel = amount === 1 ? t('numberOfProduct') : t('numberOfProducts')

                return (
                  <HorizontalProviderContainer key={key}>
                    <ProviderRow>
                      <HorizontalProvider>{key}</HorizontalProvider>
                      <Row className='hoverable' justifyContent='flex-end'>
                        {hasRequestOrderPendingState && <EmptyCartCleanUp onClick={() => deleteProducts(key)} />}
                        <HorizontalText>
                          <NumberFormat value={price} />
                        </HorizontalText>
                        <HorizontalText>{`${amount} ${numberOfProductsLabel}`}</HorizontalText>
                      </Row>
                    </ProviderRow>
                    <HorizontalTitleSection>
                      <HorizontalTitleProduct>{t('product')}</HorizontalTitleProduct>
                      <HorizontalTitleValue>{t('price')}</HorizontalTitleValue>
                      <HorizontalTitleTotal>{t('totalLine')}</HorizontalTitleTotal>
                      <HorizontalTitleAmount>{t('approvedAmount')}</HorizontalTitleAmount>
                    </HorizontalTitleSection>
                    {productsToShow[key].map(renderProductToShow)}
                  </HorizontalProviderContainer>
                )
              })}
              {!isEmpty(notAvailableToShow) && <NotAvailableToShow notAvailableToShow={notAvailableToShow} />}
            </>

            <ExpandedBudget>
              <>
                <RemainingsSectionHorizontal>
                  <RemainingHorizontal>{t('remainingBudget')}</RemainingHorizontal>
                  <Remainings>
                    <BudgetRowHorizontal>
                      <BudgetKindHorizontal>{t('rawMaterial')}</BudgetKindHorizontal>
                      <Food value={totalFood} />
                    </BudgetRowHorizontal>
                    <BudgetRowHorizontal>
                      <BudgetKindHorizontalNotRawMaterial>{t('notRawMaterial')}</BudgetKindHorizontalNotRawMaterial>
                      <NoFood value={totalNoFood} />
                    </BudgetRowHorizontal>
                  </Remainings>
                </RemainingsSectionHorizontal>
              </>
              {!isFetchingCart && (
                <FinishButtonSection>
                  {hasRequestOrderPendingState && (
                    <PendingActions>
                      <RejectCart aria-label={t('approverTutorial.stepSeven.target')} onClick={onClickReject}>
                        {t('rejectCart')}
                      </RejectCart>
                      {!emptyCart && (
                        <AddProducts aria-label={t('approverTutorial.stepEight.target')} onClick={onClickAddProducts}>
                          {t('addProducts')}
                        </AddProducts>
                      )}
                    </PendingActions>
                  )}
                  {!emptyCart ? <SubmitButton fullTotal={fullTotal} /> : <div style={{ width: '25rem' }} />}
                </FinishButtonSection>
              )}
            </ExpandedBudget>
          </HorizontalProductContainer>
        </>
        <ModalLoading showModal={isUpdatingCart} />
        <ModalConfirmation
          onCancel={() => setShowConfirmRejectModal(false)}
          onAccept={doRejectCart}
          showModal={showConfirmRejectModal}
          setShowModal={setShowConfirmRejectModal}
          title={t('rejectCartTitle')}
          action={t('rejectCartAction')}
          cancel={t('rejectCartCancel')}
          color='secondary'
          showClose={false}
          icon={<CartError />}
        />
        <ModalConfirmation
          onCancel={onCancelProductDelete}
          onAccept={onConfirmProduct}
          showModal={showModalConfirmationProduct}
          setShowModal={setShowModalConfirmationProduct}
          message={t('deleteProductMessage')}
          title={t('deleteProductTitle')}
          action={t('deleteProductAction')}
          color='red'
          width='680px'
          height='256px'
        />
        <ModalConfirmation
          onCancel={onCancelProviderErase}
          onAccept={onAcceptProviderErase}
          showModal={showModalConfirmationProvider}
          setShowModal={setShowModalConfirmationProvider}
          message={t('emptyProviderMessage')}
          title={t('emptyProvider')}
          action={t('actionEmptyProvider')}
          color='red'
          width='680px'
          height='256px'
        />
      </>
    </Root>
  )
}

const EmptyCartCleanUp = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <EmptyCartContainer onClick={onClick}>
      <CleanUp style={{ margin: '0.5rem' }} />
      <EmptyMargin>{t('emptyCart')}</EmptyMargin>
    </EmptyCartContainer>
  )
}

EmptyCartCleanUp.defaultProps = {
  onClick: () => null,
}

EmptyCartCleanUp.propTypes = {
  onClick: PropTypes.func,
}

const NotAvailableWarning = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <HorizontalRedSquare>
        <Row justifyContent='space-between' alignItems='center'>
          {t('someProductsNotAvailable')}
          <HorizontalButtonNoProducts onClick={onClick}>
            <ExclamationCircle style={{ marginRight: '0.5rem' }} />
            {t('seeProducts')}
          </HorizontalButtonNoProducts>
        </Row>
      </HorizontalRedSquare>
    </Container>
  )
}

NotAvailableWarning.defaultProps = {
  onClick: () => null,
}

NotAvailableWarning.propTypes = {
  onClick: PropTypes.func,
}

const NotAvailableToShow = ({ notAvailableToShow }) => {
  const { t } = useTranslation()

  const renderNotAvailableToShow = ({
    id,
    measureUnit,
    min,
    multiple,
    name,
    price,
    pricePerKg,
    providerName,
    state,
    raw_material: productFood,
    provider,
    reference,
    previous_price: previousPrice,
    availability,
  }) => (
    <ProductHorizontalCardApprover
      key={id}
      id={id}
      measureUnit={measureUnit}
      min={min}
      multiple={multiple}
      name={name}
      price={price}
      pricePerKg={pricePerKg}
      providerName={providerName}
      raw_material={productFood}
      state={state}
      notAvailable
      provider={provider}
      reference={reference}
      previousPrice={previousPrice}
      availability={availability}
    />
  )

  return (
    <HorizontalProviderNotAvailableContainer name='myScrollToElement'>
      <NotAvailableBudgetRow marginBottom='0.5rem'>
        <NotAvailableTitle>{t('notAvailableProducts')}</NotAvailableTitle>
        <HorizontalMessage>{t('notAvailableProductsMessage')}</HorizontalMessage>
      </NotAvailableBudgetRow>
      <HorizontalTitleSection>
        <HorizontalTitleProduct>{t('product')}</HorizontalTitleProduct>
        <HorizontalTitleValue>{t('total')}</HorizontalTitleValue>
        <HorizontalTitleTotal>{t('totalLine')}</HorizontalTitleTotal>
        <HorizontalTitleAmount>{t('approvedAmount')}</HorizontalTitleAmount>
      </HorizontalTitleSection>
      {notAvailableToShow.map(renderNotAvailableToShow)}
    </HorizontalProviderNotAvailableContainer>
  )
}

NotAvailableToShow.defaultProps = {
  notAvailableToShow: [],
}

NotAvailableToShow.propTypes = {
  notAvailableToShow: PropTypes.array,
}

const Food = ({ value }) =>
  value > 0 ? (
    <BudgetAmountPositiveHorizontal>
      <NumberFormat value={value} />
    </BudgetAmountPositiveHorizontal>
  ) : (
    <BudgetAmountNegativeHorizontal>
      <NumberFormat value={value} />
    </BudgetAmountNegativeHorizontal>
  )

Food.defaultProps = {
  value: 0,
}

Food.propTypes = {
  value: PropTypes.number,
}

const NoFood = ({ value }) =>
  value > 0 ? (
    <BudgetAmountPositiveHorizontalNotRaw>
      <NumberFormat value={value} />
    </BudgetAmountPositiveHorizontalNotRaw>
  ) : (
    <BudgetAmountNegativeHorizontalNotRaw>
      <NumberFormat value={value} />
    </BudgetAmountNegativeHorizontalNotRaw>
  )

NoFood.defaultProps = {
  value: 0,
}

NoFood.propTypes = {
  value: PropTypes.number,
}

const Header = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onCloseCart = () => {
    dispatch(setRequestOrder(null))
    dispatch(setCart(null))
  }

  return (
    <HorizontalMainRow>
      <Row>
        <CloseX style={{ marginRight: '1rem' }} className='hoverable' onClick={onCloseCart} />
        <HorizontalClose>{t('close')}</HorizontalClose>
      </Row>
    </HorizontalMainRow>
  )
}

const EmptyCart = () => {
  const { t } = useTranslation()
  return (
    <div style={{ padding: '1rem' }}>
      <SVGRow>
        <CarroVacio />
      </SVGRow>
      <ErrorTitle>{t('emptyCartTitle')}</ErrorTitle>
      <ErrorMessage>{t('emptyCartSubtitle')}</ErrorMessage>
    </div>
  )
}

const Search = ({ onSeek }) => {
  const { t } = useTranslation()

  return (
    <SearchRow>
      <Cart style={{ fontSize: 31 }}>{t('cart')}</Cart>
      <CartSearchBar expanded onSeek={onSeek} />
    </SearchRow>
  )
}

Search.defaultProps = {
  onSeek: () => null,
}

Search.propTypes = {
  onSeek: PropTypes.func,
}

const Spinner = () => <CircularProgress style={{ position: 'absolute', left: '50%', top: '50%' }} />

const SubmitButton = ({ fullTotal }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const cart = useSelector(state => state.approver.cart)
  const requestOrder = useSelector(state => state.approver.requestOrder)

  const hasRequestOrderPendingState = requestOrder?.state === REQUEST_ORDER_STATE.PENDING
  const productQuantity = hasSomethingInCart(cart) ? calculateProductAmount(cart) : ''

  const doConfirmCart = () => history.push(routes.approvedCheckout)

  return (
    <FinishButton
      aria-label={t('approverTutorial.stepEleven.target')}
      onClick={hasRequestOrderPendingState ? doConfirmCart : null}
      disabled={!hasRequestOrderPendingState}
    >
      <ButtonLiteral>{hasRequestOrderPendingState ? t('aproveCart') : t('cart')}</ButtonLiteral>
      {hasSomethingInCart(cart) ? <NumberFormat value={fullTotal} /> : '0€'}
      {hasSomethingInCart(cart) ? <TotalItems>{productQuantity}</TotalItems> : null}
    </FinishButton>
  )
}

SubmitButton.defaultProps = {
  fullTotal: 0,
}

SubmitButton.propTypes = {
  fullTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ApproverCart
