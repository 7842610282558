import React from 'react'

import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import get from 'lodash/get'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ArrowDropdown from 'Assets/Icons/ArrowDropdown'
import { ReactComponent as CompassCenterIcon } from 'Assets/Icons/CompassCenter.svg'
import colors from 'Assets/colors'
import { ReactComponent as CalendarIcon } from 'Assets/Icons/Calendario.svg'
import { REQUEST_ORDER_STATE } from 'Config/constants'
import { setRequestOrder } from 'Redux/approver/actions'
import NumberFormat from 'Components/atoms/numberFormat'
import { Calendar } from 'Components/atoms'
import { NoResults } from 'Components/molecules'
import { useClickOutside } from 'Components/utils/customHooks'
import Box from '@material-ui/core/Box'

import NotCenterView from './not-center-view'
import {
  ShowProductsContainer,
  ShowLessCopy,
  ShowMoreCopy,
  Container,
  HeaderRow,
  Text,
  Row,
  SpaceBetweenRow,
  ColorRow,
  ItemContainer,
  RequestNumber,
  SpaceBetweenDetailRow,
  ButtonSection,
  NumberP,
  StateRow,
  SelectedDeliveryDate,
  DetailRow,
  OrderProvider,
  InfoRow,
  Total,
  Value,
  ButtonContainer,
  ActionButton,
  ButtonLiteral,
  DateSelectContainer,
  DateSelect,
  DateSelectTitle,
  CalendarContainer,
  BorderBox,
} from './styled'

const SummaryList = ({
  summaryList,
  onSelectCenter,
  summaryCenter,
  summarySpend,
  onChangeWeek,
  weekFirstDate,
  isFetching,
}) => {
  const { t } = useTranslation()
  const ContainerRef = React.useRef()

  const [showOrders, setShowOrders] = React.useState([])
  const [openCalendar, setOpenCalendar] = React.useState(false)

  const handleShow = React.useCallback(row => {
    setShowOrders(prev => prev.map((el, index) => (index === row ? !el : el)))
  }, [])

  const handleClickOpenCalendar = React.useCallback(() => setOpenCalendar(newValue => !newValue), [])

  const handleChangeWeek = React.useCallback(
    value => {
      onChangeWeek(value)
    },
    [onChangeWeek]
  )

  useClickOutside(ContainerRef, () => setOpenCalendar(false))

  const isDateDisable = ({ date, view }) => moment(date).weekday() !== 0 && view === 'month'

  React.useEffect(() => {
    setShowOrders(summaryList.map(element => element.show))
  }, [summaryList])

  React.useEffect(() => {
    setOpenCalendar(false)
  }, [weekFirstDate])

  return (
    <Container>
      <InfoRow>
        <BorderBox>
          <Button onClick={onSelectCenter} startIcon={<CompassCenterIcon />} color='inherit'>
            {`${summaryCenter?.name || t('selectYourCenter')} - ${summaryCenter?.id || ''}`}
          </Button>
        </BorderBox>

        <BorderBox>
          <DateSelectContainer ref={ContainerRef}>
            <CalendarIcon />
            <DateSelect>
              <DateSelectTitle onClick={handleClickOpenCalendar}>
                {`${t('summary.week')}: ${moment(weekFirstDate).format('l')} - ${moment(weekFirstDate)
                  .add(6, 'd')
                  .format('l')}`}
              </DateSelectTitle>
            </DateSelect>
            {openCalendar && (
              <CalendarContainer>
                <Calendar
                  value={weekFirstDate}
                  onChange={handleChangeWeek}
                  selectRange={false}
                  tileDisabled={isDateDisable}
                />
              </CalendarContainer>
            )}
          </DateSelectContainer>
        </BorderBox>

        <Total>{`${t('summary.summaryTotal')}: `}</Total>
        <Value>
          <NumberFormat value={summarySpend || '0.0'} suffix='€' />
        </Value>
      </InfoRow>
      {!summaryCenter?.id && <NotCenterView />}
      {summaryCenter?.id && !isFetching && summaryList?.length > 0 ? (
        summaryList.map((element, index) => (
          <OrderProvider key={element.title}>
            <Header element={element} onShow={handleShow} showOrder={showOrders[index] === true} index={index} />
            {showOrders[index] === true &&
              element.data.map((detail, detailIndex) => <Item item={detail} key={detail.id} index={detailIndex} />)}
          </OrderProvider>
        ))
      ) : (
        <>
          {summaryCenter?.id && isFetching && <ListLoader />}
          {summaryCenter?.id && !isFetching && <NoResults title={t('summary.noResults')} />}
        </>
      )}
    </Container>
  )
}

SummaryList.propTypes = {
  summaryList: PropTypes.array,
  onSelectCenter: PropTypes.func,
  summaryCenter: PropTypes.object,
  summarySpend: PropTypes.number,
  onChangeWeek: PropTypes.func,
  weekFirstDate: PropTypes.object,
  isFetching: PropTypes.bool,
}

SummaryList.defaultProps = {
  summaryList: [],
  onSelectCenter: () => {},
  summaryCenter: {},
  summarySpend: 0,
  onChangeWeek: () => {},
  weekFirstDate: {},
  isFetching: false,
}

const Header = ({ element, onShow, showOrder, index }) => {
  const { t } = useTranslation()

  const onChange = React.useCallback(() => {
    onShow(index)
  }, [index, onShow])

  return (
    <HeaderRow>
      <SpaceBetweenRow>
        <Row>
          <CompassCenterIcon />
          <Text>{element.title}</Text>
        </Row>
        <ColorRow>
          <Text>{t('totalByWeek')}</Text>
          <NumberFormat value={element.total || '0.0'} suffix='€' />
        </ColorRow>
      </SpaceBetweenRow>

      <ShowProductsContainer onClick={onChange}>
        <ArrowDropdown
          fill={colors.black}
          style={{ margin: '0 0.5rem', transform: showOrder ? 'rotate(180deg)' : 'none' }}
        />
        {showOrder ? <ShowLessCopy>{t('hideOrder')}</ShowLessCopy> : <ShowMoreCopy>{t('seeOrder')}</ShowMoreCopy>}
      </ShowProductsContainer>
    </HeaderRow>
  )
}

Header.propTypes = {
  element: PropTypes.object,
  onShow: PropTypes.func,
  showOrder: PropTypes.bool,
  index: PropTypes.number,
}

Header.defaultProps = {
  element: {},
  onShow: () => {},
  showOrder: false,
  index: 0,
}

const Item = ({ item }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const deliveryDate = moment(get(item, 'deliveryDate')).format('DD/MM/YYYY')
  const createdDate = moment(get(item, 'created')).format('DD/MM/YYYY')

  const handleClickSeeCart = () => dispatch(setRequestOrder(item))

  return (
    <ItemContainer>
      <RequestNumber>{`${t('summary.request')} ${item.id}`}</RequestNumber>

      <SpaceBetweenDetailRow>
        <Box
          display='flex'
          sx={{
            flexDirection: { xs: 'column', lg: 'row' },
            alignItems: { xs: 'flex-start', lg: 'center' },
            width: { xs: '100%', lg: 'unset' },
            gridRowGap: { xs: '0.2rem', lg: 'unset' },
          }}
        >
          <DetailRow> {`${t('summary.createdDate')} ${createdDate}`} </DetailRow>
          <StateRow>
            {' '}
            {item.state === REQUEST_ORDER_STATE.PENDING
              ? `${t('summary.state')} ${t('requestOrderStatus.Pte Aprobacion')}`
              : ''}{' '}
          </StateRow>
          <SelectedDeliveryDate>{`${t('summary.deliveryDate')} ${deliveryDate}`} </SelectedDeliveryDate>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          sx={{
            width: { xs: '100%', lg: 'unset' },
          }}
        >
          <NumberP>
            {`${t('summary.total')}  `}

            <NumberFormat value={item.totalPrice || '0.0'} suffix='€' />
          </NumberP>
        </Box>
      </SpaceBetweenDetailRow>

      <ButtonSection>
        <>
          {item.state === REQUEST_ORDER_STATE.PENDING && (
            <ButtonContainer>
              <ActionButton onClick={handleClickSeeCart} aria-label={t('approverTutorial.stepFive.target')}>
                <ButtonLiteral>{t('seeCart2')}</ButtonLiteral>
              </ActionButton>
            </ButtonContainer>
          )}
        </>
      </ButtonSection>
    </ItemContainer>
  )
}

Item.propTypes = {
  item: PropTypes.object,
}

Item.defaultProps = {
  item: {},
}

export const ListLoader = () => (
  <>
    <RequestsLoader />
    <RequestsLoader />
  </>
)

const RequestsLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width='100%'
      height={100}
      viewBox='0 0 100% 100'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='0' y='10' rx='10' ry='10' width='100%' height='70' />
    </ContentLoader>
  )
}

export default SummaryList
