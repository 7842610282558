import styled from 'styled-components'
import { Calendar } from 'Components/atoms'
import CircularProgress from '@material-ui/core/CircularProgress'
import colors from 'Assets/colors'
import { media } from 'Config/styled'

export const Container = styled.div`
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  padding: 2.5rem 1.25rem 9rem;
  height: calc(100vh - 168px);
  overflow: auto;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 1rem;
`

export const SubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
`

export const RequestedContainer = styled.div`
  padding: 1.5rem 0;
`

export const SingleRequestedContainer = styled.div`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
`

export const RequestedHeader = styled.div`
  background: ${colors.black};
  color: ${colors.white};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
`

export const RequestedHeaderInfo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`

export const RequestedProviderInfoContainer = styled.div`
  background: ${colors.gray98b};
  padding: 16px;
  display: flex;
  justify-content: space-between;

  ${media('lg')} {
    flex-direction: column;
  }
`

export const RequestedProviderInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  > * {
    margin-right: 8px;
  }

  ${media('lg')} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const RequestedProviderName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
`

export const RequestedProviderDeliveryDate = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.black};
  background: ${colors.cart};
  border-radius: 8px;
  padding: 0 8px;
`

export const RequestedProviderOrderNumber = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.gray40l};
`

export const ProviderDetail = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
`

export const TotalPrice = styled.span`
  margin-right: 24px;
`

export const TotalProductLines = styled.span``

export const ProductsContainer = styled.div`
  border-top: 1px solid rgba(51, 51, 51, 0.24);
  padding: 0 16px 16px;
  background: ${colors.gray98b};

  ${media('lg')} {
    padding: 1rem;
  }
`

export const ProductHeader = styled.div`
  display: grid;
  grid-template-columns: 36% 16% 16% 16% 16%;
  padding: 16px;

  ${media('lg')} {
    display: none;
  }
`

export const StatusNameComboHeader = styled.div`
  display: grid;
  grid-template-columns: 18% 82%;
`

export const ProductHeaderItem = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  padding-left: 16px;
  &:first-child {
    padding-left: 0;
  }
`

export const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: 36% 16% 16% 16% 16%;
  background: ${colors.white};
  border: 1px solid rgba(51, 51, 51, 0.24);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 16px;
  margin-bottom: 16px;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }

  ${media('lg')} {
    grid-template-columns: 1fr;
    row-gap: 0.8rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`

export const StatusNameCombo = styled.div`
  display: grid;
  grid-template-columns: 18% 82%;
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  height: 100%;
  align-items: center;

  ${media('lg')} {
    border-right: none;
    grid-template-columns: 1fr;
  }
`

export const SingleFieldContainer = styled.div`
  padding-left: 16px;

  ${media('lg')} {
    padding-left: 0;
  }
`

export const ProductName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  padding-right: 24px;
`

export const FixedInputField = styled.div`
  background: ${colors.grayInput};
  border-radius: 8px;
  height: 40px;
  padding: 8px 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
`

export const ActionContainer = styled.div`
  background: ${colors.gray98b};
  border-top: 1px solid rgba(51, 51, 51, 0.24);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1280px) {
    grid-template-columns: 33% 29% 38%;
  }

  ${media('lg')} {
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
`

export const DateSelectorContainer = styled.div`
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  display: flex;
  justify-content: space-between;
  position: relative;
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 5px 24px 20px;

  ${media('lg')} {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const DateLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
  margin-bottom: 8px;
`

export const DatesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const DateSelected = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.gray40l};
`

export const WrongDate = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.red};
  margin-right: 8px;
`

export const WarningDateContainer = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: ${colors.red};
  margin-bottom: -6px;
`

export const ButtonContainer = styled.div`
  padding: 24px 24px 24px 5px;

  ${media('lg')} {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const SelectDateButton = styled.button`
  background: ${colors.secondary};
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 1rem 1.125rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const TextInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 20px;

  ${media('lg')} {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const FinishButtonContainer = styled.div`
  padding: 24px 20px;

  ${media('lg')} {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const FinishButton = styled.div`
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  width: 100%;
  background-color: ${props => (props.disabled ? colors.gray40l : colors.cart)};
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.disabled ? colors.white : colors.black)};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const TotalsContainer = styled.div`
  display: flex;
`

export const TotalsTotalPrice = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.disabled ? colors.white : colors.black)};

  ${media('lg')} {
    white-space: nowrap;
  }
`

export const TotalsTotalItems = styled.div`
  background: #ffffff;
  border-radius: 4px;
  padding: 0px 8px;
  margin-left: 1rem;
  color: ${colors.black};
`

export const ButtonLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-right: auto;
`

export const StyledCalendar = styled(Calendar)`
  position: absolute;
  bottom: 106px;
  right: 0;
  background: ${colors.white};
`

export const CircularProgressStyled = styled(CircularProgress)`
  margin-right: 10px;
  &.MuiCircularProgress-colorPrimary {
    color: ${colors.white};
  }
`

export const LabelMobile = styled.div`
  display: none;

  ${media('lg')} {
    display: block;
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
  }
`
