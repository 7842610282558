import { media } from 'Config/styled'
import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: calc(100vh - 16px); // 16px of body margin
  overflow: hidden;

  svg {
    margin: 1em 0;
  }
`

export const IEContainer = styled.div`
  display: block;
  min-width: 300px;
  width: 95%;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 2rem 1rem;
  margin: 1rem;

  p {
    margin: 1rem;
  }

  @media (min-width: 500px) {
    width: 495px;
    padding: 2rem;

    p {
      margin: 3rem;
    }

    ${media('lg')} {
      width: 100%;
    }
  }
`
