import colors from 'Assets/colors'

export const FRONT_URL = process.env.REACT_APP_FRONT_URL
export const MSAL_CLIENT_ID = process.env.REACT_APP_MSAL_CLIENT_ID
export const MSAL_AUTH = process.env.REACT_APP_MSAL_AUTH

export const GOOGLE_GTM_ID = process.env.REACT_APP_GOOGLE_GTM_ID

export const categoryTypes = {
  keymap3: 'keymap3',
  subCategory: 'subcategory',
  category: 'category',
  provider: 'provider',
  product: 'product',
}

export const TEMPERATURES = {
  refrigerated: 'Refrigerado',
  frozen: 'Congelado',
  room: 'Ambiente',
}

export const TICKET_STATUS = {
  new: 'new',
  open: 'open',
  hold: 'hold',
  solved: 'solved',
  closed: 'closed',
  pending: 'pending',
}

export const TICKET_STATUS_COLOR = {
  new: colors.black,
  open: colors.black,
  pending: colors.red,
  solved: colors.secondary,
  closed: colors.secondary,
}

export const LIMIT_REQUEST_CENTERS = 50
export const LIMIT_REQUEST_PROVIDERS = 50

export const debounceAmount = 600

export const DELIVERY_DATE_MIN_DAYS = 1

export const productState = {
  ok: 'ok',
  noAvailable: 'no_available',
  changePrice: 'change_price',
}

export const MEASURE_UNIT = {
  KG: 'KG',
}

export const DELIVERY_TYPE = {
  NORMAL: 'ordinary',
  SPECIAL: 'exceptional',
}

export const AVAILABILITY = {
  NO: 'no',
  YES: 'yes',
}

export const ROLES = {
  ROLE_USER: 'User',
  ROLE_STEF: 'Stef',
}

export const ROLES_KEYS = {
  ROLE_USER: 'ROLE_USER',
  ROLE_STEF: 'ROLE_STEF',
  ROLE_SALES: 'ROLE_VENTA',
  ROLE_PURCHASES: 'ROLE_COMPRA',
  ROLE_APPROVER: 'ROLE_APPROVER',
}

export const ORDER_STATUS = {
  PR: 'PR',
  RC: 'RC',
  PE: 'PE',
  CN: 'CN',
  OT: 'OT',
}

export const ORDER_QUALITY_STATUS = {
  SC: 'SC',
  CC: 'CC',
}

export const FILTER_ORDER = {
  ALPHABETICALLY: 'alpha',
  DESCENDENT: 'descendent',
  DELIVERY: 'delivery',
}

export const RECEPTION_STATUS = {
  ST: 'ST',
  DF: 'DF',
  OK: 'OK',
  EX: 'EX',
  FT: 'FT',
}

export const NonConformityQuestionType = {
  IMAGE: 'image',
  TEXTAREA: 'textarea',
}

export const NonConformityCategory = {
  REQUIRED: 'req',
  NO_REQUIRED: 'no_req',
  OPTIONAL: 'opt',
}

export const NonConformityRedelivery = [
  { id: 'si', name: 'yes' },
  { id: 'no', name: 'no' },
]

export const AVAILABILITY_STATES = {
  NOT_STOCK: 'A',
  AVAILABLE: 'D',
  NEW: 'N',
  NEXT: 'DP',
  LAST_UNITS: 'UU',
  UNAVAILABLE: 'B',
}

export const HAS_CALENDAR = {
  YES: 'yes',
  NO: 'no',
}

export const NUM_DAYS_AVAILABLE_PROVIDER = 2

export const DISABLE_WEEKS = false

export const COMPRESSOR_OPTIONS = {
  quality: 0.6,
  maxWidth: 2000,
  maxHeight: 2000,
}

export const KEY_CODES = {
  UP_ARROW: 38,
  DOWN_ARROW: 40,
  TAB: 9,
  ENTER: 13,
  ESC: 27,
}

export const NOTE_MAX_LENGHT = 30

export const CART_STATUS = {
  PENDING: 'pending',
  CANCELLED: 'cancelled',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  CLOSE_EXPIRED: 'closeExpired',
}

export const REQUEST_ORDER_STATE = {
  PENDING: 'Pte Aprobacion',
  CANCELLED: 'Cancelada',
  APPROVED: 'Aprobada',
  REJECTED: 'Rechazada',
}

export const APPROVAL_STATE = {
  NO_CHANGES: 'NO_CHANGES',
  MODIFIED: 'MODIFIED',
  DELETED: 'DELETED',
  ADDED: 'ADDED',
}

export const PROVIDER_ORDERS_MODIFICATION_TYPE = {
  MODIFIED: 'MD',
  NO_MODIFIED: 'NMD',
  AUTOMATICALLY_APPROVED: 'AUT',
}

export const MAX_FILE_SIZE_BYTES = 10000000

export const ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=com.compass_app'

export const TUTORIAL_MODE = 'demo'

export const TUTORIAL_NAMES = {
  approverRoleV2: 'Rol supervisor V2',
  habitual: 'Habituales',
  shoppingList: 'Listas de la compra',
  requestV2: 'Solicitudes cocinero V2',
}
export const TUTORIAL_STATUS = {
  pending: '0',
  done: '1',
}

export const TUTORIAL_STATE = {
  tutorialInfo: [],
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: '',
  toursFinished: [],
  restart: false,
}
export const EXCEL_BLOB_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const TUTORIAL_REQUEST_CENTER = '33333'

export const TUTORIAL_SHOW_BANNER_DURATION = 14

export const TUTORIAL_HISTORY_ACTIONS = ['REPLACE', 'POP']

export const NAVBAR_HEIGHT = '64px'

export const SEARCHBAR_HEIGHT = '104px'

export const TUTORIAL_HEIGHT = '48px'

export const TABLE_ROWS_PER_PAGE_OPTIONS = [5, 10, 20, 50]

export const FEATURE_FLAG_DEFAULT_CONFIG = {
  order_confirm_sqs: false,
  analytics: false,
  failed_requests: false,
  login_email: true,
  msal: true,
  multicenter: false,
  shopping_lists: false,
  show_maintenance: false,
  order_reception_sqs: false,
  approver: false,
  tutorial: false,
  habituals: false,
  weekSummary: false,
}

export const CART_CHANGES_EXCEPTION = 'DetectCartChangesException'

export const RECEPTION_COMPLETES_EXCEPTION = 'ReceptionCompletedException'

export const REQUEST_APPROVERD_EXCEPTION = 'RequestOrderApprovedException'

export const SHOPPING_LIST_TITLE_MAX_LENGTH = 30

export const MIN_CHARS_FOR_SEARCH = 3

export const MAINTENANCE_TIME_TO_RELOAD_CHECK = 60000
export const MAINTENANCE_APP_KEY_CHECK = 'portal_de_compras'
export const MAINTENANCE_MOCKED_RESPONSE = [{ app: MAINTENANCE_APP_KEY_CHECK, active: false }]

export const HELP_URL_WATCH_PORTAL_DE_COMPRAS = 'https://youtu.be/NJp8Xcz5vVk'
export const HELP_URL_WATCH_SHOPPING_LISTS = 'https://youtu.be/AD4lM-p5uDY'
export const HELP_URL_WATCH_MULTICENTER = 'https://youtu.be/JNa8dA4Zy0s'
/* eslint-disable max-len */
export const HELP_URL_WATCH_APPROVAL_REQUEST =
  'https://www.figma.com/proto/TWMSKIH9slSNJxi9CF0DpC/Compass-Group-%E2%80%93-Portal-de-Compras?page-id=5697%3A23686&node-id=11500-41725&node-type=FRAME&viewport=-23032%2C-1159%2C1&t=bWM2xyiPFDHCDTqT-1&scaling=contain&content-scaling=fixed&starting-point-node-id=11303%3A44527&hide-ui=1'
export const HELP_URL_WATCH_ORDER_APPROVAL =
  'https://www.figma.com/proto/TWMSKIH9slSNJxi9CF0DpC/Compass-Group-%E2%80%93-Portal-de-Compras?node-id=11303-43667&t=NELz2wM1chawfa5J-1&scaling=contain&content-scaling=fixed&page-id=5697%3A23686&starting-point-node-id=11303%3A44527&hotspot-hints=0&hide-ui=1'
