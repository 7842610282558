import styled from 'styled-components'

export const MasterContainer = styled.div``

export const SmallDevices = styled.div`
  padding: 2.5rem 1.25rem 0;
  overflow: scroll;
  height: 100vh;

  @media (min-width: 1201px) {
    display: none;
  }
`

export const EnvironmentWarning = styled.div`
  padding: 0.35rem;
  position: relative;
  z-index: 9999;
  color: ${props => props.theme.colors.white};
  font-size: 0.8rem;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  background: rgb(63, 94, 251);
  background: radial-gradient(circle, rgba(63, 94, 251, 1) 0%, rgba(252, 70, 107, 1) 100%);
  width: 100%;
  max-width: 45rem;
  margin: 0 auto;
  border-radius: 0 0 1rem 1rem;
  &:after {
    content: 'Development environment';
  }
`
