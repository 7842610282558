import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import colors from 'Assets/colors'
import { media } from 'Config/styled'

export const useStyles = makeStyles(() => ({
  root: {
    zIndex: '900 !important',
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  paper: { maxWidth: '22.5rem', overflow: 'hidden' },
  expand: { width: '100%', overflow: 'hidden' },
}))

export const HorizontalMainRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: '100%';
  border-bottom: 2px solid rgba(51, 51, 51, 0.24);
  padding: 1.25rem;

  .hoverable {
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin: ${props => props.margin};
  justify-content: ${props => props.justifyContent};
`

export const HorizontalClose = styled.div`
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
`

export const HorizontalProvider = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 1rem;
  margin-right: 1rem;
  color: ${props => props.theme.colors.black};
`

export const HorizontalTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  width: 30rem;
  margin-left: 1rem;

  ${media('lg')} {
    width: 100%;
  }
`

export const HorizontalText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-right: 1rem;
  margin-left: 1rem;
`

export const ProviderRow = styled(Row)`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);

  ${media('lg')} {
    flex-wrap: wrap;
  }
`

export const HorizontalProviderContainer = styled.div`
  margin: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  background: ${props => props.theme.colors.gray98b};
`

export const SVGRow = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10rem;
  padding-bottom: 2.5rem;
`

export const ErrorTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${props => props.theme.colors.black};
  padding-bottom: 1rem;
`

export const ErrorMessage = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.black};
  padding-bottom: 2.5rem;
`

export const ProductCardLoaderContainer = styled.div`
  margin: 0 0 1rem;
  padding: 1rem;
`

export const HorizontalProductContainer = styled.div`
  margin-bottom: 2rem;
`

export const SingleProductRow = styled.div`
  background: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.24);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-columns: ${({ withPrice }) => (withPrice ? '43% 17% 17% 23%' : '43% 34% 23%')};
  margin-bottom: 16px;
  height: 80px;
  margin-right: 1rem;
  margin-left: 1rem;

  ${media('lg')} {
    grid-template-columns: 1fr;
    height: auto;
    gap: 0.8rem;
    padding: 1rem;
  }
`

export const SingleProductRowCL = styled.div`
  background: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.24);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 43% 17% 17% 23%;
  margin-bottom: 16px;
  margin-top: 16px;
  height: 80px;

  ${media('lg')} {
    height: auto;
    margin: 0;
  }
`

export const SingleProductNameHelper = styled.div`
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 78px;

  ${media('lg')} {
    height: auto;
    margin: 0;
    border: 0;
  }
`

export const SingleProductName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  text-transform: uppercase;
  padding: 0 16px;

  ${media('lg')} {
    padding: 0;
  }
`

export const SingleProductPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 16px 0;
  height: 78px;

  ${media('lg')} {
    padding: 0;
    height: auto;
  }
`

export const SingleProductQtyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 16px 0;
  height: 78px;

  ${media('lg')} {
    padding: 0;
    height: auto;
  }
`

export const SingleProductTotalPrice = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${colors.black};
`

export const SingleProductTotalPriceSplit = styled.div`
  display: flex;
`

export const SingleProductTotalQty = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${colors.black};
`

export const MinAndMulti = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
`

export const SingleProductButtonContainer = styled.div`
  display: flex;
  padding: 12px 16px;
  height: 78px;

  ${media('lg')} {
    height: auto;
    padding: 0;
  }
`

export const AddCartButton = styled.div`
  width: 100%;
  background-color: ${colors.secondary};
  padding: 1rem;
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const ButtonLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
`

export const RequestProviderInfoContainer = styled.div`
  background: ${colors.gray98b};
  padding: 16px;
  display: flex;
  justify-content: space-between;
`

export const RequestProviderInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  > * {
    margin-right: 8px;
  }
`

export const RequestProviderName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
`

export const RequestProviderDeliveryDate = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.black};
  background: ${colors.cart};
  border-radius: 8px;
  padding: 0 8px;
`

export const RequestProviderOrderNumber = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.gray40l};
`

export const ProviderDetail = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  display: flex;
  width: 30rem;
  justify-content: flex-end;

  ${media('lg')} {
    width: 100%;
  }
`

export const TotalPrice = styled.span`
  margin-right: 16px;
  width: 10rem;
  text-align: right;

  ${media('lg')} {
    width: 100%;
  }
`

export const TotalProductLines = styled.span`
  width: 10rem;

  ${media('lg')} {
    width: 100%;
  }
`

export const RequestOrderContainer = styled.div`
  background: ${colors.white};
  border-top: 1px solid rgba(51, 51, 51, 0.24);
  border-bottom: ${props => (props.openProducts ? '1px solid rgba(51, 51, 51, 0.24)' : 'none')};
  border-bottom-left-radius: ${props => (props.openProducts ? '0' : '8px')};
  border-bottom-right-radius: ${props => (props.openProducts ? '0' : '8px')};
  display: grid;
  grid-template-columns: 77% 23%;
  height: 80px;
`

export const RequestRepeatOrderContainer = styled.div`
  display: flex;
  padding: 0 18px;
  border-right: 1px solid rgba(51, 51, 51, 0.24);
`

export const RequestActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
`

export const RequestRepeatOrder = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 8px;

  &:hover {
    svg {
      path {
        fill: ${colors.secondary};
      }
    }

    button {
      color: ${colors.secondary};
    }
  }

  svg {
    margin-right: 18px;
    width: 24px;
    path {
      transition: all 0.2s;
    }
  }
  button {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.black};
    margin-left: -5px;
    cursor: pointer;
    transition: all 0.2s;
  }
`

export const HelpText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
  margin-left: 38px;
`

export const ShowProductsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
`

export const ShowMoreCopy = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  width: 11rem;

  ${media('lg')} {
    width: 100%;
  }
`

export const ShowLessCopy = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.secondary};
  width: 11rem;

  ${media('lg')} {
    width: 100%;
  }
`

export const Price = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
  color: ${colors.gray40l};
`
export const PriceKG = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
`

export const NotAvailable = styled.div`
  background: ${colors.gray40l};
  border-radius: 8px;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NotAvailableLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
`
export const HorizontalTitleTotal = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  width: 30rem;

  ${media('lg')} {
    width: 100%;
  }
`

export const SelectText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  margin-left: 1rem;
  color: ${props => props.theme.colors.gray40l};
`

export const HorizontalTitleSection = styled.div`
  background: gray98b;
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-columns: ${() => '43% 17% 17% 23%'};
  margin-bottom: 16px;
  height: 30px;
  margin-right: 1rem;

  ${media('lg')} {
    display: none;
  }
`
export const LabelMobile = styled.div`
  display: none;

  ${media('lg')} {
    display: block;
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
  }
`
