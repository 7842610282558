import React, { useCallback, useEffect, useMemo } from 'react'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MulticenterNoComplianceTable from 'Components/organisms/multicenterNoCompliancesTable'
import MulticenterStats from 'Components/organisms/multicenterStats'
import { useQuery } from 'Components/utils/customHooks'
import { TICKET_STATUS } from 'Config/constants'
import routes from 'Config/routes'
import { setNoCompliancesFilters, setSelectedCenter } from 'Redux/multicenter/actions'
import HeaderMobile from 'Components/organisms/searchBar/headerMobile'
import useIsMobile from 'hooks/useIsMobile'

import FilterCenter from './center-filter'
import * as S from './styled'

const MulticenterTicketing = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const queryParams = useQuery()
  const isMobile = useIsMobile()

  const filters = useSelector(({ multicenter }) => multicenter.noCompliancesFilters)
  const centers = useSelector(state => state.auth.centersList)

  useEffect(() => {
    dispatch(setSelectedCenter(null))
  }, [dispatch])

  useEffect(() => {
    const initialStatus = queryParams.get('status')
    if (initialStatus === TICKET_STATUS.pending) {
      dispatch(setNoCompliancesFilters({ ...filters, status: initialStatus }))
      history.replace({ search: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  const parsedFilters = useMemo(() => {
    const filtersCopy = { ...filters }
    if (filtersCopy.center) {
      filtersCopy.centerId = filtersCopy.center.id
      delete filtersCopy.center
    }

    return filtersCopy
  }, [filters])

  const handleFilterChange = useCallback(
    (key, value) => {
      dispatch(setNoCompliancesFilters({ ...filters, [key]: value }))
    },
    [filters, dispatch]
  )

  const handleViewDetail = useCallback(
    ticket => {
      const ticketCenter = centers.find(({ id }) => id === `${ticket.centerId}`)
      dispatch(setSelectedCenter({ ...ticketCenter }))
      history.push(routes.multicenterTicketingDetail.replace(':id', ticket.id))
    },
    [centers, history, dispatch]
  )

  return (
    <>
      {isMobile && <HeaderMobile multicenterView />}
      <S.Container>
        <MulticenterStats />
        <div className='filters-holder'>
          <FilterCenter center={filters.center} onSelectCenter={center => handleFilterChange('center', center)} />
          <S.StyledStatusFilter status={filters.status} onChange={status => handleFilterChange('status', status)} />
        </div>
        <div className='table-holder'>
          <MulticenterNoComplianceTable filters={parsedFilters} onViewDetailClick={handleViewDetail} />
        </div>
      </S.Container>
    </>
  )
}

export default MulticenterTicketing
