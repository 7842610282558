import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import colors from 'Assets/colors'
import { media } from 'Config/styled'

export const useStyles = makeStyles(() => ({
  addCartButton: {
    width: '100%',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '2.5rem',
    textAlign: 'center',
    textTransform: 'none',
    color: colors.white,
    transition: 'all 0.2s',
    border: `1px solid ${colors.secondary}`,
    backgroundColor: colors.secondary,
    '&:hover': {
      opacity: 0.8,
      color: colors.white,
      transform: 'translateY(-2px)',
      backgroundColor: colors.secondary,
      boxShadow: '0 3px 5px rgba(51, 51, 51, 0.2)',
    },
    '&:active': {
      transform: 'translate(-1px)',
      boxShadow: '0 1px 3px rgba(51, 51, 51, 0.4)',
    },
  },
}))

export const Name = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 24px;
`

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  padding-bottom: 24px;

  ${media('lg')} {
    grid-template-columns: 1fr;
  }
`

export const TextsContainer = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const ProviderCodeContainer = styled.div`
  color: #333333;
`

export const ProviderCode = styled.div`
  font-weight: normal;
  padding: 10px 0;
`

export const NextDeliveryDate = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 10px;
`

export const FormattedDate = styled.div`
  font-weight: normal;
  padding: 10px 0;
`

export const StringDate = styled.span`
  font-weight: 600;
`

export const CalendarContainer = styled.div`
  ${media('lg')} {
    max-width: 286px;
  }
`
