import styled from 'styled-components'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { media } from 'Config/styled'

export const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    &:before {
      display: none;
    }
  }
`

export const AccordionSummary = styled(MuiAccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0 32px 0 25px;
  }

  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0 28px 0;
    & .MuiTablePagination-root .MuiToolbar-root {
      background-color: red;
    }
    ${media('lg')} {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }

    > div {
      display: flex;
      align-items: center;

      ${media('lg')} {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
      }

      &:first-child {
        justify-content: flex-start;
        column-gap: 28px;

        p {
          cursor: text;
          user-select: text;
          display: flex;
          align-items: center;
          column-gap: 12px;
        }
      }

      &:last-child {
        justify-content: flex-end;
        column-gap: 42px;
      }
    }

    &.Mui-expanded {
      margin: 32px 0 28px 0;

      .accordion-toggle {
        margin-left: -14px;

        ${media('lg')} {
          margin-left: 0;
        }
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .accordion-toggle {
      display: flex;
      align-items: center;
      column-gap: 18px;
      margin-left: 14px;
      cursor: pointer;

      ${media('lg')} {
        margin-left: 0;
      }
      svg {
        transition: transform 200ms;
      }
    }
  }
`

export const AccordionDetails = styled(MuiAccordionDetails)`
  &.MuiAccordionDetails-root {
    min-height: 220px;
    padding: 0px;
  }
`
