import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import colors from 'Assets/colors'
import { BigTitle, TextInput, RegularText } from 'Components/atoms'
import { AuthTypes, AuthActions } from 'Redux/auth'
import { ModalError } from 'Components/molecules'
import { ReactComponent as Microsoft } from 'Assets/Logos/MicrosoftLogo.svg'
import { ReactComponent as MicrosoftLogoText } from 'Assets/Icons/MicrosoftLogoText.svg'
import { selectConfig } from 'Redux/auth/utils'

import {
  ButtonContainer,
  ActionButton,
  MicrosoftLogo,
  ButtonLiteral,
  CircularProgressStyled,
  InputLabel,
  MsalButton,
  MSLogoContainer,
} from './styled'

const Login = ({ title, onClickMsalLogin, isLoading, isLoadingMsal }) => {
  const features = useSelector(selectConfig)
  if (!features?.login_email && features?.msal) {
    return <LoginMsal title={title} onClickMsalLogin={onClickMsalLogin} isLoading={isLoadingMsal} />
  }

  return (
    <LoginMsalEmail
      title={title}
      onClickMsalLogin={onClickMsalLogin}
      isLoading={isLoading}
      isLoadingMsal={isLoadingMsal}
    />
  )
}

Login.defaultProps = {
  title: '',
  onClickMsalLogin: () => null,
  isLoading: false,
  isLoadingMsal: false,
}

Login.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  onClickMsalLogin: PropTypes.func,
  isLoadingMsal: PropTypes.bool,
}

const LoginMsal = ({ title, onClickMsalLogin, isLoading }) => {
  const { t } = useTranslation()

  return (
    <>
      <BigTitle margin='0 0 1rem' textAlign='center'>
        {t(title)}
      </BigTitle>
      <RegularText margin='0 0 60px' textAlign='center'>
        {t('enterLoginMessageMsal')}
      </RegularText>
      <MSLogoContainer>
        <MicrosoftLogoText />
      </MSLogoContainer>
      <MsalButton onClick={onClickMsalLogin}>
        <span>{t('accessPortal')}</span>
        {isLoading && <CircularProgressStyled size={16} />}
      </MsalButton>
    </>
  )
}

LoginMsal.defaultProps = {
  title: '',
  onClickMsalLogin: () => null,
  isLoading: false,
}

LoginMsal.propTypes = {
  title: PropTypes.string,
  onClickMsalLogin: PropTypes.func,
  isLoading: PropTypes.bool,
}

const LoginMsalEmail = ({ title, onClickMsalLogin, isLoading, isLoadingMsal }) => {
  const { t } = useTranslation()
  const errorLogin = useSelector(state => state.auth.errorLogin)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorUsername, setErrorUsername] = useState(null)
  const [errorPassword, setErrorPassword] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)
  const dispatch = useDispatch()
  const buttonRef = React.useRef(null)
  const features = useSelector(selectConfig)

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        buttonRef.current.click()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [])

  const onChangeUser = e => {
    if (!username) setErrorUsername(t('errorUsername'))
    else setErrorUsername(null)
    setUsername(e.target.value)
  }

  const onChangePassword = e => {
    if (!password) setErrorPassword(t('errorPassword'))
    else setErrorPassword(null)
    setPassword(e.target.value)
  }

  const onLogin = () => {
    if (username && password) {
      setSubmitLoading(true)
      dispatch({
        type: AuthTypes.LOGIN,
        params: { username, password },
      })
    } else {
      if (!username) setErrorUsername(t('errorUsername'))
      if (!password) setErrorPassword(t('errorPassword'))
    }
  }

  const setShowModal = () => {
    dispatch(AuthActions.setErrorToken(''))
    dispatch(AuthActions.setErrorLogin(null))
    setSubmitLoading(false)
  }

  return (
    <>
      <BigTitle margin='0 0 1rem' textAlign='center'>
        {t(title)}
      </BigTitle>
      <RegularText margin='0 0 30px' textAlign='center'>
        {t('enterLoginMessage')}
      </RegularText>
      {features?.login_email && (
        <>
          <InputLabel>{t('loginUserLabel')}</InputLabel>
          <TextInput
            value={username}
            placeholder={errorUsername || t('loginUserPlaceholder')}
            placeholderColor={errorUsername ? colors.red : 'null'}
            border={username || !errorUsername ? '0' : `1px solid ${colors.red}`}
            borderRadius='8px'
            background='#F4F1F1'
            margin='0 0 16px'
            height='56px'
            onChange={onChangeUser}
            name='new-user'
            disabled={submitLoading}
          />
          <InputLabel>{t('loginPassLabel')}</InputLabel>
          <TextInput
            value={password}
            placeholder={errorPassword || t('loginPassPlaceholder')}
            placeholderColor={errorPassword ? colors.red : 'null'}
            border={password || !errorPassword ? 'none' : `1px solid ${colors.red}`}
            borderRadius='8px'
            background='#F4F1F1'
            margin='0 0 24px'
            height='56px'
            onChange={onChangePassword}
            type='password'
            name='new-password'
            disabled={submitLoading}
          />
          <ButtonContainer>
            <ActionButton onClick={onLogin} submitLoading={isLoading} ref={buttonRef}>
              <ButtonLiteral>{t('enterPortal')}</ButtonLiteral>
              {isLoading && <CircularProgressStyled size={16} />}
            </ActionButton>
          </ButtonContainer>
        </>
      )}

      {features?.msal && (
        <MicrosoftLogo onClick={onClickMsalLogin}>
          <Microsoft />
          <span>{t('enterAzurePortal')}</span>
          {isLoadingMsal && <CircularProgressStyled size={16} color='black' />}
        </MicrosoftLogo>
      )}

      {errorLogin ? (
        <ModalError
          showModal={errorLogin != null}
          setShowModal={setShowModal}
          title={t('errorTitle')}
          message={errorLogin?.message || t('errorLogin')}
          onConfirm={setShowModal}
          color='red'
          width='100px'
          height='100px'
        />
      ) : null}
    </>
  )
}

LoginMsalEmail.defaultProps = {
  title: '',
  onClickMsalLogin: () => null,
  isLoading: false,
  isLoadingMsal: false,
}

LoginMsalEmail.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  onClickMsalLogin: PropTypes.func,
  isLoadingMsal: PropTypes.bool,
}

export default Login
