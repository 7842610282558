import React, { useCallback, useEffect, useMemo, useState } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Sentry from 'Services/sentry'
import { ReactComponent as DownloadIcon } from 'Assets/Icons/Descargar.svg'
import { ReactComponent as FilterIcon } from 'Assets/Icons/Filter.svg'
import Button from 'Components/atoms/button'
import Modal from 'Components/atoms/modal'
import UnderlineButton from 'Components/atoms/underlineButton'
import ModalDownloadEmail from 'Components/molecules/modalDownloadEmail'
import ModalMulticenterDownloadList from 'Components/molecules/modalMulticenterDownloadList'
import ModalMulticenterOrderFilter from 'Components/molecules/modalMulticenterOrderFilter'
import MulticenterOrder from 'Components/organisms/multicenterOrder'
import MulticenterStats from 'Components/organisms/multicenterStats'
import { useQuery } from 'Components/utils/customHooks'
import { ORDER_STATUS } from 'Config/constants'
import { fetchCentersList, setOrdersFilters, setQAFilters } from 'Redux/multicenter/actions'
import { selectCenters } from 'Redux/multicenter/selectors'
import {
  downloadMulticenterProductCatalog,
  getMulticenterProviderOrdersExcel,
  getMulticenterProviderOrdersQaExcel,
} from 'Services/api'
import { NoResults } from 'Components/molecules'
import ModalMulticenterFilterDownload from 'Components/molecules/modalMulticenterFilterDownload'
import HeaderMobile from 'Components/organisms/searchBar/headerMobile'
import useIsMobile from 'hooks/useIsMobile'

import * as S from './styled'
import useInfiniteScroll from './use-infinite-scroll'

const CENTERS_PER_PAGE = 10

const MulticenterOrders = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const queryParams = useQuery()
  const isMobile = useIsMobile()

  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [showDownloadModalCatalog, setShowDownloadModalCatalog] = useState(false)
  const [showDownloadQaExcelModal, setShowDownloadQaExcelModal] = useState(false)
  const [showDownloadProviderOrdersExcelModal, setShowDownloadProviderOrdersExcelModal] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [isLoadingQA, setIsLoadingQA] = useState(false)
  const [isLoadingCatalog, setIsLoadingCatalog] = useState(false)
  const [isLoadingOrders, setIsLoadingOrders] = useState(false)
  const [showDownloadFilters, setShowDownloadFilters] = useState(false)
  const [showDownloadCatalogFilter, setShowDownloadCatalogFilter] = useState(false)
  const [isQADownload, setIsQADownload] = useState(false)

  const { username } = useSelector(state => state.auth.user)
  const isLoadingCenters = useSelector(({ multicenter }) => multicenter.isLoadingCenters)
  const { data: centersList, currentPageCount } = useSelector(selectCenters)
  const filters = useSelector(({ multicenter }) => multicenter.ordersFilters)
  const qaFilters = useSelector(({ multicenter }) => multicenter.qaFilters)

  const { page, intersectElementRef, resetPagination } = useInfiniteScroll(currentPageCount, CENTERS_PER_PAGE)

  const areFiltersActive = useMemo(() => !!Object.keys(filters).length, [filters])

  useEffect(() => {
    dispatch(fetchCentersList({ filters, page, pageSize: CENTERS_PER_PAGE }))
  }, [filters, page, dispatch])

  useEffect(() => {
    const initialStatus = queryParams.get('status')
    if (initialStatus === ORDER_STATUS.PR) {
      dispatch(setOrdersFilters({ ...filters, status: initialStatus }))
      history.replace({ search: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, history])

  const handleDownloadCatalog = useCallback(
    async strParams => {
      setShowDownloadFilters(false)
      setShowDownloadCatalogFilter(false)
      try {
        setIsLoadingCatalog(true)
        await downloadMulticenterProductCatalog(strParams)
        setShowDownloadModalCatalog(true)
      } catch (e) {
        toast.error(t('downloadCatalogError'))
        Sentry.captureException(e)
      } finally {
        setIsLoadingCatalog(false)
        setShowDownloadModal(false)
      }
    },
    [t]
  )

  const handleFilterOrdersClick = useCallback(() => setShowFilters(true), [])

  const handleFiltersChange = useCallback(
    updatedFilters => {
      resetPagination()
      dispatch(setOrdersFilters(updatedFilters))
      setShowFilters(false)
    },
    [dispatch, resetPagination]
  )

  const handleDownloadOrders = useCallback(
    async strParams => {
      try {
        setShowDownloadFilters(false)
        setShowDownloadCatalogFilter(false)
        setIsLoadingOrders(true)
        await getMulticenterProviderOrdersExcel(strParams)
        setShowDownloadProviderOrdersExcelModal(true)
      } catch (e) {
        toast.error(t('petitionError'))
        Sentry.captureException(e)
      } finally {
        setIsLoadingOrders(false)
        setShowDownloadModal(false)
      }
    },
    [t]
  )

  const handleShowDownloadFilters = useCallback(() => {
    setShowDownloadModal(false)
    setShowDownloadCatalogFilter(false)
    setShowDownloadFilters(true)
  }, [])

  const handleShowDownloadCatalogFilters = useCallback(() => {
    setShowDownloadModal(false)
    setShowDownloadFilters(false)
    setShowDownloadCatalogFilter(true)
  }, [])

  const handleFiltersQA = useCallback(
    async qaFiltersUpdated => {
      try {
        setIsLoadingQA(true)
        await getMulticenterProviderOrdersQaExcel(qaFiltersUpdated)
        setShowDownloadQaExcelModal(true)
      } catch (e) {
        toast.error(t('petitionError'))
        Sentry.captureException(e)
      } finally {
        setIsLoadingQA(false)
        setShowDownloadModal(false)
        setShowFilters(false)
        setIsQADownload(false)
      }
    },
    [t]
  )

  const handleDownloadQA = useCallback(() => {
    setShowDownloadModal(false)
    setShowDownloadFilters(false)
    setShowDownloadCatalogFilter(false)
    dispatch(setQAFilters({ status: ORDER_STATUS.RC }))
    setShowFilters(true)
    setIsQADownload(true)
  }, [dispatch])

  const ff = isQADownload ? { ...qaFilters } : { ...filters }

  return (
    <>
      {isMobile && <HeaderMobile multicenterView />}
      <S.Container>
        <p>{t('multicenterWelcomeMessage', { username })}</p>
        <MulticenterStats />
        <S.Content>
          <div className='centers-list-options'>
            <Button className='squared' onClick={handleFilterOrdersClick}>
              <S.IconContainer showDot={filters && Object.keys(filters).length > 0}>
                <FilterIcon />
              </S.IconContainer>
              <span>{t`filterButtonSearch`}</span>
            </Button>

            <UnderlineButton label={t`downloadAdmin`} Icon={DownloadIcon} onClick={() => setShowDownloadModal(true)} />
          </div>
          {areFiltersActive && !!centersList.length && <S.FiltersMessage>{t`filteredSearchResults`}</S.FiltersMessage>}
          {!centersList.length && !isLoadingCenters && (
            <NoResults title={t('noMulticenterResults')} description={t('noMulticenterResultsSub')} />
          )}
          <div className={classNames('centers-list', { 'is-loading': isLoadingCenters })}>
            {isLoadingCenters && page === 0 ? (
              <CircularProgress className='initial' size={40} />
            ) : (
              centersList.map((center, idx) => (
                <div key={center.id} ref={centersList.length === idx + 2 ? intersectElementRef : null}>
                  <MulticenterOrder center={center} />
                </div>
              ))
            )}
            {isLoadingCenters && page !== 0 && <CircularProgress size={40} />}
          </div>
        </S.Content>
        <ModalMulticenterDownloadList
          isSingleOrder={false}
          canDownloadCatalog
          canDownloadOrder
          canDownloadQA
          showModal={showDownloadModal}
          setShowModal={setShowDownloadModal}
          onDownloadCatalog={handleShowDownloadCatalogFilters}
          onDownloadOrder={handleShowDownloadFilters}
          onDownloadQa={handleDownloadQA}
          isLoadingQA={isLoadingQA}
          isLoadingCatalog={isLoadingCatalog}
          isLoadingOrders={isLoadingOrders}
        />
        <Modal isOpen={showDownloadFilters} setIsOpen={setShowDownloadFilters} width='960px' height='740px'>
          <ModalMulticenterFilterDownload title={t('downloadOrdersList')} onChange={handleDownloadOrders} />
        </Modal>
        <Modal isOpen={showDownloadCatalogFilter} setIsOpen={setShowDownloadCatalogFilter} width='960px' height='740px'>
          <ModalMulticenterFilterDownload title={t('downloadCatalog')} onChange={handleDownloadCatalog} onlyCenters />
        </Modal>
        <ModalDownloadEmail
          setShowModal={() => setShowDownloadModalCatalog(false)}
          showModal={showDownloadModalCatalog}
          title={t('downloadProductCatalogTitle')}
          description={t('downloadProductCatalogDescription')}
        />
        <ModalDownloadEmail
          setShowModal={() => setShowDownloadProviderOrdersExcelModal(false)}
          showModal={showDownloadProviderOrdersExcelModal}
          title={t('downloadOrdersExcelMultipleTitle')}
          description={t('downloadOrdersExcelMultipleDescription')}
        />
        <ModalDownloadEmail
          setShowModal={() => setShowDownloadQaExcelModal(false)}
          showModal={showDownloadQaExcelModal}
          title={t('downloadQaExcelMultipleTitle')}
          description={t('downloadQaExcelMultipleDescription')}
        />
        <Modal isOpen={showFilters} setIsOpen={setShowFilters} width='960px' height='740px'>
          <ModalMulticenterOrderFilter
            initialFilters={{ ...ff }}
            message={t('filterMulticenterModalSubtitle')}
            title={isQADownload ? t('filterMulticenterQATitle') : t('filterMulticenterModalTitle')}
            onChange={isQADownload ? handleFiltersQA : handleFiltersChange}
            disabledStatus={isQADownload}
          />
        </Modal>
      </S.Container>
    </>
  )
}

export default MulticenterOrders
