import React, { useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { Box } from '@material-ui/core'
import find from 'lodash/find'
import { ReactComponent as SwitchOnIcon } from 'Assets/Icons/SwitchOn.svg'
import { ReactComponent as CompassCenterIcon } from 'Assets/Icons/CompassCenter.svg'
import { ModalCenter, ApproverTutorialBanner } from 'Components/molecules'
import { AuthTypes } from 'Redux/auth'
import { ROLES, TUTORIAL_NAMES } from 'Config/constants'
import { useMsal } from '@azure/msal-react'
import { logoutRequest } from 'Config/mfa-config'
import { checkTutorialBannerV2 } from 'Redux/tutorial/actions'
import { selectConfig } from 'Redux/auth/utils'
import useIsMobile from 'hooks/useIsMobile'

import HeaderMobile from '../searchBar/headerMobile'
import MulticenterButtons from '../navBar/multicenterButtons'

import { useStyles, Container, ToolbarRightSide } from './styled'

export default function ApproverNavBar() {
  const classes = useStyles()
  const isMobile = useIsMobile()

  const dispatch = useDispatch()
  const features = useSelector(selectConfig)

  const user = useSelector(state => state.auth.user)
  const hasOnlyOneCenter = useSelector(({ auth }) => auth.hasOnlyOneCenter)
  const showMulticenterButtons = useMemo(() => features?.multicenter && !hasOnlyOneCenter, [hasOnlyOneCenter, features])

  const [showModal, setShowModal] = useState(false)

  const onCenterClick = () => {
    if (hasOnlyOneCenter) return
    setShowModal(true)
  }

  useEffect(() => {
    if (features?.tutorial) {
      dispatch(checkTutorialBannerV2(TUTORIAL_NAMES.approverRoleV2))
    }
  }, [dispatch, features])

  return (
    <Container maxHeight='200px' className={classes.root}>
      {features?.tutorial && <ApproverTutorialBanner />}
      {isMobile ? (
        <HeaderMobile isApprover onCenterClick={onCenterClick} multicenterView />
      ) : (
        <AppBar position='static' color='default' className={classes.bar}>
          <Toolbar className={classes.toolbar}>
            <ToolbarRightSide>
              <CenterButton onClick={onCenterClick} />
              {showMulticenterButtons && (
                <MulticenterButtons isApprover showBackToCenter={false} onCenterClick={onCenterClick} />
              )}
            </ToolbarRightSide>
            {user && (
              <Box>
                <LogoutMenuButton />
                <UserName />
                <Role />
              </Box>
            )}
          </Toolbar>
        </AppBar>
      )}

      <ModalCenter showModal={showModal} setShowModal={setShowModal} />
    </Container>
  )
}

const UserName = () => {
  const classes = useStyles()
  const { username } = useSelector(state => state.auth.user)

  return <span className={classes.username}>{username}</span>
}

const Role = () => {
  const classes = useStyles()

  const { roles } = useSelector(state => state.auth.user)
  const role = find(roles, elem => elem !== 'ROLE_USER')

  if (!ROLES[role]) {
    return null
  }

  return <span className={classes.role}>{ROLES[role]}</span>
}

const CenterButton = ({ onClick }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Button onClick={onClick} startIcon={<CompassCenterIcon />} className={classes.menuButton} color='inherit'>
      {t('selectYourCenter')}
    </Button>
  )
}

CenterButton.defaultProps = {
  onClick: () => null,
}

CenterButton.propTypes = {
  onClick: PropTypes.func,
}

const LogoutMenuButton = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { instance } = useMsal()

  const user = useSelector(state => state.auth.user)

  const logout = async () => {
    dispatch({
      type: AuthTypes.LOGOUT_USER,
    })
    if (user.msal) {
      await instance.logout(logoutRequest)
    }
  }

  return (
    <IconButton
      color='inherit'
      onClick={logout}
      style={{ textTransform: 'none ' }}
      className={classes.logout}
      data-cy='logout'
    >
      <SwitchOnIcon />
    </IconButton>
  )
}
