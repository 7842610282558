import React from 'react'

import Routes from 'Components/system/routes'
import { ThemeProvider } from 'styled-components/macro'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import { GlobalStyle, theme, muiTheme } from 'Config/styled'
import { store, persistor } from 'Config/redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'Assets/i18n'
import history from 'Config/history'
import { ThemeProvider as MuiProvider } from '@material-ui/core/styles'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { Router } from 'react-router-dom'
import { msalConfig } from 'Config/mfa-config'
import * as Sentry from '@sentry/react'
import Hotjar from '@hotjar/browser'
import { BrowserTracing } from '@sentry/tracing'
import SmallDeviceAlert from 'Components/organisms/smallDeviceAlert'
import { useMaintenanceStatus } from 'hooks/maintenance'
import MaintenanceMode from 'Components/atoms/maintenanceMode'

import { MasterContainer, SmallDevices, EnvironmentWarning } from './styled'

const msalInstance = new PublicClientApplication(msalConfig)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.2,
  transactionContext: { name: 'frontend' },
})

const App = () => {
  const { active: maintenanceActive, message: maintenanceMessage } = useMaintenanceStatus()
  const siteId = parseInt(process.env.REACT_APP_HOTJAR_SITE_ID, 10)
  const hotjarVersion = parseInt(process.env.REACT_APP_HOTJAR_VERSION, 10)
  Hotjar.init(siteId, hotjarVersion, {
    debug: process.env.NODE_ENV === 'development',
  })
  const hideMobileDevices = process.env.REACT_APP_HIDE_MOBILE_DEVICES || 'true'

  return (
    <>
      {maintenanceActive ? (
        <MaintenanceMode message={maintenanceMessage} />
      ) : (
        <MsalProvider instance={msalInstance}>
          <>
            {hideMobileDevices === 'true' && (
              <SmallDevices>
                <SmallDeviceAlert />
              </SmallDevices>
            )}
            <MasterContainer>
              <GlobalStyle />
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <ThemeProvider theme={theme}>
                    <MuiProvider theme={muiTheme}>
                      {process.env.REACT_APP_ENVIRONMENT_NAME === 'dev' && <EnvironmentWarning />}
                      <Router history={history}>
                        <Routes />
                      </Router>
                    </MuiProvider>
                  </ThemeProvider>
                </PersistGate>
              </Provider>
              <ToastContainer hideProgressBar pauseOnHover={false} closeButton={false} />
            </MasterContainer>
          </>
        </MsalProvider>
      )}
    </>
  )
}
export default App
