import { makeStyles, alpha } from '@material-ui/core/styles'
import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'
import colors from 'Assets/colors'
import { media } from 'Config/styled'

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    transition: 'all 0.4s ease-in',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  bar: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    textTransform: 'none',
    '&.my-centers-nav svg path': {
      fill: colors.black,
    },
  },
  title: {
    flexGrow: 1,
    display: 'flex',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '24ch',
      '&:focus': {
        width: '40ch',
      },
    },
  },
  username: {
    display: 'inline-block',
    marginLeft: '5px',
    marginRight: '5px',
    fontSize: '0.875rem',
  },
  role: {
    display: 'inline-block',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    marginLeft: '5px',
    marginRight: '5px',
    textTransform: 'uppercase',
    borderRadius: '10px',
    padding: '7px',
    fontSize: '0.875rem',
  },
  logout: {
    minWidth: 'auto',
    '& .MuiButton-startIcon': {
      margin: '0px',
    },
  },
}))

export const Text = styled.div`
  cursor: pointer;
`

export const Container = styled.div`
  max-height: ${props => props.maxHeight};
`

export const StyledChip = styled(Chip)`
  background: ${colors.secondary} !important;
  border-radius: 8px !important;
  padding: 0 8px !important;
  height: unset !important;

  .MuiChip-label {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 0 !important;
  }
`

export const OrderPRCounter = styled.div`
  background: ${colors.red};
  color: ${colors.white};
  border-radius: 8px;
  height: 24px;
  width: 24px;
  text-align: center;
  margin-left: 8px;

  ${media('lg')} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
`

export const OrderPRBullet = styled.div`
  background: ${colors.red};
  color: ${colors.white};
  border-radius: 8px;
  height: 8px;
  width: 8px;
  text-align: center;
  margin-left: 8px;
`

export const Badge = styled.div`
  background: ${colors.red};
  color: ${colors.white};
  border-radius: 8px;
  height: 24px;
  width: 24px;
  text-align: center;
  margin-left: 18px;
`

export const Topbar = styled.div`
  height: 48px;
  padding: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: ${colors.black};
  background: ${colors.grayInput};
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const RestartButton = styled.button`
  font-size: 1rem;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
`
export const Close = styled.div`
  flex-direction: row;
  position: absolute;
  right: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: ${colors.black};
  background: ${colors.grayInput};
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  max-width: 50px;
  &:hover {
    opacity: 0.5;
  }
`
