import React, { useEffect, useState, useRef, useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import colors from 'Assets/colors'
import ContentLoader from 'react-content-loader'
import moment from 'moment'
import set from 'lodash/set'
import { useParams } from 'react-router'
import find from 'lodash/find'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import isEmpty from 'lodash/isEmpty'
import { DeliveryTypes } from 'Redux/delivery'
import { setProviderOrderForNC } from 'Redux/delivery/actions'
import { selectCenterId } from 'Redux/purchases/utils'
import { PurchaseTypes } from 'Redux/purchases'
import { TicketActions } from 'Redux/ticket'
import routes from 'Config/routes'
import { NOTE_MAX_LENGHT, RECEPTION_COMPLETES_EXCEPTION, RECEPTION_STATUS } from 'Config/constants'
import { putDeliveryQa, confirmDelivery, getProviderReceptionOrdersLimitDate, confirmDeliverySQS } from 'Services/api'
import { TextInput, CountInput, ReceptionStatus, NumberFormat } from 'Components/atoms'
import { ModalLoading, ModalError } from 'Components/molecules'
import { useHistory } from 'react-router-dom'
import { selectConfig } from 'Redux/auth/utils'
import Box from '@material-ui/core/Box'

import NotServedInfo from './NotServedInfo'
import validate from './validate'
import {
  Container,
  Title,
  SubTitle,
  RequestedContainer,
  SingleRequestedContainer,
  RequestedHeader,
  RequestedHeaderInfo,
  RequestedProviderInfoContainer,
  RequestedProviderInfo,
  RequestedProviderName,
  RequestedProviderDeliveryDate,
  RequestedProviderOrderNumber,
  ProviderDetail,
  TotalPrice,
  TotalProductLines,
  ProductsContainer,
  ProductHeader,
  StatusNameComboHeader,
  ProductHeaderItem,
  ProductContainer,
  StatusNameCombo,
  ProductName,
  SingleFieldContainer,
  FixedInputField,
  ActionContainer,
  DateSelectorContainer,
  DateContainer,
  DateLabel,
  DateSelected,
  ButtonContainer,
  SelectDateButton,
  TextInputContainer,
  FinishButtonContainer,
  FinishButton,
  ButtonLiteral,
  TotalsContainer,
  TotalsTotalPrice,
  TotalsTotalItems,
  StyledCalendar,
  DatesContainer,
  WrongDate,
  WarningDateContainer,
  CircularProgressStyled,
  LabelMobile,
} from './styled'

const Delivery = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { orderId, documentTypeCode, documentCompany } = useParams()

  const itemEls = useRef({})

  const providerOrder = useSelector(({ delivery: deliveryState }) => deliveryState.providerOrder)
  const questionsForm = useSelector(state => state.purchase.qaForm || {})
  const deliveryForm = useSelector(({ delivery: deliveryState }) => deliveryState.deliveryForm)
  const centerId = useSelector(selectCenterId)
  const center = useSelector(state => state.auth.selectedCenter)
  const form = useSelector(({ delivery: deliveryState }) => deliveryState.receptionlines)
  const isLoadingProviderRecivingOrders = useSelector(
    ({ delivery: deliveryState }) => deliveryState.isLoadingProviderRecivingOrders
  )
  const features = useSelector(selectConfig)

  const [submitLoading, setSubmitLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [totalPrice, setTotalPrice] = useState(null)
  const [showCalendar, setShowCalendar] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [showNotServedModal, setShowNotServedModal] = useState(false)

  const [showSubmitErrorModal, setShowSubmitErrorModal] = useState(false)
  const [messageErrorModal, setMessageErrorModal] = useState('deliveryError')

  const { request, lines = [], delivery } = providerOrder
  const { showPrice } = center
  const withProdException = lines.some(prod => prod.price === 0)

  useEffect(() => {
    dispatch({
      type: DeliveryTypes.PROVIDER_RECIVING_ORDERS,
      params: { orderId, documentTypeCode, documentCompany },
    })
    return () => {
      dispatch({
        type: DeliveryTypes.PROVIDER_RECIVING_ORDERS_RESET,
      })
    }
  }, [dispatch, orderId, documentTypeCode, documentCompany])

  useEffect(() => {
    if (lines && lines.length > 0) {
      const total = lines?.reduce((acc, product) => acc + product.price, 0)
      setTotalPrice(total)
    }
  }, [lines])

  useEffect(() => {
    if (form) {
      const total = form?.reduce((acc, newForm) => acc + newForm.amount * newForm.priceUnit, 0)
      setTotalPrice(total)
    }
  }, [form])

  const [limitDate, setLimitDate] = useState(null)

  useEffect(() => {
    getProviderReceptionOrdersLimitDate().then(response => {
      setLimitDate(response)
    })
  }, [])

  const handleChange = (index, name, value) => {
    const formCopy = [...(form || [])]

    set(formCopy, `[${index}].${name}`, value)
    dispatch({
      type: DeliveryTypes.PROVIDER_RECIVING_ORDERS_FORM,
      params: {
        orderId,
        form: {
          receptionlines: formCopy,
          providerOrder,
        },
        documentTypeCode,
        documentCompany,
      },
    })
  }

  const handleDeliveryChange = (name, value) => {
    const formCopy = { ...deliveryForm }
    set(formCopy, name, value)
    dispatch({
      type: DeliveryTypes.DELIVERY_FORM,
      params: { ...formCopy },
    })
  }

  const handleOpenNC = useCallback(() => {
    const providerId = providerOrder.provider.id
    dispatch(TicketActions.setIsNotServedOrder(true))
    dispatch(TicketActions.setNonConformityOrder(providerOrder))
    history.push(
      routes.addTicketing(providerId, providerOrder.id, providerOrder.documentTypeCode, providerOrder.documentCompany)
    )
  }, [dispatch, history, providerOrder])

  const ProductLinesPrice = () => {
    const textProduct =
      lines.length === 1
        ? `${lines.length} ${t('product').toLowerCase()}`
        : `${lines.length} ${t('products').toLowerCase()}`
    if (!lines.length) return null
    return <TotalProductLines>{textProduct}</TotalProductLines>
  }

  const handleDeliveryError = error => {
    if (error?.response?.data?.error === RECEPTION_COMPLETES_EXCEPTION && error?.response?.data?.statusCode === 422) {
      toast.info(t('deliveryQaConfirmedError', { id: orderId }))
      history.push(routes.historic)
    } else {
      setMessageErrorModal('deliveryError')
      setShowSubmitErrorModal(true)
      setSubmitLoading(false)
    }
  }

  const handleSubmit = () => {
    const formCopy = [...(form || [])]

    const totalCount = formCopy.reduce((acc, current) => acc + current.amount, 0)

    const payload = { ...deliveryForm, receptionlines: formCopy }
    const submitErrors = validate(payload, t)
    setErrors(submitErrors)
    setIsSubmit(true)

    if (Object.keys(submitErrors).length > 0) {
      setMessageErrorModal('validationDeliveryError')
      setShowSubmitErrorModal(true)
      return null
    }
    if (totalCount > 0) {
      setSubmitLoading(true)
      const ep = features?.order_reception_sqs ? confirmDeliverySQS : confirmDelivery

      ep(centerId, orderId, documentTypeCode, documentCompany, payload)
        .then(() => {
          dispatch({
            type: PurchaseTypes.FETCH_PENDING_PROVIDER_ORDERS,
          })
          if (!isEmpty(questionsForm)) {
            putDeliveryQa(orderId, documentTypeCode, documentCompany, centerId, questionsForm)
              .then(() => {
                dispatch(setProviderOrderForNC(providerOrder))

                history.push(routes.addDeliveryConfirmed(orderId, documentTypeCode, documentCompany))
              })
              .catch(() => {
                toast.error(t('petitionError'))
                setSubmitLoading(false)
              })
          } else {
            dispatch(setProviderOrderForNC(providerOrder))

            history.push(routes.addDeliveryConfirmed(orderId, documentTypeCode, documentCompany))
          }
        })
        .catch(e => {
          handleDeliveryError(e)
        })
    } else {
      setShowNotServedModal(true)
    }
    return null
  }

  const disableCompleteButton = !get(deliveryForm, 'receptionDate') || !get(deliveryForm, 'deliveryNote')

  const handleNext = (id, key, i) => {
    if (!id || !itemEls || !itemEls.current) return

    let index = i

    const currentItem = itemEls.current[index]
    if (!currentItem) return

    if (key === 'amount' && currentItem.priceUnit) {
      currentItem.priceUnit.focus()
    } else {
      if (index >= lines.length - 1) {
        index = 0
      } else {
        index += 1
      }

      const item = itemEls.current[index]
      if (item && item.amount) item.amount.focus()
    }
  }

  const handlePreviuos = (id, key, i) => {
    if (!id || !itemEls || !itemEls.current) return

    let index = i

    const currentItem = itemEls.current[index]
    if (!currentItem) return

    if (key === 'priceUnit' && currentItem.amount) {
      currentItem.amount.focus()
    } else {
      if (index <= 0) {
        index = lines.length - 1
      } else {
        index -= 1
      }

      const item = itemEls.current[index]
      if (key === 'amount' && item.priceUnit) {
        item.priceUnit.focus()
      } else if (item && item.amount) {
        item.amount.focus()
      }
    }
  }

  let linesCount = 0

  return (
    <Container>
      <Title>{t('receptionOrderTitle')}</Title>
      <SubTitle>{t('receptionOrderSubtitle')}</SubTitle>
      <RequestedContainer>
        {isLoadingProviderRecivingOrders && (!lines || lines.length <= 0) ? (
          <RequestsLoader />
        ) : (
          <SingleRequestedContainer>
            <RequestedHeader>
              <RequestedHeaderInfo>{t('request', { request })}</RequestedHeaderInfo>
              <RequestedHeaderInfo>{moment(providerOrder.created).format('DD/MM/YYYY')}</RequestedHeaderInfo>
            </RequestedHeader>

            <RequestedProviderInfoContainer>
              <RequestedProviderInfo>
                <RequestedProviderName>{get(lines, '0.provider.name')}</RequestedProviderName>
                <RequestedProviderDeliveryDate>
                  {t('historyDeliveryDate')} {moment(delivery).format('DD/MM/YYYY')}
                </RequestedProviderDeliveryDate>
                <RequestedProviderOrderNumber>{orderId}</RequestedProviderOrderNumber>
              </RequestedProviderInfo>
              <ProviderDetail>
                {totalPrice && showPrice ? (
                  <TotalPrice>
                    <NumberFormat value={totalPrice} />
                  </TotalPrice>
                ) : null}
                <ProductLinesPrice />
              </ProviderDetail>
            </RequestedProviderInfoContainer>
            <ProductsContainer>
              <ProductHeader>
                <StatusNameComboHeader>
                  <ProductHeaderItem>{t('status')}</ProductHeaderItem>
                  <ProductHeaderItem>{t('product')}</ProductHeaderItem>
                </StatusNameComboHeader>

                <ProductHeaderItem>{t('orderQty')}</ProductHeaderItem>
                <ProductHeaderItem>{t('recievedQty')}</ProductHeaderItem>
                {withProdException || showPrice ? <ProductHeaderItem>{t('price')}</ProductHeaderItem> : null}
                {withProdException || showPrice ? <ProductHeaderItem>{t('sum')}</ProductHeaderItem> : null}
              </ProductHeader>
              {form &&
                lines.map((product, index) => {
                  const { status, name, measureUnit, amount, reference, id } = product
                  const itemForm = find(form, { id: reference, documentLineNumber: product.documentLineNumber })
                  if (!itemForm) return null
                  const { amount: amountReceived, priceUnit } = itemForm

                  const lineIndex = linesCount
                  linesCount += 1

                  return (
                    <ProductContainer key={`${reference}_${id}_${lineIndex}`}>
                      <StatusNameCombo>
                        <ReceptionStatus newStatus={status} />
                        <SingleFieldContainer>
                          <LabelMobile>{t('product')}</LabelMobile>
                          <ProductName>{name}</ProductName>
                        </SingleFieldContainer>
                      </StatusNameCombo>
                      <SingleFieldContainer>
                        <LabelMobile>{t('orderQty')}</LabelMobile>

                        <FixedInputField>
                          {amount} &nbsp;
                          {measureUnit}
                        </FixedInputField>
                      </SingleFieldContainer>

                      {status !== RECEPTION_STATUS.FT ? (
                        <Box>
                          <LabelMobile>{t('recievedQty')}</LabelMobile>

                          <CountInput
                            amountOrdered={amount}
                            value={amountReceived}
                            unit={measureUnit}
                            onChange={e => handleChange(index, 'amount', e)}
                            format='float'
                            innerRef={element => {
                              if (element) {
                                itemEls.current[lineIndex] = itemEls.current[lineIndex] || {}
                                itemEls.current[lineIndex].amount = element
                                itemEls.current[lineIndex].index = lineIndex
                              }
                            }}
                            onPrevious={() => handlePreviuos(reference, 'amount', lineIndex)}
                            onNext={() => handleNext(reference, 'amount', lineIndex)}
                            min={amount < 0 ? null : 0}
                          />
                        </Box>
                      ) : (
                        <SingleFieldContainer>
                          <LabelMobile>{t('recievedQty')}</LabelMobile>

                          <FixedInputField>
                            {amountReceived} &nbsp;
                            {measureUnit}
                          </FixedInputField>
                        </SingleFieldContainer>
                      )}

                      <SingleFieldContainer>
                        <LabelMobile>{t('price')}</LabelMobile>

                        {get(lines, `[${index}].priceUnit`) ? (
                          showPrice ? (
                            <FixedInputField>
                              <NumberFormat value={priceUnit} suffix={`€/${measureUnit}`} />
                            </FixedInputField>
                          ) : null
                        ) : (
                          <CountInput
                            value={priceUnit}
                            unit={`€/${measureUnit}`}
                            onChange={e => handleChange(index, 'priceUnit', e)}
                            format='float'
                            paddingLeft='0'
                            error={isSubmit && get(errors, `receptionlines[${index}].priceUnit`)}
                            innerRef={element => {
                              if (element) {
                                itemEls.current[lineIndex] = itemEls.current[lineIndex] || {}
                                itemEls.current[lineIndex].priceUnit = element
                                itemEls.current[lineIndex].index = lineIndex
                              }
                            }}
                            onPrevious={() => handlePreviuos(reference, 'priceUnit', lineIndex)}
                            onNext={() => handleNext(reference, 'priceUnit', lineIndex)}
                            min={amount < 0 ? null : 0}
                          />
                        )}
                      </SingleFieldContainer>
                      <SingleFieldContainer>
                        <LabelMobile>{t('sum')}</LabelMobile>

                        {showPrice || get(lines, `[${index}].price`) === 0 ? (
                          <FixedInputField>
                            <NumberFormat value={priceUnit * amountReceived} />
                          </FixedInputField>
                        ) : null}
                      </SingleFieldContainer>
                    </ProductContainer>
                  )
                })}
            </ProductsContainer>
          </SingleRequestedContainer>
        )}
      </RequestedContainer>
      <ActionContainer>
        <DateSelectorContainer>
          <DateContainer>
            <DateLabel>{t('receptionDate')}</DateLabel>
            <DatesContainer>
              {limitDate && moment(get(deliveryForm, 'receptionDate')) < moment(limitDate.limitReceptionDate) ? (
                <WrongDate>{`${moment(limitDate.limitReceptionDate).format('DD/MM/YYYY')}`}</WrongDate>
              ) : null}
              <DateSelected>
                {get(deliveryForm, 'receptionDate')
                  ? moment(get(deliveryForm, 'receptionDate')).format('DD/MM/YYYY')
                  : t('toBeFilled')}
              </DateSelected>
            </DatesContainer>
            {limitDate && moment(get(deliveryForm, 'receptionDate')) < moment(limitDate.limitReceptionDate) ? (
              <WarningDateContainer>{t('invalidSelectDate')}</WarningDateContainer>
            ) : null}
          </DateContainer>
          <ButtonContainer>
            <SelectDateButton onClick={() => setShowCalendar(!showCalendar)}>{t('select')}</SelectDateButton>
            {showCalendar && limitDate && (
              <StyledCalendar
                formatShortWeekday={(locale, date) => moment(date).format('ddd')[0]}
                formatMonthYear={(locale, date) => moment(date).format('MMMM YYYY')}
                value={get(deliveryForm, 'receptionDate')}
                selectRange={false}
                minDate={moment(limitDate.limitReceptionDate).toDate()}
                maxDate={moment().toDate()}
                onChange={value => {
                  setShowCalendar(false)
                  handleDeliveryChange('receptionDate', value)
                }}
              />
            )}
          </ButtonContainer>
        </DateSelectorContainer>
        <TextInputContainer>
          <TextInput
            placeholder={t('fillNoteNumber')}
            value={get(deliveryForm, 'deliveryNote')}
            onChange={e => handleDeliveryChange('deliveryNote', e.target.value)}
            background={colors.white}
            border='1px solid black'
            height='56px'
            maxLength={NOTE_MAX_LENGHT}
          />
        </TextInputContainer>
        <FinishButtonContainer>
          <FinishButton onClick={handleSubmit} disabled={disableCompleteButton || submitLoading}>
            {disableCompleteButton ? (
              <ButtonLiteral>
                {submitLoading && <CircularProgressStyled size={16} />}
                {t('fillAllOut')}
              </ButtonLiteral>
            ) : (
              <ButtonLiteral>
                {submitLoading && <CircularProgressStyled size={16} />}
                {t('confirmOrderReception')}
              </ButtonLiteral>
            )}

            <TotalsContainer>
              {showPrice ? (
                <TotalsTotalPrice disabled={disableCompleteButton || submitLoading}>
                  <NumberFormat value={totalPrice} />
                </TotalsTotalPrice>
              ) : null}
              <TotalsTotalItems>{lines.length}</TotalsTotalItems>
            </TotalsContainer>
          </FinishButton>
        </FinishButtonContainer>
      </ActionContainer>
      <ModalLoading showModal={submitLoading} />
      <ModalError
        showModal={showSubmitErrorModal}
        title={t('deliveryErrorTitle')}
        color='red'
        message={t(messageErrorModal)}
        height='300px'
        onConfirm={() => setShowSubmitErrorModal(false)}
        setShowModal={() => setShowSubmitErrorModal(false)}
      />
      <NotServedInfo
        showModal={showNotServedModal}
        setShowModal={setShowNotServedModal}
        width='700px'
        height='300px'
        onConfirm={handleOpenNC}
      />
    </Container>
  )
}

const RequestsLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width={1400}
      height={232}
      viewBox='0 0 1400 232'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='18' y='60' rx='0' ry='0' width='700' height='10' />
      <rect x='18' y='18' rx='0' ry='0' width='150' height='10' />
      <rect x='18' y='110' rx='0' ry='0' width='100' height='10' />
      <rect x='400' y='110' rx='0' ry='0' width='100' height='10' />
      <rect x='18' y='142' rx='0' ry='0' width='700' height='10' />
    </ContentLoader>
  )
}

export default Delivery
