import React, { useCallback, useMemo } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Box, Button, CircularProgress, Divider } from '@material-ui/core'
import classNames from 'classnames'
import { TextInput, NumberFormat } from 'Components/atoms'
import BackButton from 'Components/atoms/backButton'
import { ReactComponent as CartIcon } from 'Assets/Icons/Cart.svg'
import { ReactComponent as CompassCenterIcon } from 'Assets/Icons/CompassCenter.svg'
import SearchIcon from 'Assets/Icons/Search'
import { ReactComponent as MenuBurgerIcon } from 'Assets/Icons/MenuBurger.svg'
import { useTranslation } from 'react-i18next'
import { needsApprovalMode } from 'Redux/approver/selectors'
import colors from 'Assets/colors'
import isEmpty from 'lodash/isEmpty'
import routes from 'Config/routes'
import { useHistory } from 'react-router-dom'
import { ROLES } from 'Config/constants'
import find from 'lodash/find'
import { selectTicketsPendingListCount } from 'Redux/ticket/selectors'
import { isUserRoleApprover } from 'Components/utils/roles'
import { selectConfig } from 'Redux/auth/utils'
import { AuthActions, AuthTypes } from 'Redux/auth'
import { PurchaseActions } from 'Redux/purchases'
import _ from 'lodash'
import { useMsal } from '@azure/msal-react'
import { logoutRequest } from 'Config/mfa-config'
import { ReactComponent as SwitchOnIcon } from 'Assets/Icons/SwitchOn.svg'
import Drawer from '@material-ui/core/Drawer'
import { ModalCenter } from 'Components/molecules'

import { OrderPRBullet, OrderPRCounter } from '../navBar/styled'

import { RelativeContainer, ResultsContainer, Result, StyledText, Type, TotalItems } from './styled'

import { ApprovedCartButton } from '.'

const HeaderMobile = ({
  searchRef,
  inputRef,
  query,
  setQuery,
  focused,
  onFocusInput,
  onKeyDownInput,
  displayAutocomplete,
  hoveredResult,
  onClickSearch,
  refs,
  showCartButton,
  isDisabledCartButton,
  setShowDrawer,
  hasSomethingInCart,
  showPrice,
  fullTotal,
  productQuantity,
  multicenterView,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [openMenu, setOpenMenu] = React.useState(false)
  const [showCenterModal, setShowCenterModal] = React.useState(false)

  const center = useSelector(({ auth }) => auth.selectedCenter)
  const needsApproval = useSelector(needsApprovalMode)
  const results = useSelector(state => state.purchase.mainSearch)
  const numProviderPendingOrders = useSelector(state => state.purchase.numProviderPendingOrders)
  const deliveryErrors = useSelector(state => state.delivery.deliveryErrors)
  const failedReceptions = useSelector(state => state.purchase.failedReceptions)
  const user = useSelector(state => state.auth.user)
  const numPendingTicketsCount = useSelector(selectTicketsPendingListCount)
  const pendingApprovalCount = useSelector(state => state.carts.pendingApprovalCount || 0)
  const features = useSelector(selectConfig)
  const hasOnlyOneCenter = useSelector(({ auth }) => auth.hasOnlyOneCenter)
  const categoriesHabitual = useSelector(state => state.purchase.categoriesHabitual)

  const isApprover = isUserRoleApprover(user)
  const isMulticenter = features?.multicenter
  const role = find(user.roles, elem => elem !== 'ROLE_USER')
  const { instance } = useMsal()

  const haveNotifications = useMemo(
    () =>
      numPendingTicketsCount > 0 ||
      numProviderPendingOrders > 0 ||
      !isEmpty(deliveryErrors) ||
      !isEmpty(failedReceptions) ||
      pendingApprovalCount > 0,
    [numPendingTicketsCount, numProviderPendingOrders, deliveryErrors, failedReceptions, pendingApprovalCount]
  )

  const goToMulticenter = useCallback(() => {
    dispatch(AuthActions.setCenter({}))
    history.push(routes.multicenterOrders)
  }, [history, dispatch])

  const goToCategories = () => {
    if (!_.get(history, 'location.pathname', '').includes('categories')) {
      dispatch(PurchaseActions.setShowOnlyOneProducts(false))
      dispatch(PurchaseActions.setSelectedCategories({}))
      history.push(routes.categories)
    }
    setOpenMenu(false)
  }

  const goToProviders = () => {
    dispatch(PurchaseActions.setSelectedCategories({}))
    history.push(routes.providers)
    setOpenMenu(false)
  }

  const goToCategoriesHabitual = () => {
    dispatch(PurchaseActions.setSelectedCategoriesHabitual({}))

    const firstCategory = _.get(categoriesHabitual, '0.id', 'A')
    history.push(routes.AddCategoryHabitual(firstCategory))
    setOpenMenu(false)
  }

  const goToLists = () => {
    history.push(routes.lists)
    setOpenMenu(false)
  }

  const logout = async () => {
    try {
      dispatch({
        type: AuthTypes.LOGOUT_USER,
      })
      if (user.msal) {
        await instance.logout(logoutRequest)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const MenuItemContainer = ({ children, onClick }) => (
    <Box
      display='flex'
      justifyContent='flex-start'
      alignItems='center'
      py={2}
      px={2}
      sx={{ columnGap: '0.5rem', cursor: 'pointer', fontSize: '0.875rem' }}
      onClick={onClick}
    >
      {children}
    </Box>
  )
  MenuItemContainer.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  const hasCenter = Object.values(center).length > 0

  return (
    <>
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <Box display='flex' justifyContent='space-between' alignItems='center' px={1} py={0.5} marginLeft='40px'>
          <BackButton cMarginRight='10px' cTop='0px' />
          {isApprover && multicenterView ? (
            <Button
              onClick={() => setShowCenterModal(true)}
              startIcon={<CompassCenterIcon />}
              color='inherit'
              size='small'
            >
              {t('summary.selectCenter')}
            </Button>
          ) : (
            <Button
              onClick={() => setShowCenterModal(true)}
              startIcon={<CompassCenterIcon />}
              color='inherit'
              size='small'
            >
              {hasCenter ? `${center.name} - ${center.id}` : t('summary.selectCenter')}
            </Button>
          )}
          <Box
            display='flex'
            alignItems='center'
            sx={{ columnGap: '0.5rem', cursor: 'pointer' }}
            onClick={() => setOpenMenu(!openMenu)}
          >
            {haveNotifications && <OrderPRBullet />}
            <Box p={1}>
              <MenuBurgerIcon className='icon' />
            </Box>
          </Box>
        </Box>

        {multicenterView === false && (
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            px={1}
            py={0.5}
            sx={{ columnGap: '0.5rem' }}
          >
            <Box ref={searchRef} flex={1}>
              <TextInput
                placeholder={t('searchPlaceholderCategory')}
                topIcon='0.9rem'
                width='100%'
                Icon={props => <SearchIcon fill={focused ? colors.secondary : colors.black} {...props} />}
                margin='0'
                value={query}
                onChange={e => setQuery(e.target.value)}
                onFocus={onFocusInput}
                onKeyDown={onKeyDownInput}
                borderRadius={focused ? '8px 8px 0 0' : '8px'}
                refProp={inputRef}
                background='#F4F1F1'
                name='search'
                height='3rem'
                padding='1rem 3rem 1rem 1rem'
              />
              <RelativeContainer display={displayAutocomplete} style={{ margin: 0 }}>
                <ResultsContainer>
                  {results.map(({ content, key, type, ...rest }, index) => (
                    <Result
                      className={classNames({ selected: index === hoveredResult })}
                      key={`${key}${index}`}
                      onClick={() => onClickSearch({ content, key, type, ...rest })}
                      ref={refs[index]}
                      data-cy={`search-${index}`}
                    >
                      <StyledText padding='8px 0'>{content}</StyledText>
                      <Type>{t(type)}</Type>
                    </Result>
                  ))}
                </ResultsContainer>
              </RelativeContainer>
            </Box>
            {showCartButton && (
              <Button
                disabled={isDisabledCartButton}
                startIcon={isDisabledCartButton ? <CircularProgress color='inherit' size={20} /> : <CartIcon />}
                color='inherit'
                onClick={() => setShowDrawer(true)}
                style={{ textTransform: 'none', backgroundColor: colors.cart, height: '3rem', borderRadius: '8px' }}
                size='large'
              >
                {hasSomethingInCart && showPrice ? <NumberFormat value={fullTotal} /> : t('cart')}
                {hasSomethingInCart ? <TotalItems>{productQuantity}</TotalItems> : null}
              </Button>
            )}
            {needsApproval && <ApprovedCartButton />}
          </Box>
        )}

        <Drawer anchor='top' open={openMenu} onClose={() => setOpenMenu(false)}>
          <Box display='flex' flexDirection='column' justifyContent='space-between'>
            {hasCenter && (
              <MenuItemContainer
                onClick={() => {
                  history.push(routes.historic)
                }}
              >
                {t('history')}
                {numProviderPendingOrders || !isEmpty(deliveryErrors) || !isEmpty(failedReceptions) ? (
                  <OrderPRBullet />
                ) : null}
              </MenuItemContainer>
            )}

            {(hasCenter || multicenterView) && ROLES.ROLE_STEF !== ROLES[role] && (
              <MenuItemContainer
                onClick={() => {
                  history.push(routes.ticketingList)
                }}
              >
                {parseInt(numPendingTicketsCount, 10) > 0 && <OrderPRCounter>{numPendingTicketsCount}</OrderPRCounter>}

                {t('complaints')}
              </MenuItemContainer>
            )}

            {hasCenter && center?.requiresApproval && (
              <MenuItemContainer
                onClick={() => {
                  history.push(routes.carts)
                }}
              >
                {parseInt(pendingApprovalCount, 10) > 0 && <OrderPRCounter>{pendingApprovalCount}</OrderPRCounter>}

                {t('requests')}
              </MenuItemContainer>
            )}

            {hasCenter && isApprover && (
              <MenuItemContainer
                onClick={() => {
                  history.push(routes.approverCarts)
                }}
              >
                {parseInt(pendingApprovalCount, 10) > 0 && <OrderPRCounter>{pendingApprovalCount}</OrderPRCounter>}

                {t('approveRequests')}
              </MenuItemContainer>
            )}

            {hasCenter && isMulticenter && !hasOnlyOneCenter && (
              <MenuItemContainer onClick={goToMulticenter}>{t('myCenters')}</MenuItemContainer>
            )}

            {hasCenter && (
              <MenuItemContainer onClick={() => history.push(routes.help)}>{t('helpSection')}</MenuItemContainer>
            )}

            {isApprover && !hasCenter && (
              <MenuItemContainer
                onClick={() => {
                  history.push(routes.multicenterOrders)
                }}
              >
                {t('orders')}
              </MenuItemContainer>
            )}

            {isApprover && !hasCenter && (
              <MenuItemContainer
                onClick={() => {
                  history.push(routes.approverCarts)
                }}
              >
                {t('approval')}
              </MenuItemContainer>
            )}

            <Divider />

            {multicenterView === false && (
              <>
                <MenuItemContainer onClick={goToCategories}>{t('categories')}</MenuItemContainer>
                <MenuItemContainer onClick={goToProviders}>{t('providers')}</MenuItemContainer>
                {features?.habituals && (
                  <MenuItemContainer onClick={goToCategoriesHabitual}>{t('habitualCats')}</MenuItemContainer>
                )}
                {features?.shopping_lists && <MenuItemContainer onClick={goToLists}>{t('lists')}</MenuItemContainer>}
                <Divider />
              </>
            )}

            <MenuItemContainer onClick={logout}>
              <SwitchOnIcon />
              <span>{user.username}</span>
              {ROLES[role] && `- ${(<span>{ROLES[role]}</span>)}`}
            </MenuItemContainer>
          </Box>
        </Drawer>
      </Box>
      <ModalCenter showModal={showCenterModal} setShowModal={setShowCenterModal} />
    </>
  )
}
HeaderMobile.defaultProps = {
  multicenterView: false,
}

HeaderMobile.propTypes = {
  searchRef: PropTypes.object.isRequired,
  inputRef: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  focused: PropTypes.bool.isRequired,
  onFocusInput: PropTypes.func.isRequired,
  onKeyDownInput: PropTypes.func.isRequired,
  displayAutocomplete: PropTypes.string.isRequired,
  hoveredResult: PropTypes.number.isRequired,
  onClickSearch: PropTypes.func.isRequired,
  refs: PropTypes.arrayOf(PropTypes.object).isRequired,
  showCartButton: PropTypes.bool.isRequired,
  isDisabledCartButton: PropTypes.bool.isRequired,
  setShowDrawer: PropTypes.func.isRequired,
  hasSomethingInCart: PropTypes.bool.isRequired,
  showPrice: PropTypes.bool.isRequired,
  fullTotal: PropTypes.number.isRequired,
  productQuantity: PropTypes.number.isRequired,
  multicenterView: PropTypes.bool,
}

export default HeaderMobile
