import styled, { css } from 'styled-components'
import colors from 'Assets/colors'
import { makeStyles } from '@material-ui/core/styles'
import { ORDER_STATUS, ROLES } from 'Config/constants'
import { media } from 'Config/styled'

export const useStyles = makeStyles(() => ({
  seeQA: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'none',
    color: colors.white,
    transition: 'all 0.2s',
    border: `1px solid ${colors.secondary}`,
    height: '40px',
    backgroundColor: colors.secondary,
    '&:hover': {
      opacity: 0.8,
      color: colors.white,
      transform: 'translateY(-2px)',
      backgroundColor: colors.secondary,
      boxShadow: '0 3px 5px rgba(51, 51, 51, 0.2)',
    },
    '&:active': {
      transform: 'translate(-1px)',
      boxShadow: '0 1px 3px rgba(51, 51, 51, 0.4)',
      backgroundColor: colors.secondary,
    },
    '& svg': {
      fill: 'white',
      '& path': {
        fill: 'white',
      },
    },
  },
}))

export const Price = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
  color: ${colors.gray40l};
`

export const PriceKG = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
`

export const ProductsContainer = styled.div`
  background-color: #fafafa;
  padding: 0 16px;
`

export const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 43% 17% 17% 23%;
  padding: 16px 0;
  ${media('lg')} {
    display: none;
  }
`

export const TableHeaderItem = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  &:first-child {
    padding-left: 16px;
  }
`

export const SingleProductRow = styled.div`
  background: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.24);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-columns: ${({ withPrice }) => (withPrice ? '43% 17% 17% 23%' : '43% 34% 23%')};
  margin-bottom: 16px;
  height: 80px;
  ${media('lg')} {
    grid-template-columns: 1fr;
    height: auto;
  }
`

export const SingleProductRowCL = styled.div`
  background: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.24);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 43% 17% 17% 23%;
  margin-bottom: 16px;
  margin-top: 16px;
  height: 80px;
`

export const SingleProductNameHelper = styled.div`
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 78px;
  ${media('lg')} {
    height: auto;
    margin-right: unset;
    padding: 1rem 1rem 0;
  }
`

export const SingleProductNameLabelMobile = styled.div`
  display: none;
  ${media('lg')} {
    display: block;
    font-size: 0.8rem;
    text-transform: none;
    font-weight: 400;
    text-transform: uppercase;
  }
`
export const SingleProductTotalQtyLabelMobile = styled.div`
  display: none;
  ${media('lg')} {
    display: block;
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
  }
`

export const SingleProductName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  text-transform: uppercase;
  padding: 0 16px;
  ${media('lg')} {
    padding: 0;
  }
`

export const SingleProductPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 16px 0;
  height: 78px;
  ${media('lg')} {
    height: auto;
    padding: 1rem 1rem 0;
  }
`

export const SingleProductQtyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 16px 0;
  height: 78px;
  ${media('lg')} {
    height: auto;
    padding: 1rem 1rem 0;
  }
`

export const SingleProductTotalPrice = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${colors.black};
`

export const SingleProductTotalPriceSplit = styled.div`
  display: flex;
`

export const SingleProductTotalQty = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${colors.black};
`

export const MinAndMulti = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
`

export const SingleProductButtonContainer = styled.div`
  display: flex;
  padding: 12px 16px;
  height: 78px;
  ${media('lg')} {
    height: auto;
    padding: 1rem;
  }
`

export const AddCartButton = styled.div`
  width: 100%;
  background-color: ${colors.secondary};
  padding: 1rem;
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const ButtonLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
`

export const RequestProviderInfoContainer = styled.div`
  background: ${colors.gray98b};
  padding: 16px;
  display: flex;
  justify-content: space-between;
  ${media('lg')} {
    flex-direction: column;
  }
`

export const RequestProviderInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  > * {
    margin-right: 8px;
  }
  ${media('lg')} {
    flex-direction: column;
    row-gap: 0.5rem;
  }
`

export const RequestProviderName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
`

export const RequestProviderDeliveryDate = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.black};
  background: ${colors.cart};
  border-radius: 8px;
  padding: 0 8px;
`

export const RequestProviderOrderNumber = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.gray40l};
`

export const ProviderDetail = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  display: flex;
  width: 100%;
  max-width: 30rem;
  justify-content: flex-end;
  ${media('lg')} {
    justify-content: flex-start;
    flex-direction: column;
  }
`

export const TotalPrice = styled.span`
  margin-right: 16px;
  width: 100%;
  max-width: 10rem;
  text-align: right;
  ${media('lg')} {
    text-align: left;
  }
`

export const TotalProductLines = styled.span`
  width: 100%;
  max-width: 10rem;
  ${media('lg')} {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    &:empty {
      display: none;
    }
  }
`

export const RequestOrderContainer = styled.div`
  background: ${colors.white};
  border-top: 1px solid rgba(51, 51, 51, 0.24);
  border-bottom: ${props => (props.openProducts ? '1px solid rgba(51, 51, 51, 0.24)' : 'none')};
  border-bottom-left-radius: ${props => (props.openProducts ? '0' : '8px')};
  border-bottom-right-radius: ${props => (props.openProducts ? '0' : '8px')};
  display: grid;
  grid-template-columns: 72% 28%;
  height: 80px;
  ${media('lg')} {
    grid-template-columns: 1fr;
    height: auto;
    order: 100;
  }
`

export const RequestRepeatOrderContainer = styled.div`
  display: flex;
  padding: 0 18px;
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  ${media('lg')} {
    flex-direction: column;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
`

export const RequestActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
`

export const RequestRepeatOrder = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 8px;

  &:hover {
    svg {
      path {
        fill: ${colors.secondary};
      }
    }

    button {
      color: ${colors.secondary};
    }
  }

  svg {
    margin-right: 18px;
    width: 24px;
    path {
      transition: all 0.2s;
    }
  }
  button {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.black};
    margin-left: -5px;
    cursor: pointer;
    transition: all 0.2s;
  }
`

export const RequestActionOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  border: ${props =>
    props.stateProp === ORDER_STATUS.PR && props.role !== ROLES.ROLE_STEF ? '1px solid red' : 'none'};
  justify-content: center;
  border-bottom-right-radius: ${props => (props.openProducts ? '0' : '8px')};
  height: 100%;
  ${media('lg')} {
    padding-bottom: 0.5rem;
  }
`

export const RequestActionOrder = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  color: ${props => (props.stateProp === ORDER_STATUS.PR ? colors.red : null)};
  color: ${props => (props.stateProp === ORDER_STATUS.RC ? colors.secondary : null)};
  color: ${props => (props.stateProp === ORDER_STATUS.OT ? colors.gray40l : null)};
  margin-bottom: ${props => (props.stateProp === ORDER_STATUS.OT ? '0' : '8px')};
  svg {
    margin-right: 14px;
  }
`

export const RequestActionOrderDisabled = styled(RequestActionOrder)`
  color: ${props => (props.stateProp === ORDER_STATUS.PR ? '#ddd' : null)};
`

export const RequestActionOrderContainerDisabled = styled(RequestActionOrderContainer)`
  border: ${props => (props.stateProp === ORDER_STATUS.PR ? '#ddd' : 'none')};
`

export const HelpText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
  margin-left: 38px;

  ${props =>
    props.bold &&
    css`
      color: ${colors.blue};
      font-weight: 700;
    `}
`

export const NotAvailable = styled.div`
  background: ${colors.gray40l};
  border-radius: 8px;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NotAvailableLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
`

export const ShowProductsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  ${media('lg')} {
    margin-left: 0;
    justify-content: flex-start;
  }
`

export const ShowMoreCopy = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  width: 11rem;
  ${media('lg')} {
    width: 100%;
    margin-left: 0.2rem;
  }
`

export const ShowLessCopy = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.secondary};
  width: 11rem;
  ${media('lg')} {
    width: 100%;
    margin-left: 0.2rem;
  }
`

export const RequestActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`
