import styled from 'styled-components'
import colors from 'Assets/colors'
import { ORDER_STATUS } from 'Config/constants'
import Checkbox from '@material-ui/core/Checkbox'
import { media } from 'Config/styled'

export const Container = styled.div`
  padding: 2.5rem 1.25rem 9rem;
  height: calc(100vh - 168px);
  overflow: auto;
  margin-top: 2px;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 1rem;
`

export const SubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 24px;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
`

export const StyledCheckbox = styled(Checkbox)`
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
  &:hover {
    background-color: ${colors.white} !important;
  }
  &.Mui-checked {
    svg {
      rect {
        fill: ${colors.secondary};
        stroke: none;
        stroke-width: 0;
      }
    }
  }
  svg {
    rect {
      fill: none;
      stroke: #666666;
      stroke-width: 0.5;
    }
  }
`

export const Price = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
  color: ${colors.gray40l};
`

export const PriceKG = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
`

export const ProductsContainer = styled.div`
  background-color: #fafafa;
  padding: 0 16px 16px;

  ${media('lg')} {
    padding: 1rem;
  }
`

export const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 7% 27% 13% 13% 16% 12% 13%;
  padding: 16px 0;

  ${media('lg')} {
    display: none;
  }
`

export const TableHeaderItem = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  &:first-child {
    padding-left: 16px;
  }
`

export const SingleProductRow = styled.div`
  background: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.24);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 7% 27% 13% 13% 16% 12% 13%;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }

  ${media('lg')} {
    grid-template-columns: 1fr;
    row-gap: 0.8rem;
    padding: 0.2rem 1rem 1rem;
  }
`

export const SingleProductNameHelper = styled.div`
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media('lg')} {
    border-right: none;
    margin-right: 0;
    display: grid;
    grid-template-columns: 3rem 1fr;
  }
`

export const SingleProductName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  text-transform: uppercase;
  padding-right: 20px;

  ${media('lg')} {
    padding-right: 0;
  }
`

export const SingleProductPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 16px 0;

  ${media('lg')} {
    padding: 0;
  }
`

export const SingleProductQtyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 16px 0;

  ${media('lg')} {
    padding: 0;
  }
`

export const SingleProductTotalPrice = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${colors.black};
`

export const SingleProductTotalPriceSplit = styled.div`
  display: flex;
`

export const SingleProductTotalQty = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${colors.black};
`

export const RequestProviderInfoContainer = styled.div`
  background: ${colors.gray98b};
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);

  ${media('lg')} {
    flex-direction: column;
  }
`

export const RequestProviderInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  > * {
    margin-right: 8px;
  }

  ${media('lg')} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const RequestProviderName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
`

export const RequestProviderDeliveryDate = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.black};
  background: ${colors.cart};
  border-radius: 8px;
  padding: 0 8px;
`

export const RequestProviderOrderNumber = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.gray40l};
`

export const ProviderDetail = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
`

export const RequestOrderContainer = styled.div`
  background: ${colors.white};
  border-top: 1px solid rgba(51, 51, 51, 0.24);
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);
  display: grid;
  grid-template-columns: 77% 23%;
`

export const RequestRepeatOrderContainer = styled.div`
  display: flex;
  padding: 18px;
  border-right: 1px solid rgba(51, 51, 51, 0.24);
`

export const RequestActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
`

export const RequestRepeatOrder = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 8px;

  &:hover {
    svg {
      path {
        fill: ${colors.secondary};
      }
    }

    button {
      color: ${colors.secondary};
    }
  }

  svg {
    margin-right: 18px;
    width: 24px;
    path {
      transition: all 0.2s;
    }
  }
  button {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.black};
    margin-left: -5px;
    cursor: pointer;
    transition: all 0.2s;
  }
`

export const RequestActionOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
  border: ${props => (props.stateProp === ORDER_STATUS.PR ? '1px solid red' : 'none')};
  justify-content: center;
`

export const RequestActionOrder = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  color: ${props => (props.stateProp === ORDER_STATUS.PR ? colors.red : null)};
  color: ${props => (props.stateProp === ORDER_STATUS.RC ? colors.secondary : null)};
  color: ${props => (props.stateProp === ORDER_STATUS.OT ? colors.gray40l : null)};
  margin-bottom: ${props => (props.stateProp === ORDER_STATUS.OT ? '0' : '8px')};
  svg {
    margin-right: 14px;
  }
`

export const HelpText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
  margin-left: 38px;
`

export const NotAvailable = styled.div`
  background: ${colors.gray40l};
  border-radius: 8px;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NotAvailableLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
`
export const RequestedContainer = styled.div`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  margin: 12px 0;
`

export const RequestHeader = styled.div`
  background: ${colors.black};
  color: ${colors.white};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
`

export const RequestHeaderInfo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`

export const TextInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 40px;

  ${media('lg')} {
    padding-right: 0;
    align-items: flex-start;
    row-gap: 0.3rem;
    flex-direction: column;
  }
`

export const ActionBoxContainer = styled.div`
  background: ${colors.gray98b};
  border-top: 1px solid rgba(51, 51, 51, 0.24);
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 104px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  ${media('lg')} {
    flex-direction: column;
    height: auto;
    padding: 0.5rem 1rem;
  }
`

export const ActionBoxInfoContainer = styled.div``

export const ActionBoxInfoTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
  margin-bottom: 8px;
`

export const ActionBoxInfo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};

  ${media('lg')} {
    padding-bottom: 0.5rem;
  }
`

export const ActionBoxActionContainer = styled.div``

export const ActionButton = styled.div`
  position: relative;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  background-color: ${props => (props.disabled ? colors.gray40l : colors.secondary)};
  color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  height: 56px;
  padding: 16px;
  display: flex;
  min-width: 440px;
  justify-content: space-between;

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }

  ${media('lg')} {
    width: 100%;
    min-width: unset;
    max-width: 440px;
  }
`

export const ButtonLiteral = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const LabelMobile = styled.div`
  display: none;

  ${media('lg')} {
    display: block;
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
  }
`
