import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ContentLoader from 'react-content-loader'
import { ReactComponent as CarroError } from 'Assets/Icons/CarroError.svg'
import { PurchaseTypes } from 'Redux/purchases'
import { useDebounce } from 'Components/utils/customHooks'
import { NumberFormat, ProductButton } from 'Components/atoms'
import { RepeatOrderBar, CartDrawer } from 'Components/organisms'
import { debounceAmount } from 'Config/constants'
import useProductButton from 'Components/utils/useProductButton'

import {
  Row,
  HorizontalProvider,
  SVGRow,
  ErrorTitle,
  ErrorMessage,
  HorizontalProductContainer,
  HorizontalProviderContainer,
  ProviderRow,
  HorizontalTitleSection,
  HorizontalTitle,
  HorizontalText,
  SingleProductRow,
  SingleProductNameHelper,
  SingleProductPriceContainer,
  SingleProductQtyContainer,
  SingleProductTotalPrice,
  SingleProductTotalPriceSplit,
  SingleProductTotalQty,
  SingleProductButtonContainer,
  MinAndMulti,
  Price,
  PriceKG,
  NotAvailableLiteral,
  NotAvailable,
  SingleProductName,
  HorizontalTitleTotal,
  SelectText,
  LabelMobile,
} from './styled'

const OrderFromOtherView = ({
  totalByProvider,
  productsToShow,
  isLoadingPreviusOrder,
  errorPreviusOrder,
  requestId,
  provider,
}) => {
  const { t } = useTranslation()

  const selectedCenter = useSelector(state => state.auth.selectedCenter)
  const cart = useSelector(state => state.purchase.cart)
  const cartInitiated = useSelector(state => state.purchase.cartInitiated)

  const dispatch = useDispatch()

  const { onNext, onPreviuos, setRef } = useProductButton()

  const [showDrawer, setShowDrawer] = useState(false)
  const debouncedCart = useDebounce(cart, debounceAmount)

  useEffect(() => {
    dispatch({ type: PurchaseTypes.FETCH_CATEGORIES })
    dispatch({ type: PurchaseTypes.FETCH_CART })
    dispatch({ type: PurchaseTypes.FETCH_BUDGET })
    dispatch({ type: PurchaseTypes.FETCH_CATEGORIES_HABITUAL })
  }, [dispatch, selectedCenter])

  useEffect(() => {
    if (!cartInitiated) {
      return
    }

    dispatch({ type: PurchaseTypes.UPDATE_CART, debouncedCart })

    /* eslint-disable-next-line */
  }, [debouncedCart, dispatch])

  const getProductText = () => (productsToShow.length === 1 ? t('product') : t('products'))

  return (
    <>
      <RepeatOrderBar setShowDrawer={setShowDrawer} requestId={requestId} />
      {isLoadingPreviusOrder ? (
        <ProductCardLoader />
      ) : (
        <HorizontalProductContainer name='containerElement' id='containerElement'>
          {errorPreviusOrder && <ErrorPreviusOrderinfo />}
          {!errorPreviusOrder && (
            <>
              <SelectText>{t('selectRequest')}</SelectText>
              <HorizontalProviderContainer>
                <ProviderRow>
                  <HorizontalProvider>{provider}</HorizontalProvider>
                  <Row className='hoverable' justifyContent='flex-end'>
                    <HorizontalText>
                      <NumberFormat value={totalByProvider} />
                    </HorizontalText>
                    <HorizontalText>{`${productsToShow.length} ${getProductText()}`}</HorizontalText>
                  </Row>
                </ProviderRow>
                <HorizontalTitleSection>
                  <HorizontalTitle>{t('product')}</HorizontalTitle>
                  <HorizontalTitleTotal>{t('total')}</HorizontalTitleTotal>
                  <HorizontalTitleTotal>{t('quantity')}</HorizontalTitleTotal>
                </HorizontalTitleSection>
                {productsToShow.map(product => {
                  const { name, price, measureUnit, amount, priceUnit, product: productData } = product
                  return (
                    <SingleProductRow withPrice>
                      <SingleProductNameHelper>
                        <LabelMobile>{t('product')}</LabelMobile>
                        <SingleProductName>{name}</SingleProductName>
                      </SingleProductNameHelper>

                      <SingleProductPriceContainer>
                        <LabelMobile>{t('total')}</LabelMobile>
                        <SingleProductTotalPrice>
                          <NumberFormat value={price} />
                        </SingleProductTotalPrice>
                        <SingleProductTotalPriceSplit>
                          <Price>
                            <NumberFormat value={priceUnit} suffix={`€/${measureUnit}`} />
                          </Price>
                          <PriceKG />
                        </SingleProductTotalPriceSplit>
                      </SingleProductPriceContainer>

                      <SingleProductQtyContainer>
                        <LabelMobile>{t('quantity')}</LabelMobile>
                        <SingleProductTotalQty>
                          {amount} {measureUnit}
                        </SingleProductTotalQty>
                        <div style={{ display: 'flex' }}>
                          <MinAndMulti>Min: 1 / Multi: 1</MinAndMulti>
                        </div>
                      </SingleProductQtyContainer>
                      <SingleProductButtonContainer>
                        {productData ? (
                          <ProductButton
                            {...productData}
                            innerRef={element => setRef(element, productData.id)}
                            onPrevious={onPreviuos}
                            onNext={onNext}
                          />
                        ) : (
                          <NotAvailable>
                            <NotAvailableLiteral>{t('notAvailable')}</NotAvailableLiteral>
                          </NotAvailable>
                        )}
                      </SingleProductButtonContainer>
                    </SingleProductRow>
                  )
                })}
              </HorizontalProviderContainer>
            </>
          )}
        </HorizontalProductContainer>
      )}
      <CartDrawer showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
    </>
  )
}

OrderFromOtherView.defaultProps = {
  totalByProvider: 0,
  productsToShow: [],
  isLoadingPreviusOrder: false,
  errorPreviusOrder: false,
  requestId: 0,
  provider: '',
}

OrderFromOtherView.propTypes = {
  totalByProvider: PropTypes.number,
  productsToShow: PropTypes.array,
  isLoadingPreviusOrder: PropTypes.bool,
  errorPreviusOrder: PropTypes.bool,
  requestId: PropTypes.number,
  provider: PropTypes.string,
}

const ProductCardLoader = () => {
  const { t } = useTranslation()

  return (
    <ContentLoader
      speed={2}
      width={1400}
      height={140}
      viewBox='0 0 1400 140'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      title={t('loading')}
    >
      <rect x='18' y='60' rx='0' ry='0' width='100%' height='10' />
      <rect x='18' y='18' rx='0' ry='0' width='150' height='10' />
      <rect x='18' y='90' rx='0' ry='0' width='100' height='10' />
    </ContentLoader>
  )
}

const ErrorPreviusOrderinfo = () => {
  const { t } = useTranslation()

  return (
    <div style={{ padding: '1rem' }}>
      <SVGRow>
        <CarroError />
      </SVGRow>
      <ErrorTitle>{t('errorPreviusOrderTitle')}</ErrorTitle>
      <ErrorMessage>{t('errorPreviusOrderSubtitle')}</ErrorMessage>
    </div>
  )
}

export default OrderFromOtherView
