import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import colors from 'Assets/colors'
import { media } from 'Config/styled'

export const ModalContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0%;
  left: ${props => (props.left ? props.left : '0%')};
  right: ${props => (props.right ? props.right : 'unset')};
  z-index: 200;
  background-color: ${colors.white};
  padding: ${props => (props.range === 'false' ? '0' : '24px 40px')};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  width: ${props => (props.range === 'false' ? 'auto' : '800px')};
  display: ${props => (props.range === 'false' ? 'block' : 'grid')};
  grid-template-columns: 48% 48%;
  grid-column-gap: 4%;

  ${media('lg')} {
    left: 0%;
    padding: 0px;
  }
`

export const ModalItem = styled.div`
  display: ${props => (props.range === 'false' ? 'none' : 'block')};
  ${media('lg')} {
   display: flex;
   border-radius: 8px;
  
`

export const SubLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  text-align: left;
  margin-bottom: 8px;
  display: ${props => (props.range === 'false' ? 'none' : 'block')};
`

export const Predefined = styled.div``

export const PredefinedButton = styled.div`
  width: 100%;
  padding: 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;
  display: flex;
  margin-bottom: 8px;
  background-color: ${props => (props.active ? colors.secondary : colors.white)};
  color: ${props => (props.active ? colors.white : colors.black)};
  border: 1px solid ${props => (props.active ? 'transparent' : 'rgba(51, 51, 51, 0.24)')};

  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const Container = styled(FormControl)`
  position: relative;
  width: 100%;

  .MuiFormControl-fullWidth {
    height: ${props => (props.height ? props.height : '56px')};
  }
  .MuiFilledInput-underline:before {
    border-bottom: none;
  }
  .MuiFilledInput-root {
    background-color: ${colors.white};
  }
  .MuiFilledInput-root:hover {
    background-color: ${colors.white};
  }
  .MuiFilledInput-underline:hover:before {
    border-bottom: none;
  }
  .MuiFilledInput-underline:after {
    border-bottom: none;
  }
  .MuiFilledInput-input {
    padding: 0 15px;
    height: ${props => (props.height ? props.height : '56px')};
  }
  .MuiFilledInput-input::placeholder {
    color: ${colors.gray40l};
    opacity: 0.8;
  }
  .MuiFilledInput-root {
    background-color: #f4f1f1;
    border-radius: 8px;
    height: ${props => (props.height ? props.height : '56px')};
  }
  .MuiFilledInput-root:hover {
    background-color: #f4f1f1;
    border-radius: 8px;
    transition: all 0.2s;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0), 0px 0px 2px rgba(0, 0, 0, 0);
  }
  .MuiFilledInput-root.Mui-focused {
    background: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    .MuiInputAdornment-root {
      path {
        fill: ${colors.secondary};
      }
    }
  }
  .MuiInputAdornment-root {
    margin-right: 4px;
    display: ${props => (props.showIcon ? props.showIcon : null)};
  }
`
