import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Calendar } from 'Components/atoms'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ReactComponent as CalendarIcon } from 'Assets/Icons/Calendario.svg'
import moment from 'moment'

import { useClickOutside } from '../../utils/customHooks'

import { Container, ModalContainer, ModalItem, SubLabel, Predefined, PredefinedButton } from './styled'

const CalendarInput = ({
  label,
  error,
  value,
  onChange,
  selectRange,
  onRemove,
  height,
  showIcon,
  left,
  right,
  range,
  ...rest
}) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState('')
  const [active, setActive] = useState(null)
  const myRef = React.createRef()
  useClickOutside(myRef, () => setVisible(false))

  const handleChange = valueInput => {
    const endDate = moment(valueInput[1]).startOf('day').toDate()
    const startDate = moment(valueInput[0]).startOf('day').toDate()
    setVisible(false)
    onChange({ endDate, startDate })
    setActive(null)
  }

  const handleClickLastWeek = () => {
    const endDate = moment().startOf('day').toDate()
    const startDate = moment().subtract(7, 'days').startOf('day').toDate()
    onChange({ endDate, startDate })
    setActive(1)
  }

  const handleClickLast14Days = () => {
    const endDate = moment().startOf('day').toDate()
    const startDate = moment().subtract(14, 'days').startOf('day').toDate()
    onChange({ endDate, startDate })
    setActive(2)
  }

  const handleClickLastMonth = () => {
    const endDate = moment().startOf('day').toDate()
    const startDate = moment().subtract(1, 'month').startOf('day').toDate()
    onChange({ endDate, startDate })
    setActive(3)
  }

  return (
    <Container height={height} showIcon={showIcon}>
      <FormControl error={error} fullWidth>
        <TextField
          value={
            value
              ? [moment(value[0]).format('DD/MM/YYYY'), moment(value[1]).format('DD/MM/YYYY')]
                  .toString()
                  .replace(',', ' - ')
              : ''
          }
          placeholder={label}
          onChange={e => {
            if (!e.target.value) onRemove()
          }}
          variant='filled'
          onFocus={() => setVisible(true)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <CalendarIcon />
              </InputAdornment>
            ),
          }}
        />
        {visible && (
          <ModalContainer ref={myRef} left={left} right={right} range={range}>
            <ModalItem>
              <SubLabel range={range}>{t('filterPlaceholderDate')}</SubLabel>

              <Calendar
                {...rest}
                onChange={handleChange}
                value={value}
                selectRange={selectRange}
                className='searchCalendar'
              />
            </ModalItem>
            <ModalItem range={range}>
              <SubLabel>Rangos predefinidos</SubLabel>
              <Predefined>
                <PredefinedButton onClick={handleClickLastWeek} active={active === 1}>
                  Últimos 7 días
                </PredefinedButton>
                <PredefinedButton onClick={handleClickLast14Days} active={active === 2}>
                  Últimos 14 días
                </PredefinedButton>
                <PredefinedButton onClick={handleClickLastMonth} active={active === 3}>
                  Último mes
                </PredefinedButton>
              </Predefined>
            </ModalItem>
          </ModalContainer>
        )}
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Container>
  )
}

CalendarInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  error: PropTypes.string || PropTypes.bool,
  label: PropTypes.string,
  selectRange: PropTypes.bool,
  onRemove: PropTypes.func,
  height: PropTypes.string,
  showIcon: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  range: PropTypes.bool,
}

CalendarInput.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => {},
  onRemove: () => {},
  error: '',
  label: '',
  selectRange: '',
  height: '',
  showIcon: '',
  left: '',
  right: '',
  range: '',
}

export default CalendarInput
