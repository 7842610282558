import React, { useState, useEffect, useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'
import Compressor from 'compressorjs'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import find from 'lodash/find'
import get from 'lodash/get'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import colors from 'Assets/colors'
import { ReactComponent as FileIcon } from 'Assets/Icons/File.svg'
import { ReactComponent as TrashIcon } from 'Assets/Icons/Trash.svg'
import routes from 'Config/routes'
import { TextArea, SelectInput } from 'Components/atoms'
import { ModalLoading } from 'Components/molecules'
import BackButton from 'Components/atoms/backButton'
import { parseFileSize } from 'Components/utils/files'
import { selectCategories, selectCenterId } from 'Redux/ticket/selectors'
import { fetchCategories } from 'Redux/ticket/actions'
import { postNoConformitiesAttachment, confirmNoConformities } from 'Services/api'
import { getNCCategories, NOT_SERVED_ORDER_ID } from 'Config/utils'
import {
  NonConformityCategory,
  NonConformityQuestionType,
  COMPRESSOR_OPTIONS,
  NonConformityRedelivery,
} from 'Config/constants'

import { MAX_FILE_SIZE_BYTES } from '../../../../Config/constants'

import validate from './validate'
import {
  Container,
  Content,
  Title,
  SubTitle,
  InputContainer,
  InputLabel,
  InputInfoTitle,
  InputInfoSubtitle,
  InputSeparator,
  ButtonContainer,
  ActionButton,
  ButtonLiteral,
  Dropzone,
  Caption,
} from './styled'

const Form = ({ onNext }) => {
  const { t } = useTranslation()
  const { orderId, documentTypeCode, documentCompany, providerId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const [data, setData] = useState({ status: '', redelivery: '', questions: [] })
  const [errors, setErrors] = useState({})
  const [isSubmited, setSubmited] = useState(false)
  const [visible, setVisible] = useState({})
  const [uploads, setUploads] = useState([])
  const [isFetchingFile, setIsfetchingFile] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)

  const categories = useSelector(selectCategories)
  const centerId = useSelector(selectCenterId)
  const ticketOrder = useSelector(state => state.ticket.notConformmityOrder)
  const isNotServedOrder = useSelector(state => state.ticket.isNotServedOrder)

  const categoriesToUse = useMemo(() => getNCCategories(categories, ticketOrder, isNotServedOrder), [
    categories,
    isNotServedOrder,
    ticketOrder,
  ])
  const category = find(categories, { id: data?.status }, {})

  useEffect(() => {
    dispatch(fetchCategories())
  }, [dispatch])

  useEffect(() => {
    setErrors(validate(data, category, uploads, providerId, t))
  }, [data, category, uploads, providerId, t])

  const handleSubmit = async () => {
    setSubmited(true)
    const hasFormErrors = errors.status || errors.redelivery || errors.questions.findIndex(v => v.error !== '') > -1

    if (!hasFormErrors) {
      const answers = data?.questions?.filter(v => v.type !== NonConformityQuestionType.IMAGE)
      const formatUploads = uploads.map(v => v.value)

      const payload = {
        orderId,
        category: data?.status,
        redelivery: data?.redelivery,
        answers,
        uploads: formatUploads,
        ticketLines: [],
        documentTypeCode,
        documentCompany,
      }

      if (get(category, 'requiredLines') === NonConformityCategory.NO_REQUIRED) {
        try {
          setIsSubmiting(true)
          await confirmNoConformities(centerId, payload)
          history.push(routes.addTicketingConfirmed(orderId))
        } catch {
          toast.error(t('ticketingSubmitError'), { hideProgressBar: true })
        } finally {
          setIsSubmiting(false)
        }
      } else {
        onNext(payload)
      }
    }
  }

  const handleOnChangeStatus = e => {
    setData({ status: e.target.value, redelivery: '', questions: [] })
    setUploads([])
  }

  const handleOnChangeRedelivery = e => {
    setData(prevData => ({ ...prevData, redelivery: e.target.value }))
  }

  const handleOnChangeQuestions = (id, value, type) => {
    setData(prevData => {
      let formatQuestions = [...prevData.questions]
      const findIndex = formatQuestions.findIndex(v => v.id === id)

      if (findIndex > -1) {
        formatQuestions[findIndex] = { id, value, type }
      } else {
        formatQuestions = [...prevData?.questions, { id, value, type }]
      }

      return { ...prevData, questions: formatQuestions }
    })
  }

  const handleChangeFileToken = (id, value) => {
    setUploads(prevData => {
      let formatFilestToken = [...prevData]
      const findIndex = formatFilestToken.findIndex(v => v.id === id)

      if (findIndex > -1) {
        formatFilestToken[findIndex] = { id, value }
      } else {
        formatFilestToken = [...prevData, { id, value }]
      }

      return formatFilestToken
    })
  }

  const handleFocus = id => setVisible({ ...visible, [id]: true })
  const handleBlur = id => setVisible({ ...visible, [id]: false })

  const removeFile = useCallback(id => {
    setData(prevData => ({ ...prevData, questions: prevData?.questions?.filter(v => v.id !== id) }))
    setUploads(prevData => prevData.filter(v => v.id !== id))
  }, [])

  useEffect(() => {
    if (categoriesToUse?.length === 1 && categoriesToUse[0]?.id === NOT_SERVED_ORDER_ID) {
      setData({ status: NOT_SERVED_ORDER_ID, redelivery: '', questions: [] })
    }
  }, [categoriesToUse])

  const uploadAttachment = async (id, file) => {
    try {
      setIsfetchingFile(true)
      const response = await postNoConformitiesAttachment(centerId, file)
      handleOnChangeQuestions(id, file, NonConformityQuestionType.IMAGE)
      handleChangeFileToken(id, response.token)
    } catch {
      toast.error(t('ticketingUploadFileError'), { hideProgressBar: true })
    } finally {
      setIsfetchingFile(false)
    }
  }

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles, e) => {
      if (acceptedFiles.length === 0) {
        toast.error(t('ticketingUploadFileExtensionError'), { hideProgressBar: true })
        return acceptedFiles[0]
      }

      if (acceptedFiles[0].size > MAX_FILE_SIZE_BYTES) {
        return toast.error(t('ticketingUploadFileSizeError'), { hideProgressBar: true })
      }

      const inputId = e.target.childNodes?.[0]?.name || e.target.name
      const file = acceptedFiles[0]
      const spliType = file.type.split('/')

      if (spliType[0].includes('image')) {
        const compresor = new Compressor(file, {
          ...COMPRESSOR_OPTIONS,
          async success(result) {
            uploadAttachment(inputId, result)
          },
          error() {
            toast.error(t('ticketingUploadFileError'), { hideProgressBar: true })
          },
        })

        return compresor
      }

      uploadAttachment(inputId, file)

      return null
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [centerId, removeFile]
  )

  const textareaQuestions = category?.questions?.filter(v => v.type === NonConformityQuestionType.TEXTAREA)
  const imageQuestions = category?.questions?.filter(v => v.type === NonConformityQuestionType.IMAGE)
  const hasFormErrors = errors?.status || errors.redelivery || errors?.questions?.findIndex(v => v.error !== '') > -1

  return (
    <Container>
      <BackButton />
      <Content>
        <Title>{t('ticketingPageTitle', { orderId })}</Title>
        <SubTitle>{t('ticketingPageSubtitle')}</SubTitle>
        <InputContainer>
          <InputLabel>{t('ticketingStateLabel')}*</InputLabel>
          <SelectInput
            placeholder={isSubmited && errors.status ? t('mandatoryField') : t('ticketingStatePlaceholder')}
            placeholderColor={isSubmited && errors.status ? colors.red : 'rgb(117,117,117)'}
            id='status'
            name='status'
            value={data?.status}
            onFocus={() => handleFocus('status')}
            onBlur={() => handleBlur('status')}
            onChange={handleOnChangeStatus}
            background={visible.status ? colors.white : '#F4F1F1'}
            borderRadius={visible.status ? '8px 8px 0 0' : '8px'}
            boxShadow={visible.status ? '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)' : 'none'}
            opacity={visible.status ? '0' : '1'}
            choices={categoriesToUse}
            border={isSubmited && errors.status ? `2px solid ${colors.red}` : ''}
          />
        </InputContainer>
        {category?.caption && <Caption>{category?.caption}</Caption>}

        {category?.redelivery === true && (
          <InputContainer>
            <InputLabel>{t('ticketingRedeliveryLabel')}*</InputLabel>
            <SelectInput
              placeholder={isSubmited && errors.redelivery ? t('mandatoryField') : t('ticketingRedeliveryPlaceholder')}
              placeholderColor={isSubmited && errors.redelivery ? colors.red : 'rgb(117,117,117)'}
              id='redelivery'
              name='redelivery'
              value={data?.redelivery}
              onFocus={() => handleFocus('redelivery')}
              onBlur={() => handleBlur('redelivery')}
              onChange={handleOnChangeRedelivery}
              background={visible.status ? colors.white : '#F4F1F1'}
              borderRadius={visible.status ? '8px 8px 0 0' : '8px'}
              boxShadow={visible.status ? '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)' : 'none'}
              opacity={visible.status ? '0' : '1'}
              choices={NonConformityRedelivery.map(v => ({ id: v.id, name: t(v.name) }))}
              border={isSubmited && errors.status ? `2px solid ${colors.red}` : ''}
            />
          </InputContainer>
        )}

        {textareaQuestions?.map((question, index) => {
          const hasError = errors?.questions?.find(v => v?.id === question.id)?.error
          const inputValue = data?.questions?.find(v => v.id === question.id)?.value

          return (
            <div key={question.id}>
              <InputContainer>
                <InputLabel>
                  {question.label}
                  {question.required ? '*' : ''}
                </InputLabel>
                <TextArea
                  placeholder={isSubmited && hasError ? t('mandatoryField') : question.placeholder}
                  placeholderColor={isSubmited && hasError ? colors.red : 'rgb(117,117,117)'}
                  background={isSubmited && hasError ? colors.white : colors.grayInput}
                  border={isSubmited && hasError ? `2px solid ${colors.red}` : null}
                  name={question?.id}
                  value={inputValue}
                  onChange={e => handleOnChangeQuestions(e.target.name, e.target.value, question.type)}
                  height='104px'
                />
              </InputContainer>
              {index !== category?.questions.length - 1 && <InputSeparator />}
            </div>
          )
        })}

        {imageQuestions?.length > 0 && (
          <InputContainer>
            <InputLabel>{t('ticketingFilesLabel')}</InputLabel>
            <InputInfoTitle>{t('ticketingFilesLabelInfoTitle')}</InputInfoTitle>

            {imageQuestions?.map(question => {
              const hasError = errors?.questions?.find(v => v?.id === question.id)?.error
              const inputValue = data?.questions?.find(v => v.id === question.id)?.value

              return (
                <DropSection
                  key={question.id}
                  disabled={isFetchingFile || isSubmiting}
                  error={isSubmited && hasError}
                  value={inputValue}
                  question={question}
                  isFetching={isFetchingFile}
                  onDrop={onDrop}
                  removeFile={removeFile}
                />
              )
            })}
          </InputContainer>
        )}

        <SubmitButton
          handleSubmit={handleSubmit}
          disabled={hasFormErrors}
          isFetchingFile={isFetchingFile}
          isSubmiting={isSubmiting}
        />
      </Content>
      <ModalLoading showModal={isSubmiting} />
    </Container>
  )
}

Form.defaultProps = {
  onNext: () => {},
}

Form.propTypes = {
  onNext: PropTypes.func,
}

const DropSection = ({ question, error, disabled, isFetching, value, onDrop, removeFile }) => {
  const { t } = useTranslation()

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    disabled,
    accept: [
      'image/*',
      'application/pdf',
      '.doc',
      '.docx',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
  })

  return (
    <div>
      <InputInfoSubtitle>
        {question.label}
        {question.required ? '*' : ''}
      </InputInfoSubtitle>
      <Dropzone disabled={disabled} error={error}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} name={question.id} />
          <p className='dropzone-placeholder'>{t('ticketingFilesDropzone')}</p>
        </div>
        {value && (
          <aside className='files'>
            <ul>
              <DropFile file={value} isFetching={isFetching} onRemove={() => removeFile(question.id)} />
            </ul>
          </aside>
        )}
      </Dropzone>
    </div>
  )
}

DropSection.defaultProps = {
  question: {},
  error: '',
  disabled: false,
  isFetching: false,
  value: '',
  onDrop: () => {},
  removeFile: () => {},
}

DropSection.propTypes = {
  question: PropTypes.object,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  value: PropTypes.string,
  onDrop: PropTypes.func,
  removeFile: PropTypes.func,
}

const DropFile = ({ file, onRemove, isFetching }) => (
  <li className='file'>
    <FileIcon />
    <span className='file-text'>
      <span className='file-name'>{file.name}</span>
      <span className='file-size'>{parseFileSize(file.size)}</span>
    </span>
    {isFetching ? <CircularProgress size={18} /> : <TrashIcon className='trash-icon' onClick={onRemove} />}
  </li>
)

DropFile.defaultProps = {
  file: {},
  onRemove: () => {},
  isFetching: false,
}

DropFile.propTypes = {
  file: PropTypes.object,
  onRemove: PropTypes.func,
  isFetching: PropTypes.bool,
}

const SubmitButton = ({ handleSubmit, isFetchingFile, isSubmiting, disabled }) => {
  const { t } = useTranslation()

  return (
    <ButtonContainer onClick={handleSubmit} disabled={isFetchingFile || isSubmiting || disabled}>
      <ActionButton disabled={isFetchingFile || isSubmiting || disabled}>
        <ButtonLiteral>{t('ticketingConfirm')}</ButtonLiteral>
      </ActionButton>
      {isSubmiting && (
        <CircularProgress style={{ position: 'absolute', top: '12px', right: '16px', color: 'white' }} size={30} />
      )}
    </ButtonContainer>
  )
}

SubmitButton.defaultProps = {
  isSubmiting: false,
  handleSubmit: () => {},
  isFetchingFile: false,
  disabled: false,
}

SubmitButton.propTypes = {
  isSubmiting: PropTypes.object,
  handleSubmit: PropTypes.func,
  isFetchingFile: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Form
