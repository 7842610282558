import {
  SET_TICKET_CATEGORIES,
  SET_TICKET_IS_LOADING,
  SET_TICKET_ERROR,
  SET_TICKETS_LIST,
  SET_TICKET,
  SET_FILTER_STATUS,
  SET_TICKET_COMMENT_IS_LOADING,
  SET_PENDING_TICKETS_LIST,
  SET_NOT_CONFORMITY_ORDER,
  SET_IS_NOT_SERVED_ORDER,
} from './types'

const initialState = {
  categories: [],
  error: null,
  ticketList: [],
  ticketListCount: 0,
  ticketPendingList: [],
  ticketPendingListCount: 0,
  isLoading: false,
  ticket: null,
  filterStatus: '',
  isLoadingTicketComment: false,
  notConformmityOrder: null,
  isNotServedOrder: false,
}

const Reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_TICKET_CATEGORIES:
      return { ...state, categories: action.payload }
    case SET_TICKET_ERROR:
      return { ...state, error: action.payload }
    case SET_TICKET_IS_LOADING:
      return { ...state, isLoading: action.payload }
    case SET_TICKETS_LIST:
      return { ...state, ticketList: action.payload.list, ticketListCount: action.payload.count }
    case SET_TICKET:
      return { ...state, ticket: action.payload }
    case SET_FILTER_STATUS:
      return { ...state, filterStatus: action.payload }
    case SET_TICKET_COMMENT_IS_LOADING:
      return { ...state, isLoadingTicketComment: action.payload }
    case SET_PENDING_TICKETS_LIST:
      return { ...state, ticketPendingList: action.payload.list, ticketPendingListCount: action.payload.count }
    case SET_IS_NOT_SERVED_ORDER:
      return { ...state, isNotServedOrder: action.payload }

    case SET_NOT_CONFORMITY_ORDER:
      return { ...state, notConformmityOrder: action.payload }

    default:
      return state
  }
}

export default Reducer
