import React, { useState, useEffect, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import colors from 'Assets/colors'
import { PurchaseActions } from 'Redux/purchases'
import { BackButton, RadioButtons, TextInput } from 'Components/atoms'
import routes from 'Config/routes'
import useOrderProducts from 'hooks/qa/useOrderProducts'

import validate from './validate'
import {
  Container,
  Content,
  Title,
  SubTitle,
  RadioContainer,
  InputContainer,
  InputLabel,
  ButtonContainer,
  ActionButton,
  ButtonLiteral,
  ShowFrozenTemperatureButton,
} from './styled'

const DeliveryQa = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [data, setData] = useState({
    receptionTime: null,
    transport: null,
    package: null,
    lifeUse: null,
    productAspect: null,
    productLabel: null,
    acceptedProduct: null,
    frozen: '',
    frozenComment: '',
    refrigerated: '',
    refrigeratedComment: '',
  })
  const [errors, setErrors] = useState({})
  const [isSubmited, setSubmited] = useState()
  const [showRefrigeratedOrFrozen, setShowRefrigeratedOrFrozen] = useState(false)

  const { orderId, documentTypeCode, documentCompany } = useParams()

  const { hasUnAvailableProduct, hasFrozen, hasRefrigerated } = useOrderProducts({
    orderId,
    documentTypeCode,
    documentCompany,
  })

  useEffect(() => {
    setErrors(validate({ values: data, hasFrozen, hasRefrigerated }))
  }, [data, hasFrozen, hasRefrigerated])

  const handleClickSend = () => {
    setSubmited(true)
    if (!errors || Object.keys(errors).length <= 0) {
      const questions = [
        {
          question: 1,
          answer: data.receptionTime === t('agree'),
        },
        {
          question: 2,
          answer: data.transport === t('agree'),
        },
        {
          question: 3,
          answer: data.package === t('agree'),
        },
        {
          question: 4,
          answer: data.lifeUse === t('agree'),
        },
        {
          question: 5,
          answer: data.productAspect === t('agree'),
        },
        {
          question: 6,
          answer: data.productLabel === t('agree'),
        },
        {
          question: 7,
          answer: data.acceptedProduct === t('agree'),
        },
      ]
      const coolTemperature =
        data.refrigeratedComment && data.refrigerated
          ? {
              comment: data.refrigeratedComment,
              temperature:
                typeof data.refrigerated === 'string' ? parseFloat(data.refrigerated, 10) : data.refrigerated,
            }
          : null

      const frozenTemperature =
        data.frozenComment && data.frozen
          ? {
              comment: data.frozenComment,
              temperature: typeof data.frozen === 'string' ? parseFloat(data.frozen, 10) : data.frozen,
            }
          : null

      dispatch(
        PurchaseActions.setQaForm({
          questions,
          coolTemperature,
          frozenTemperature,
        })
      )
      let url = routes.delivery.replace(':orderId', orderId)
      url = url.replace(':documentTypeCode', documentTypeCode)
      url = url.replace(':documentCompany', documentCompany)
      history.push(url)
    }
  }

  const handleOnChangeData = (key, value) => setData(prevData => ({ ...prevData, [key]: value }))
  const handleOnChangeForm = e => {
    handleOnChangeData(e.target.name, e.target.value)
  }
  const checkIfNumber = e => {
    if (e.target.value === '0') {
      handleOnChangeData(e.target.name, e.target.value)
    } else if (parseFloat(e.target.value, 10)) {
      handleOnChangeData(e.target.name, parseFloat(e.target.value, 10))
    } else {
      handleOnChangeData(e.target.name, '')
    }
  }

  const handleRefrigeratedOrFrozenManual = useCallback(() => setShowRefrigeratedOrFrozen(true), [])

  const options = [t('agree'), t('disagree')]
  return (
    <Container>
      <Content>
        <BackButton />
        <Title>{t('qaPageTitle')}</Title>
        <SubTitle>{t('qaPageSubtitle', { orderId })}</SubTitle>
        <RadioContainer showBottomBorder={hasRefrigerated || hasFrozen || showRefrigeratedOrFrozen}>
          <RadioButtons
            options={options}
            labelPlacement='end'
            title={t('qaTitleReceptionTime')}
            name='receptionTime'
            value={data.receptionTime}
            onChange={handleOnChangeForm}
            errors={isSubmited && errors.receptionTime}
          />
          <RadioButtons
            options={options}
            labelPlacement='end'
            title={t('qaTitleTransport')}
            name='transport'
            value={data.transport}
            onChange={handleOnChangeForm}
            errors={isSubmited && errors.transport}
          />
          <RadioButtons
            options={options}
            labelPlacement='end'
            title={t('qaTitlePackage')}
            name='package'
            value={data.package}
            onChange={handleOnChangeForm}
            errors={isSubmited && errors.package}
          />
          <RadioButtons
            options={options}
            labelPlacement='end'
            title={t('qaTitleLifeUse')}
            name='lifeUse'
            value={data.lifeUse}
            onChange={handleOnChangeForm}
            errors={isSubmited && errors.lifeUse}
          />
          <RadioButtons
            options={options}
            labelPlacement='end'
            title={t('qaTitleProductAspect')}
            name='productAspect'
            value={data.productAspect}
            onChange={handleOnChangeForm}
            errors={isSubmited && errors.productAspect}
          />
          <RadioButtons
            options={options}
            labelPlacement='end'
            title={t('qaTitleProductLabel')}
            name='productLabel'
            value={data.productLabel}
            onChange={handleOnChangeForm}
            errors={isSubmited && errors.productLabel}
          />
          <RadioButtons
            options={options}
            labelPlacement='end'
            title={t('qaTitleAcceptedProduct')}
            name='acceptedProduct'
            value={data.acceptedProduct}
            onChange={handleOnChangeForm}
            errors={isSubmited && errors.acceptedProduct}
          />
        </RadioContainer>

        {hasUnAvailableProduct && !showRefrigeratedOrFrozen && (
          <ShowFrozenTemperatureButton onClick={handleRefrigeratedOrFrozenManual}>
            {t('qaManualFrozenRefrigerated')}
          </ShowFrozenTemperatureButton>
        )}

        <InputContainer>
          {hasFrozen || showRefrigeratedOrFrozen ? (
            <>
              <div>
                <InputLabel>{t('qaFrozenTemperatureLabel')}</InputLabel>
                <TextInput
                  placeholder={isSubmited && errors.frozen ? t('mandatoryField') : 'ºC'}
                  placeholderColor={isSubmited && errors.frozen ? colors.red : 'rgb(117,117,117)'}
                  background={isSubmited && errors.frozen ? colors.white : colors.grayInput}
                  border={isSubmited && errors.frozen ? `2px solid ${colors.red}` : null}
                  name='frozen'
                  value={data.frozen}
                  onChange={handleOnChangeForm}
                  margin='0 0 24px 0'
                  onBlur={checkIfNumber}
                  height={isSubmited && errors.frozen ? '56px' : null}
                />
              </div>
              <div>
                <InputLabel>{t('qaFrozenTemperatureCommentLabel')}</InputLabel>
                <TextInput
                  placeholder={isSubmited && errors.frozenComment ? t('mandatoryField') : t('writeComment')}
                  placeholderColor={isSubmited && errors.frozenComment ? colors.red : 'rgb(117,117,117)'}
                  background={isSubmited && errors.frozenComment ? colors.white : colors.grayInput}
                  border={isSubmited && errors.frozenComment ? `2px solid ${colors.red}` : null}
                  name='frozenComment'
                  value={data.frozenComment}
                  onChange={handleOnChangeForm}
                  margin='0 0 24px 0'
                  height={isSubmited && errors.frozenComment ? '56px' : null}
                />
              </div>
            </>
          ) : null}
          {hasRefrigerated || showRefrigeratedOrFrozen ? (
            <>
              <div>
                <InputLabel>{t('qaRefrigeratedTemperatureLabel')}</InputLabel>
                <TextInput
                  placeholder={isSubmited && errors.refrigerated ? t('mandatoryField') : 'ºC'}
                  placeholderColor={isSubmited && errors.refrigerated ? colors.red : 'rgb(117,117,117)'}
                  background={isSubmited && errors.refrigerated ? colors.white : colors.grayInput}
                  border={isSubmited && errors.refrigerated ? `2px solid ${colors.red}` : null}
                  name='refrigerated'
                  value={data.refrigerated}
                  onChange={handleOnChangeForm}
                  onBlur={checkIfNumber}
                  height={isSubmited && errors.refrigerated ? '56px' : null}
                />
              </div>
              <div>
                <InputLabel>{t('qaRefrigeratedCommentTemperatureLabel')}</InputLabel>
                <TextInput
                  placeholder={isSubmited && errors.refrigeratedComment ? t('mandatoryField') : t('writeComment')}
                  placeholderColor={isSubmited && errors.refrigeratedComment ? colors.red : 'rgb(117,117,117)'}
                  background={isSubmited && errors.refrigeratedComment ? colors.white : colors.grayInput}
                  border={isSubmited && errors.refrigeratedComment ? `2px solid ${colors.red}` : null}
                  name='refrigeratedComment'
                  value={data.refrigeratedComment}
                  onChange={handleOnChangeForm}
                  height={isSubmited && errors.refrigeratedComment ? '56px' : null}
                />
              </div>
            </>
          ) : null}
        </InputContainer>
        <ButtonContainer onClick={handleClickSend}>
          <ActionButton>
            <ButtonLiteral>{t('qaSendQA')}</ButtonLiteral>
          </ActionButton>
        </ButtonContainer>
      </Content>
    </Container>
  )
}

export default DeliveryQa
