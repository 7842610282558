import { media } from 'Config/styled'
import styled from 'styled-components'

export const StatusContainer = styled.div`
  width: 56px;
  height: 56px;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;

  ${media('lg')} {
    &:empty {
      display: none;
    }
  }
`

export const StatusShort = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`
