import styled, { css } from 'styled-components'
import colors from 'Assets/colors'
import { makeStyles } from '@material-ui/core/styles'
import { media } from 'Config/styled'

export const useStyles = makeStyles(() => ({
  seeQA: {
    width: '100%',
    maxWidth: '15rem',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    textAlign: 'center',
    textTransform: 'none',
    color: colors.white,
    transition: 'all 0.2s',
    border: `1px solid ${colors.secondary}`,
    marginLeft: '2.5rem',
    height: '40px',
    backgroundColor: colors.secondary,
    '&:hover': {
      opacity: 0.8,
      color: colors.white,
      transform: 'translateY(-2px)',
      backgroundColor: colors.secondary,
      boxShadow: '0 3px 5px rgba(51, 51, 51, 0.2)',
    },
    '&:active': {
      transform: 'translate(-1px)',
      boxShadow: '0 1px 3px rgba(51, 51, 51, 0.4)',
      backgroundColor: colors.secondary,
    },
  },
}))

export const Price = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
  color: ${colors.gray40l};
`

export const PriceKG = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
`

export const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 60% auto auto;
  padding: 16px 0;

  ${media('lg')} {
    display: none;
  }
`

export const TableHeaderItem = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  &:first-child {
    padding-left: 16px;
  }
`

export const ProductsContainer = styled.div`
  background-color: #fafafa;
  padding: 0 16px;
`

export const SingleProductRow = styled.div`
  background: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.24);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 60% auto auto;
  margin-bottom: 16px;
  height: 80px;

  ${media('lg')} {
    height: auto;
    grid-template-columns: 1fr;
    padding-top: 1rem;
  }
`

export const SingleProductNameHelper = styled.div`
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 78px;

  ${media('lg')} {
    height: auto;
    margin: 0;
  }
`

export const SingleProductName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  text-transform: uppercase;
  padding: 0 16px;
`

export const SingleProductPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 16px 0;
  height: 78px;

  ${media('lg')} {
    padding: 0.5rem 1rem;
    height: auto;
  }
`

export const SingleProductQtyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 16px 16px 0;
  height: 78px;

  ${media('lg')} {
    padding: 0.5rem 1rem;
    height: auto;
  }
`

export const SingleProductTotalPrice = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${colors.black};
`

export const SingleProductTotalPriceSplit = styled.div`
  display: flex;
`

export const SingleProductTotalQty = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${colors.black};
`

export const SingleProductRowCL = styled.div`
  background: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.24);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 43% 17% 17% 23%;
  margin-bottom: 16px;
  margin-top: 16px;
  height: 80px;

  ${media('lg')} {
    height: auto;
  }
`

export const SingleProductButtonContainer = styled.div`
  display: flex;
  padding: 12px 16px;
  height: 78px;
`

export const RequestProviderInfoContainer = styled.div`
  background: ${colors.gray98b};
  padding: 16px;
  display: flex;
  justify-content: space-between;

  ${media('lg')} {
    flex-direction: column;
    row-gap: 0.8rem;
  }
`

export const RequestProviderInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 8px;
`

export const RequestProviderName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
`

export const RequestProviderOrderNumber = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.gray40l};
`

export const ProviderDetail = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  display: flex;
  width: 30rem;
  justify-content: flex-end;

  ${media('lg')} {
    width: 100%;
    flex-direction: column;
    row-gap: 0.8rem;
  }
`

export const TotalPrice = styled.span`
  margin-right: 16px;
  width: 10rem;
  text-align: right;

  ${media('lg')} {
    width: 100%;
    margin-left: 0;
    text-align: left;
  }
`

export const TotalProductLines = styled.span`
  width: 10rem;

  ${media('lg')} {
    width: 100%;
  }
`

export const RequestOrderContainer = styled.div`
  background: ${colors.white};
  border-top: 1px solid rgba(51, 51, 51, 0.24);
  border-bottom: ${props => (props.openProducts ? '1px solid rgba(51, 51, 51, 0.24)' : 'none')};
  border-bottom-left-radius: ${props => (props.openProducts ? '0' : '8px')};
  border-bottom-right-radius: ${props => (props.openProducts ? '0' : '8px')};
  display: grid;
  grid-template-columns: 77% 23%;
  height: 80px;

  ${media('lg')} {
    grid-template-columns: 1fr;
    height: auto;
    order: 100;
  }
`

export const RequestRepeatOrderContainer = styled.div`
  display: flex;
  padding: 0 18px;
  border-right: 1px solid rgba(51, 51, 51, 0.24);

  ${media('lg')} {
    flex-direction: column;
    border-right: 0;
    border-bottom: 1px solid rgba(51, 51, 51, 0.24);
    padding: 0.5rem 1rem;
    row-gap: 0.8rem;
  }
`

export const RequestActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;

  ${media('lg')} {
    margin-right: 0;
  }
`

export const RequestStatusOrderContainerError = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

export const RequestStatusOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-right: 8px;
  padding-left: 16px;
  border: 2px solid ${colors.red};
  border-bottom-right-radius: 8px;

  ${media('lg')} {
    padding: 0.5rem 1rem;
  }
`

export const RequestStatusAdviceText = styled.p`
  color: ${colors.red};
  font-weight: 800;
  padding-left: 8px;
`

export const RequestStatusAdviceSubText = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${colors.gray40l};
  margin-left: 30px;
`

export const HelpText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40l};
  margin-left: 38px;

  ${props =>
    props.bold &&
    css`
      color: ${colors.blue};
      font-weight: 700;
    `}
`

export const ShowProductsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;

  ${media('lg')} {
    margin-left: 0;
    justify-content: flex-start;
  }
`

export const ShowMoreCopy = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  width: 11rem;
`

export const ShowLessCopy = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.secondary};
  width: 11rem;

  ${media('lg')} {
    width: 100%;
  }
`

export const ErrorInfo = styled.div`
  display: flex;
  border: 1px solid ${colors.red};
  border-radius: 8px;
  margin-left: 4px;
  color: ${colors.black};
  font-size: 14px;
  margin-left: 8px;
  padding: 8px;

  ${media('lg')} {
    margin-left: 0;
  }
`

export const LabelMobile = styled.div`
  display: none;

  ${media('lg')} {
    display: block;
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
  }
`
