import { media } from 'Config/styled'
import styled from 'styled-components'

export const FilterButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  cursor: pointer;

  align-items: center;
  justify-content: space-between;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  margin: 0px 20px;
  ${media('lg')} {
    margin: 0;
  }
`

export const MainText = styled.div`
  margin-right: 1rem;
`

export const FilterContainer = styled.div`
  position: absolute;
  width: 320px;
  height: 176px;
  top: 3.3rem;
  left: 200px;
  display: ${props => props.display};
  flex-direction: column;
  padding: 1rem;

  .MuiTypography-body1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .MuiFormControlLabel-labelPlacementStart {
    justify-content: space-between;
    margin-right: 0;
  }

  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 24px;

  ${media('lg')} {
    z-index: 10;
    left: unset;

    .MuiFormControlLabel-labelPlacementStart {
      margin-left: 0;
    }
  }
`
