export const FETCH_CATEGORIES = 'portal_compras/ticket/FETCH_CATEGORIES'
export const SET_TICKET_ERROR = 'portal_compras/purchases/SET_TICKET_ERROR'
export const SET_TICKET_CATEGORIES = 'portal_compras/ticket/SET_TICKET_CATEGORIES'
export const SET_TICKET_IS_LOADING = 'portal_compras/ticket/SET_TICKET_IS_LOADING'
export const FETCH_TICKETS = 'portal_compras/ticket/FETCH_TICKETS'
export const SET_TICKETS_LIST = 'portal_compras/ticket/SET_TICKETS_LIST'
export const FETCH_TICKET = 'portal_compras/ticket/FETCH_TICKET'
export const SET_TICKET = 'portal_compras/ticket/SET_TICKET'
export const SET_FILTER_STATUS = 'portal_compras/ticket/SET_FILTER_STATUS'
export const SET_TICKET_COMMENT_IS_LOADING = 'portal_compras/ticket/SET_TICKET_COMMENT_IS_LOADING'
export const ADD_TICKET_COMMENT = 'portal_compras/ticket/ADD_TICKET_COMMENT'
export const FETCH_PENDING_TICKETS = 'portal_compras/ticket/FETCH_PENDING_TICKETS'
export const SET_PENDING_TICKETS_LIST = 'portal_compras/ticket/SET_PENDING_TICKETS_LIST'
export const SET_NOT_CONFORMITY_ORDER = 'portal_compras/ticket/SET_NOT_CONFORMITY_ORDER'
export const SET_IS_NOT_SERVED_ORDER = 'portal_compras/ticket/SET_IS_NOT_SERVED_ORDER'
