import styled from 'styled-components'
import colors from 'Assets/colors'
import { Button } from '@material-ui/core'
import { media } from 'Config/styled'

export const CardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  width: 100%;
  max-width: 20rem;
  padding: 1rem;
  margin: 0 0.6rem 1.5rem;
  cursor: pointer;
  place-self: flex-start;
  ${media('lg')} {
    max-width: unset;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .category-product-card {
    margin-right: 8px;
    opacity: 0;
    transition: all 0.2s;
    color: ${props => props.theme.colors.gray40l};
    ${media('lg')} {
      display: none;
    }
  }

  .icon-product-card:hover + .category-product-card {
    opacity: 1;
  }

  .trash {
    :hover {
      opacity: 0.8;
    }
  }
`

export const CategoryAndIconContainer = styled.div`
  display: flex;
  align-items: center;
`

export const TempAndHabitualContainer = styled.div`
  display: flex;
`

export const TemperatureContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`

export const HabitualContainer = styled.div`
  margin-left: 8px;
`

export const SimpleCardContainer = styled(CardContainer)`
  box-shadow: none;
  border-radius: 0px;
  margin: 0 0 1rem;
  padding: 0px;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(51, 51, 51, 0.24);
  &:last-child {
    border-color: transparent;
  }
`

export const AddListBtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`

export const Name = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 0.5rem;
`

export const SimpleName = styled(Name)`
  padding-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Provider = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.gray};
`
export const PricesContainer = styled.div`
  display: flex;
`

export const Price = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
`

export const PreviousPrice = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  text-decoration: line-through;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
`

export const MinAndMulti = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.gray40l};
`

export const PriceKG = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.gray};
`

export const ButtonNotAvailable = styled.div`
  width: 100%;
  border: 2px solid ${props => props.theme.colors.gray40l};
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 8px;
`

export const Unavailable = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  padding: 0.5rem;
`

export const EraseButton = styled.div`
  padding: 0.5rem;
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  border-radius: 8px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .trash {
    padding-right: 0.5rem;
  }

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const ActionEraseButton = styled.div`
  background: ${colors.red};
  border-radius: 0.5rem;
  cursor: pointer;
  width: 48px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const Selector = styled.button`
  background: ${colors.cart};
  border-radius: 0.5rem;
  cursor: pointer;
  margin-left: 0.5rem;
  width: 48px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }

  &:last-child {
    margin-right: 0.5rem;
  }
`

export const Input = styled.input`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  width: 100%;
`

export const AmountContainer = styled.div`
  display: flex;
  margin-right: auto;
`

export const NameAndProviderContainer = styled.div`
  height: 6.125rem;
  ${media('lg')} {
    height: 8.125rem;
    flex-grow: 1;
  }
  ${media('xs')} {
    height: auto;
  }
`

export const MoreInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
`

export const MoreInfo = styled.p`
  margin: none;
  padding: none;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AddListButton = styled(Button)`
  text-decoration: underline !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 600 !important;
  text-transform: none !important;
`
