import { media } from 'Config/styled'
import styled from 'styled-components'

export const Root = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 20px 5px 20px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const CartData = styled.div``

export const ProviderContainer = styled.div`
  margin-bottom: 12px;
`

export const ShowProductsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
`

export const ShowMoreCopy = styled.div`
  margin-left: 10px;
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  width: 11rem;

  ${media('lg')} {
    width: 100%;
  }
`
