import { media } from 'Config/styled'
import styled, { css } from 'styled-components'

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
  margin-top: 0;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px;
  overflow-y: scroll;
  ${props => {
    const category = '62px'
    const tutorial = props.$tutorial ? '48px' : '0px'
    const needsApproval = props.$needsApproval ? '132px' : '0px'
    return css`
      height: calc(100vh - 274px - ${category} - ${tutorial} - ${needsApproval});
    `
  }}

  ${media('lg')} {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: auto;

    ${props => {
      const category = '62px'
      const tutorial = props.$tutorial ? '48px' : '0px'
      const needsApproval = props.$needsApproval ? '132px' : '0px'
      return css`
        height: calc(100vh - 274px - ${category} - ${tutorial} - ${needsApproval});
      `
    }}
  }

  ${media('xs')} {
    display: grid;
    grid-template-columns: 1fr;
  }
`

export const TopbarContainer = styled.div`
  padding: 2rem 1.25rem;

  ${media('lg')} {
    padding: 1rem;
  }
`

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${media('lg')} {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
  }
`

export const FilterButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;

  align-items: center;
  justify-content: space-between;

  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  .icon {
    margin-right: 0.5rem;
  }
`
