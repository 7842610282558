import styled from 'styled-components'
import colors from 'Assets/colors'
import { media } from 'Config/styled'

export const CardContainer = styled.div`
  background: #ffffff;
  border-radius: 3px;
  padding-left: 1rem;
  cursor: pointer;

  ${media('lg')} {
    padding: 1rem 1rem 0;
  }
`

export const ProductRow = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  justify-content: ${props => props.justifyContent};
  padding-right: 1rem;

  ${media('lg')} {
    flex-direction: column;
    gap: 0.7rem;
  }
`

export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid rgba(51, 51, 51, 0.24);
  width: 30rem;
  padding-right: 0.5rem;
  .category-product-card {
    opacity: 0;
    transition: all 0.2s;
    color: ${props => props.theme.colors.gray40l};
  }

  .icon-product-card:hover + .category-product-card {
    opacity: 1;
  }

  .trash {
    :hover {
      opacity: 0.8;
    }
  }

  ${media('lg')} {
    border-right: none;
    width: 100%;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Name = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  ${media('lg')} {
    margin-top: 0;
    margin-bottom: 0;
  }
`

export const SimpleName = styled(Name)`
  text-align: center;
`
export const SimpleCardContainer = styled(CardContainer)`
  border: 1px solid rgba(51, 51, 51, 0.24);
  border-radius: 8px;
  margin: 1rem;
`

export const Provider = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.theme.colors.gray};
`

export const Price = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  margin-left: 0.5rem;
  color: ${props => props.theme.colors.black};

  ${media('lg')} {
    margin-left: 0;
  }
`

export const PriceKG = styled.div`
  font-family: Inter;
  font-style: normal;
  text-align: 'center';
  font-size: 14px;
  margin-left: 1rem;
  padding-top: 0.1rem;
  color: ${props => props.theme.colors.gray};
`

export const PreviousPrice = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: 'center';
  color: ${props => props.theme.colors.black};
  text-decoration: line-through;
  padding-right: 0.5rem;
  padding-top: 0.1rem;
`

export const MinAndMulti = styled.div`
  font-family: Inter;
  font-style: normal;

  font-size: 12px;
  line-height: 16px;
  margin-left: 1rem;
  color: ${props => props.theme.colors.gray40l};

  ${media('lg')} {
    margin-left: 0;
  }
`

export const ButtonNotAvailable = styled.div`
  width: 100%;
  border: 2px solid ${props => props.theme.colors.gray40l};
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 8px;
`

export const Unavailable = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.black};
  padding: 0.5rem;
`

export const EraseButton = styled.div`
  padding: 0.5rem;
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  border-radius: 8px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .trash {
    padding-right: 0.5rem;
  }

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const ActionEraseButton = styled.div`
  background: ${colors.red};
  border-radius: 0.5rem;
  cursor: pointer;
  width: 48px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }
`

export const Selector = styled.button`
  background: ${colors.cart};
  border-radius: 0.5rem;
  cursor: pointer;
  margin-left: 0.5rem;
  width: 48px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0 3px 5px rgba(51, 51, 51, 0.2);
  }

  &:active {
    transform: translate(-1px);
    box-shadow: 0 1px 3px rgba(51, 51, 51, 0.4);
  }

  &:last-child {
    margin-right: 0.5rem;
  }
`

export const Input = styled.input`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  width: 100%;
`

export const AmountContainer = styled.div`
  display: flex;
  margin-right: auto;
`

export const NameAndProviderContainer = styled.div`
  height: 6.125rem;
`

export const MoreInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
`

export const MoreInfo = styled.p`
  margin: none;
  padding: none;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RowPrice = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  ${media('lg')} {
    padding: 0;
    margin: 0;
  }
`

export const CellTitleMobile = styled.div`
  display: none;
  ${media('lg')} {
    display: block;
    text-transform: uppercase;
    font-size: 0.8rem;
    line-height: 1;
    color: ${props => props.theme.colors.gray};
    padding-bottom: 0.2rem;
  }
`
