import { categoryTypes, ORDER_STATUS } from 'Config/constants'
import get from 'lodash/get'
import i18next from 'i18next'
import moment from 'moment'

export const NOT_SERVED_ORDER_ID = '314'
export const QUANTITY_DIF_CATEGORY = '11B'
export const DAMAGED_PRODUCT_CATEGORY = '212'

export const getCategoryValue = type => {
  switch (type) {
    case categoryTypes.category:
      return 1
    case categoryTypes.subCategory:
      return 2
    case categoryTypes.keymap3:
      return 3
    case categoryTypes.provider:
      return 4
    default:
      return 5
  }
}

export const isNumber = value => !Number.isNaN(Number(value))

export const downloadFile = (fileName, urlData) => {
  const aLink = document.createElement('a')
  const evt = document.createEvent('HTMLEvents')
  evt.initEvent('click')
  aLink.download = fileName
  aLink.href = urlData
  document.body.appendChild(aLink)
  aLink.click()
  document.body.removeChild(aLink)
}

export const fetchStyle = url =>
  new Promise(resolve => {
    const link = document.createElement('link')
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.onload = () => resolve
    link.href = url

    const headScript = document.querySelector('script')
    headScript.parentNode.insertBefore(link, headScript)
  })

export const percentage = (num, per) => (num / 100) * per

export const getApiErrorMessage = e =>
  get(e, 'response.data.message') || get(e, 'message') || i18next.t('petitionError')

/**
 * Compare in days two dates and see if the result is greater than a specific limit.
 * @param {number} daysBetween - Limit to compare the difference.
 * @param {string} firstDate - The first date.
 * @param {string} [secondDate = new Date(Date.now())] - The second date.
 * @returns {boolean} - Difference between dates are lower than limit.
 */
export const dateComparator = ({ daysBetween, firstDate, secondDate = new Date(Date.now()) }) => {
  const difference = moment(secondDate).diff(firstDate, 'days')
  return difference <= daysBetween
}

export const isAvailableTutorial = ({ availableTutorials, tutorial }) =>
  availableTutorials?.find(elem => elem?.name === tutorial)

export const getNCCategoriesForNotServided = categories => categories.filter(el => el.id === NOT_SERVED_ORDER_ID)

export const getNCCategoriesServed = categories => categories.filter(el => el.id !== NOT_SERVED_ORDER_ID)

export const getNCCategories = (categories, order, isNotServedOrder) => {
  if (order?.state === ORDER_STATUS.PR && isNotServedOrder) {
    return categories.filter(el => el.id === NOT_SERVED_ORDER_ID)
  }
  return categories.filter(el => el.id !== NOT_SERVED_ORDER_ID)
}
